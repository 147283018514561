import React from 'react';

function Companies({ width = "17.748", height = "14.198", ...props }) {
    return (
        <div className="rounded defaultPadding flex center" style={{background: 'rgb(245, 245, 245)', width: '41.4px', height: '41.4px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#545557" width={width} height={height} viewBox="0 0 26 23.4" {...props}>
                <path d="M15,8.2V3H2V26.4H28V8.2ZM7.2,23.8H4.6V21.2H7.2Zm0-5.2H4.6V16H7.2Zm0-5.2H4.6V10.8H7.2Zm0-5.2H4.6V5.6H7.2Zm5.2,15.6H9.8V21.2h2.6Zm0-5.2H9.8V16h2.6Zm0-5.2H9.8V10.8h2.6Zm0-5.2H9.8V5.6h2.6Zm13,15.6H15V21.2h2.6V18.6H15V16h2.6V13.4H15V10.8H25.4ZM22.8,13.4H20.2V16h2.6Zm0,5.2H20.2v2.6h2.6Z" transform="translate(-2 -3)" />
            </svg>
        </div>
    )
}

export default Companies;