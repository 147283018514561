import React, { useState, useEffect } from "react";
import {
  getCompanyProfile,
  putCompanyProfile,
  getInformationsByType,
  postImage,
} from "../../request";
//import Pace from 'react-pace-progress';
import ProgressBar from "../../components/ProgressBar";
import Base from "../../components/Base";
import Input from "../../components/InputHook";
import InputMaskedHook from "../../components/InputMaskedHook";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Edit from "../../svg/edit";
import ConfigurationPageTitle from "../../components/ConfigurationPageTitle";
import FileInput from "../../components/FileInput";
import { toast } from "react-toastify";

const DataCompanyPage = () => {
  const [fields, setFields] = useState({
    tradeName: "",
    companyName: "",
    cnpj: "",
    segment: "",
    responsible: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    site: "",
    email: "",
    phone: "",
    secundaryPhone: "",
  });
  const [logoCompany, setLogoCompany] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [isDisabledImage, setIsDisabledImage] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [statesOptions, setStatesOptions] = useState([]);

  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  useEffect(() => {
    setIsLoading(true);
    getInformationsByType("SEGMENTS", "STATES")
      .then((res) => {
        res.data.forEach((info) => {
          if (info.key && info.key === "STATES") {
            setStatesOptions(
              info.data.sort((a, b) => a.label.localeCompare(b.label))
            );
          }
          if (info.SEGMENTS) {
            const informations = info || "";
            if (informations) {
              setSegmentsOptions(
                informations.SEGMENTS.data.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )
              );
            }
          }
        });
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível obter a lista de responsáveis");
      });
    getCompanyProfile()
      .then(
        async ({
          data: { active, informations, created, updated, ...data },
        }) => {
          data.tradeName = data.tradeName || "Nome fantasia";
          data.companyName = data.companyName;
          setCompanyID(data.id);
          const url = data.urlImage || "";

          setLogoCompany(url || require(`../../png/logo-company.png`));
          delete data.id;
          setFields({ ...fields, ...data });
          setIsLoading(false);
        }
      )
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as informações da empresa");
        setIsLoading(false);
      });
  }, []);

  const updateCompany = () => {
    setIsLoadingSpinner(true);

    const revalidator = require("revalidator");
    let isValid = revalidator.validate(fields, {
      properties: {
        companyName: {
          message: "A Razão social é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        cnpj: {
          message: "O CNPJ é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        phone: {
          message: "O Telefone principal é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setIsLoadingSpinner(false);
      return;
    }

    putCompanyProfile(fields)
      .then(() => {
        toast.success("Dados da empresa atualizados com sucesso");
        setIsDisabled(true);
        setIsLoadingSpinner(false);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar os dados da empresa");
        setIsLoadingSpinner(false);
      });
  };

  // Event listener for if upload photo button is clicked
  const uploadFile = async (e) => {
    let file = e[0];
    //TODO verificar permissao para editar imagem do logo da empresa.
    // Upload file
    postImage(file)
      .then((resp) => {
        const url = resp.data.data.url;
        setLogoCompany(url);
        setIsDisabledImage(true);
        putCompanyProfile({ urlImage: url }).then(() => {
          toast.success("Imagem do logo da empresa atualizada com sucesso");
        });
      })
      .catch((error) => {
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar o logo da empresa da empresa");
      });
  };

  return (
    <>
      {
        <ProgressBar
          className="loading"
          loading={isLoading}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }
      <ConfigurationPageTitle title="Dados da empresa" />
      <div className="fullWidth flex wrapReverse hCenter">
        <div className="card border flex column hCenter flexGrow3 dataUserContent startSelf">
          <div className="flex between defaultMarginBottom vCenter">
            {isDisabled ? (
              <h3>{fields.tradeName ? fields.tradeName : "Nome fantasia"}</h3>
            ) : (
              <Input
                placeholder="Nome fantasia"
                disabled={isDisabled}
                isDefault={true}
                value={fields.tradeName}
                onChange={(value) => handleFields(value, "tradeName")}
                withoutMargin={true}
              />
            )}
            {isDisabled ? (
              <Edit
                className="pointer"
                onClick={() => {
                  setIsDisabled(!isDisabled);
                }}
              />
            ) : (
              <Button
                isLoading={isLoadingSpinner}
                className="smallMarginTop"
                text="Salvar"
                alignSelf="end"
                paddingHorizontal="30px"
                onClick={() => {
                  updateCompany();
                  setIsDisabled(false);
                }}
              />
            )}
          </div>

          <div className="fullWidth flex center wrap defaultMarginTop contentInputs">
            <Input
              label="Razão social"
              disabled={isDisabled}
              isDefault={true}
              value={fields.companyName}
              onChange={(value) => handleFields(value, "companyName")}
            />
            <InputMaskedHook
              label="CNPJ"
              disabled={isDisabled}
              value={fields.cnpj || ""}
              isDefault={true}
              mask="99.999.999/9999-99"
              onChange={(value) => handleFields(value, "cnpj")}
              maskChar={null}
            />

            <Select
              label="Segmento"
              placeholder="  "
              disabled={isDisabled}
              value={fields.segment}
              onChange={(value) => handleFields(value, "segment")}
              options={segmentsOptions}
              isDefault={true}
            />
            <Input
              label="Responsável"
              disabled={isDisabled}
              value={fields.responsible}
              isDefault={true}
              onChange={(value) => handleFields(value, "responsible")}
            />
            <Input
              label="Logradouro"
              disabled={isDisabled}
              value={fields.address}
              isDefault={true}
              onChange={(value) => handleFields(value, "address")}
            />
            <div className="flexGrow fullWidth flex twoColumns">
              <Input
                label="Número"
                type="number"
                disabled={isDisabled}
                value={fields.number}
                isDefault={true}
                onChange={(value) => handleFields(value, "number")}
              />
              <Input
                label="Complemento"
                disabled={isDisabled}
                value={fields.complement}
                isDefault={true}
                onChange={(value) => handleFields(value, "complement")}
              />
            </div>
            <Input
              label="Bairro"
              disabled={isDisabled}
              value={fields.neighborhood}
              isDefault={true}
              onChange={(value) => handleFields(value, "neighborhood")}
            />

            <div className="flexGrow fullWidth flex twoColumns">
              <Input
                label="Cidade"
                disabled={isDisabled}
                value={fields.city}
                isDefault={true}
                onChange={(value) => handleFields(value, "city")}
              />
              <Select
                label="Estado"
                disabled={isDisabled}
                placeholder="  "
                isDefault={true}
                value={fields.state}
                onChange={(value) => handleFields(value, "state")}
                options={statesOptions}
              />
            </div>
            <Input
              label="Site"
              type="url"
              disabled={isDisabled}
              value={fields.site}
              isDefault={true}
              onChange={(value) => handleFields(value, "site")}
            />
            <Input
              label="Email"
              type="email"
              disabled={isDisabled}
              value={fields.email}
              isDefault={true}
              onChange={(value) => handleFields(value, "email")}
            />
            <InputMaskedHook
              label="Telefone principal"
              type="tel"
              disabled={isDisabled}
              value={fields.phone || ""}
              isDefault={true}
              mask="(99) 99999-9999"
              onChange={(value) => handleFields(value, "phone")}
              maskChar={null}
            />
            <InputMaskedHook
              label="Telefone secundário"
              type="tel"
              disabled={isDisabled}
              value={fields.secundaryPhone || ""}
              isDefault={true}
              mask="(99) 99999-9999"
              onChange={(value) => handleFields(value, "secundaryPhone")}
              maskChar={null}
            />
          </div>
        </div>

        <div className="card border flex column  flexGrow3 dataLogoContent startSelf">
          <div className="flex between defaultMarginBottom vCenter">
            {isDisabledImage ? <h3>Logotipo</h3> : null}
            {isDisabledImage ? (
              <Edit
                className="pointer"
                onClick={() => {
                  setIsDisabledImage(!isDisabledImage);
                }}
              />
            ) : null}
          </div>
          <img src={logoCompany} className="logoCompany" />
          {isDisabledImage ? null : (
            <div className="fullWidth flex center wrap defaultMarginTop contentInputs">
              <FileInput value="" onChange={(e) => uploadFile(e)} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Base(DataCompanyPage);
