import React, { useState } from "react";
import { useSelector } from "react-redux";
import { putQualification } from "../../../request";
import TextArea from "../../TextArea";
import Button from "../../Button";
import ModalTitle from "../../Modal/ModalTitle";
import { toast } from "react-toastify";

function Unqualified({ leadID, closeModal }) {
  const id = useSelector((state) => state.qualifications.id);

  const [fields, setFields] = useState({
    leadID,
    notQualified: "",
  });

  const [loading, setLoading] = useState(false);

  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  const handlePutQualification = ({ notQualified }, leadID) => {
    setLoading(true);
    putQualification(
      {
        id,
        observation: { notQualified },
      },
      leadID
    )
      .then(() => {
        toast.success("Qualificação atualizada com sucesso");
        setFields({
          notQualified: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error("O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema.");
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar a qualificação");
        setLoading(false);
      })
      .finally(() => closeModal());
  };

  return (
    <>
      <ModalTitle
        title="Não qualificou"
        icon={require("../../../png/thumb_down_cover.png")}
      />
      <TextArea
        label="Observações sobre a não qualificação"
        className="fullWidth"
        disabled={false}
        isDefault={true}
        value={fields.notQualified}
        onChange={(value) => handleFields(value, "notQualified")}
      />
      <div className="fullWidth flex end vCenter defaultMarginTop">
        <Button
          text="Confirmar"
          paddingHorizontal="20px"
          marginTop="10px"
          isLoading={loading}
          onClick={() => handlePutQualification(fields)}
        />
      </div>
    </>
  );
}

export default Unqualified;
