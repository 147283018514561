import React from 'react';

function Phone({ width, height, ...props }) {
    return (
        <svg data-tip="Telefonar" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width={width} height={width} {...props} viewBox="0 0 12 12">
            <defs>
                <clipPath id="a"><path d="M0-18H12V-6H0Z" transform="translate(0 18)" /></clipPath>
            </defs>
            <g transform="translate(0 18)">
                <g className="b" clipPath="url(#a)" transform="translate(0 -18)">
                    <g transform="translate(0)">
                        <path
                            d="M-1.207-2.6A10.1,10.1,0,0,0,3.187,1.8L4.653.33a.665.665,0,0,1,.68-.161A7.567,7.567,0,0,0,7.713.55a.669.669,0,0,1,.667.667V3.543a.669.669,0,0,1-.667.667A11.332,11.332,0,0,1-3.62-7.123a.669.669,0,0,1,.667-.667H-.62a.669.669,0,0,1,.667.667,7.574,7.574,0,0,0,.38,2.38.669.669,0,0,1-.167.68Z"
                            transform="translate(3.62 7.79)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Phone;