import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import Base from "../../components/Base";
import Button from "../../components/Button";
import Input from "../../components/InputHook";
import InputMasked from "../../components/InputMaskedHook";
import TextArea from "../../components/TextArea";
import Edit from "../../svg/edit";
import EditLarge from "../../svg/edit-large";
import Trash from "../../svg/trash";
import {
  putCustomerContact,
  listCustomerCompanies,
  getCustomerContact,
  deleteCustomerContact,
} from "../../request";
import { toast } from "react-toastify";
import AutoComplete from "../../components/AutoComplete";
import Title from "../../components/Title";
import Modal from "../../components/Modal";
// import ArrowLeft from '../../svg/arrowleft';
import BackIcon from "../../svg/backIcon";

const ContactsDetailPage = () => {
  const history = useHistory();
  const customerContactID = history.location.pathname.split("/").reverse()[0];
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingPutCompany, setIsLoadingPutContact] = useState(null);
  const [customerCompanyID, setCustomerCompanyID] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phoneMain, setPhoneMain] = useState("");
  const [phoneSecondary, setPhoneSecondary] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [socialNetwork, setSocialNetwork] = useState("");
  const [observation, setObservation] = useState("");
  const [customField, setCustomField] = useState();
  const [customFieldLabel, setCustomFieldLabel] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [toDelete, setToDelete] = useState(true);
  const [toDeleteModal, setToDeleteModal] = useState(false);
  const isBusinessPage = false;
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const listCompanies = () => {
    listCustomerCompanies()
      .then(({ data }) => {
        if (data.length) {
          setCompaniesOptions(data);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as empresas");
      });
  };

  const putCustomer = () => {
    setToDelete(true);
    setIsLoadingPutContact(true);
    let payload = {
      id: customerContactID,
      customerCompanyID,
      customerCompanyName,
      name,
      position,
      email,
      phoneMain,
      phoneSecondary,
      whatsApp,
      socialNetwork,
      observation,
      customField,
    };

    const revalidator = require("revalidator");
    let isValid = revalidator.validate(payload, {
      properties: {
        name: {
          message: "O Nome é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    if (!isValid.valid) {
      console.warn("Erro: ", isValid.errors, " Status: ", isValid.valid);
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setIsLoadingPutContact(false);
      return;
    }

    putCustomerContact(payload)
      .then(() => {
        toast.success("Contato atualizado com sucesso");
        setIsDisabled(true);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar a empresa");
      })
      .finally(() => setIsLoadingPutContact(false));
  };

  const refresh = () => {
    history.push("/contatos");
    window.location.reload();
  };

  const deleteContact = () => {
    setIsLoadingPutContact(true);
    deleteCustomerContact(customerContactID)
      .then(() => {
        setIsDisabled(true);
        toast.success("Contato deletado com sucesso");
        setIsLoadingPutContact(false);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível deletar o contato.");
      })
      .finally(() => refresh());
  };

  useEffect(() => {
    let label = JSON.parse(localStorage.getItem("customFields"))?.reduce(
      (field, { key, label }) => {
        if (key === "CONTACTS") return label;
        return field;
      },
      ""
    );

    if (label) setCustomFieldLabel(label);
    handleGetCustomerContact();
  }, []);

  const handleGetCustomerContact = (reset = false) => {
    setIsLoading(true);
    listCompanies();
    getCustomerContact(
      customerContactID // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
    )
      .then(({ data: { ...rest } }) => {
        setName(rest.name);
        setPosition(rest.position);
        setEmail(rest.email);
        setPhoneMain(rest.phoneMain);
        setPhoneSecondary(rest.phoneSecondary);
        setWhatsApp(rest.whatsApp);
        setSocialNetwork(rest.socialNetwork);
        setObservation(rest.observation);
        setCustomerCompanyID(rest.customerCompanyID);
        setCustomerCompanyName(rest.customerCompanyName || rest.companyName);
        setCustomField(rest.customField);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Modal
        className="newContactModal modal-sm"
        active={toDeleteModal}
        statusModal={toDeleteModal}
        content={
          <div>
            <div>
              <h4>Deseja mesmo excluir este contato?</h4>
              <br />
              <p>
                Os dados ainda permaneceram nos negocios, mas esse contato nao
                estará mais presente na lista.
              </p>
            </div>
            <div className="flex end">
              <Button
                text={"Excluir"}
                background={"red"}
                paddingHorizontal="30px"
                className="defaultMarginTop"
                marginRight="10px"
                isLoading={isLoadingPutCompany}
                onClick={() => deleteContact()}
              />
              <Button
                text={"Cancelar"}
                paddingHorizontal="30px"
                className="defaultMarginTop"
                onClick={() => {
                  setToDeleteModal(false);
                }}
              />
            </div>
          </div>
        }
      />
      <ProgressBar
        className="loading"
        loading={isLoading}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />
      <ProgressBar
        className="loading"
        loading={isLoadingPutCompany}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />

      {/* <div className="fullWidth flex between titleContent"> */}
      <div style={{ marginBottom: "15px" }} className="fullWidth flex">
        <Title text="Contatos" />
      </div>
      <div>
        <BackIcon
          className="pointer flexShrinkNone"
          onClick={() => {
            history.goBack();
          }}
        />
      </div>
      <div className="halfWidth flex card centerSelf">
        <div className="flexGrow defaultMarginRight bodyColumn">
          <div className="flex between">
            {isDisabled ? (
              <h2 style={{ wordBreak: "normal" }}>
                {name || "Nome não informado"}
              </h2>
            ) : (
              <Input
                className="halfWidth"
                placeholder={name ? name : "Nome do contato"}
                disabled={isDisabled}
                isDefault={true}
                value={name}
                onChange={setName}
                withoutMargin={true}
              />
            )}
            <div className="editAndDelete end ">
              {isDisabled ? (
                <Button
                  icon={<EditLarge />}
                  marginRight="10px"
                  paddingHorizontal="10px"
                  background="none"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                />
              ) : (
                <Button
                  isLoading={isLoadingPutCompany}
                  marginRight="150"
                  className="flex smallMarginTop endSelf"
                  text="Salvar"
                  alignSelf="end"
                  paddingHorizontal="20px"
                  width={"100px"}
                  onClick={putCustomer}
                />
              )}

              {
                <Button
                  icon={<Trash />}
                  paddingHorizontal="10px"
                  background="none"
                  onClick={() => setToDeleteModal(true)}
                />
              }
            </div>
            {/* className="defaultMarginLeft pointer flex flexShrinkNone endSelf" */}
          </div>

          <div className="flex center wrap defaultMarginTop">
            <label
              style={{ marginTop: "8px" }}
              className="flex column secondaryLabel halfWidth"
            >
              <span>Empresa</span>
              <AutoComplete
                disabled={isDisabled}
                value={customerCompanyName}
                onChange={(event, option) => {
                  if (option && option.inputValue && isBusinessPage) {
                    setCustomerCompanyID("");
                    setCustomerCompanyName(option.inputValue);
                  } else {
                    if (option) {
                      setCustomerCompanyName(option.companyName);
                      setCustomerCompanyID(option.id);
                    }
                  }
                }}
                options={companiesOptions}
              />
            </label>

            <Input
              label="Cargo"
              disabled={isDisabled}
              value={position}
              isDefault={true}
              onChange={setPosition}
            />
            <Input
              label="E-mail"
              className="fullWidth"
              disabled={isDisabled}
              value={email}
              isDefault={true}
              onChange={setEmail}
            />
            <InputMasked
              label="Telefone principal"
              type="text"
              disabled={isDisabled}
              isDefault={true}
              mask="(99) 99999-9999"
              value={phoneMain}
              onChange={setPhoneMain}
              required={true}
              maskChar={null}
            />
            <InputMasked
              label="Telefone secundário"
              type="text"
              disabled={isDisabled}
              isDefault={true}
              mask="(99) 99999-9999"
              value={phoneSecondary}
              onChange={setPhoneSecondary}
              required={true}
              maskChar={null}
            />
            <InputMasked
              label="Whatsapp"
              type="text"
              disabled={isDisabled}
              isDefault={true}
              mask="(99) 99999-9999"
              value={whatsApp}
              onChange={setWhatsApp}
              required={true}
              maskChar={null}
            />
            <Input
              label="Rede social"
              disabled={isDisabled}
              value={socialNetwork}
              isDefault={true}
              onChange={setSocialNetwork}
            />
            {customFieldLabel && (
              <Input
                className="fullWidth"
                label={customFieldLabel}
                disabled={isDisabled}
                value={customField}
                isDefault={true}
                onChange={setCustomField}
              />
            )}
            <TextArea
              label="Observações"
              disabled={isDisabled}
              value={observation}
              isDefault={true}
              onChange={setObservation}
              className="fullWidth"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Base(ContactsDetailPage);
