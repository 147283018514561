import React from 'react';

function Score({ text, number }) {

    return (
        <div className="flex between scoreButton center defaultMarginRight">
            <span>{text}</span>
            <span className="defaultMarginLeft">{number}</span>
        </div>
    )

}

export default Score;