import React from "react";
import { useState } from "react";
import Base from "../../components/Base";
import ConfigurationPageTitle from "../../components/ConfigurationPageTitle";
import './releasesPage.css'
import { functionalities } from "./releasesPageData";

const ReleasesPage = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked === index) {
      return setClicked(null)
    }

    setClicked(index);
  }
  return (
    <>
      <ConfigurationPageTitle title="Releases" />
      <div className="container">
        <h1>Novas Funcionalidades</h1>
        {
          functionalities.map((item, index) => {
            return (
              <>
                <div
                  className="accordeon"
                  onClick={() => toggle(index)}
                  key={index}
                >
                  <h1>{item.label}</h1>
                  <span>{clicked === index ? <span>-</span> : <span>+</span>}</span>
                </div>
                {clicked === index ? (
                  <div className="content">
                    {item.funcionalidades.map((item) => {
                      return (
                        <p>{item}</p>
                      )
                    })}
                  </div>
                ) : null}
              </>
            )
          })
        }
      </div>
    </>
  );
};

export default Base(ReleasesPage);
