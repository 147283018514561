import React from 'react';

function Contacts({ width = "17.748", height = "14.198", ...props }) {
    return (
        <div className="rounded defaultPadding flex center" style={{background: 'rgb(245, 245, 245)', width: '41.4px', height: '41.4px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#545557" width={width} height={height} viewBox="0 0 26 16.545" {...props}>
                <path d="M18.727,12.091a3.545,3.545,0,1,0-3.545-3.545A3.531,3.531,0,0,0,18.727,12.091Zm-9.455,0A3.545,3.545,0,1,0,5.727,8.545,3.531,3.531,0,0,0,9.273,12.091Zm0,2.364C6.519,14.455,1,15.837,1,18.591v2.955H17.545V18.591C17.545,15.837,12.026,14.455,9.273,14.455Zm9.455,0c-.343,0-.733.024-1.146.059a4.987,4.987,0,0,1,2.328,4.077v2.955H27V18.591C27,15.837,21.481,14.455,18.727,14.455Z" transform="translate(-1 -5)" />
            </svg>
        </div>
    )
}

export default Contacts;