import React from 'react';
import TextArea from '../../../TextArea';

function Observation({ text, type, ...rest }) {
    return (
        <div className="card border defaultMarginTop flex between vCenter">
            <div className="fullWidth">
                <p className="strong">Observações</p>

                {
                    type !== "edit" ?
                        (
                            <div className="fullWidth card border smallMarginTop">
                                <p>{text}</p>
                            </div>
                        )
                        : (<TextArea className="fullWidth" {...rest}></TextArea>)
                }
            </div>
        </div>
    );
}

export default Observation;