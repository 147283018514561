import React, { useState, useEffect } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(...registerables);

const ClosedDealsChart = ({ closedDeals, createdDeals, date }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [range, setRange] = useState({
    min: new Date(date.year, date.month, 1),
    max: new Date(date.year, date.month + 1, 0),
  });
  const [options, setOptions] = useState({});

  useEffect(() => {
    let monthDays =
      Math.ceil(
        (range.max.getTime() - range.min.getTime()) / (1000 * 3600 * 24)
      ) + 1;

    let saturdays = [];
    let closed = [];
    let created = [];
    let lastFirstWeekDay = new Date(range.min);

    for (let index = 1; index <= monthDays; index++) {
      const weekdate = new Date(new Date(date.year, date.month, index + 1) - 1);
      if ((weekdate.getDay() == 6 && index > 1) || index == monthDays) {
        saturdays.push(weekdate);
        const incidenceClosed = closedDeals.filter(
          (deal) =>
            new Date(deal.date) >= lastFirstWeekDay &&
            new Date(deal.date) <= weekdate
        );
        closed.push(incidenceClosed.length);

        const createdIncidence = createdDeals.filter(
          (deal) =>
            new Date(deal.date) >= lastFirstWeekDay &&
            new Date(deal.date) <= weekdate
        );
        created.push(createdIncidence.length);

        lastFirstWeekDay = new Date(
          date.year,
          date.month,
          weekdate.getDate() + 1
        );
      }
    }

    let labelArr = [];
    saturdays.forEach((week) => {
      labelArr.push(
        `${week.getDate()}/${week.toLocaleString("pt-BR", { month: "short" })}`
      );
    });

    let chartDataObj = {
      labels: labelArr,
      datasets: [
        {
          label: `Negócios Ganhos`,
          data: closed,
          fill: true,
          backgroundColor: "rgba(0, 161, 154, 0.5)",
          borderColor: "rgba(0, 161, 154, 1)",
          tension: 0.3,
        },
        {
          label: `Negócios Criados`,
          data: created,
          fill: true,
          backgroundColor: "rgba(107, 184, 222,0.5)",
          borderColor: "rgba(107, 184, 222,1)",
          tension: 0.3,
        },
      ],
    };

    setOptions({
      interaction: {
        mode: "index",
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            precision: 0,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          position: "right",
          labels: {
            usePointStyle: true,
          },
        },
        datalabels: {
          display: false,
        },
      },
    });

    setChartData({ ...chartDataObj });
  }, [closedDeals, createdDeals, date]);

  return <Line options={options} data={chartData} className="chart" />;
};

export default ClosedDealsChart;
