/* eslint-disable react/prop-types */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export default function AutoComplete(props) {
  const disabledStyle = props.disabled ? "rgba(222, 222, 222, 0.3)" : "white";
  const disabledBorderStyle = props.disabled
    ? "0px"
    : "1px solid rgb(222, 222, 222) !important";
  const useStyles = makeStyles({
    textField: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
      //border: "1px solid rgb(222, 222, 222) !important",
      border: disabledBorderStyle,
      height: "33px",
      marginLeft: "5px",
      marginTop: "8px",
      borderRadius: "4px",
      padding: "0px 9px",
      backgroundColor: disabledStyle,
    },
    container: {
      "&& button": {
        marginRight: "2px",
      },
    },
  });
  const classes = useStyles();
  const filter = createFilterOptions();
  if (props.contactsOptions) {
    return (
      <Autocomplete
        className={clsx("autocompleteInput", classes.container)}
        value={props.value}
        onChange={props.onChange}
        filterOptions={(options, params) => {
          options = options.filter((op) => op.name);
          const filtered = filter(options, params);

          if (params.inputValue !== "" && props.isBusinessPage) {
            if (filtered.length == 0) {
              filtered.push({
                inputValue: params.inputValue,
                name: `Adicionar "${params.inputValue}"`,
              });
            }
          }

          return filtered;
        }}
        options={props.contactsOptions}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => {
          const { InputProps } = params;
          const { className } = InputProps;
          return (
            <TextField
              {...params}
              placeholder="Selecione um contato"
              disabled={props.disabled ? true : false}
              InputProps={{
                ...InputProps,
                className: `${className}  ${classes.textField} fontMontserrat`,
              }}
            />
          );
        }}
      />
    );
  } else {
    return (
      <Autocomplete
        disabled={props.disabled}
        className={clsx("autocompleteInput", classes.container)}
        value={props.value}
        onChange={props.onChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "" && props.isBusinessPage) {
            if (filtered.length == 0) {
              filtered.push({
                inputValue: params.inputValue,
                companyName: `Adicionar "${params.inputValue}"`,
              });
            }
          }

          return filtered;
        }}
        options={props.options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.companyName;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.companyName}
        freeSolo
        renderInput={(params) => {
          const { InputProps } = params;
          const { className } = InputProps;
          return (
            <TextField
              {...params}
              disabled={props.disabled ? true : false}
              placeholder="Selecione uma empresa"
              InputProps={{
                ...InputProps,
                className: `${className}  ${classes.textField} fontMontserrat`,
              }}
            />
          );
        }}
      />
    );
  }
}
