// import firebase from "firebase/app";
// import "firebase/analytics";
// import "firebase/auth";
// import "firebase/storage";
// import "firebase/database";
// import { firebaseConfig } from "./config/environment";

//const env = process.env.REACT_APP_ENV_ALIAS || "default";
// Não dá pra usar NODE_ENV porque no Firebase é sempre 'development' ou 'production'
// console.log('process.env.NODE_ENV:', process.env.NODE_ENV);

// console.log('Environment:', env);
// console.log('Firebase projectId:', firebaseConfig[env].projectId);

//firebase.initializeApp(firebaseConfig[env]);
//firebase.analytics();

// Reference to auth method of Firebase
//var auth = firebase.auth();

// Reference to storage method of Firebase
//var storage = firebase.storage();

// Reference to database method of Firebase
//var database = firebase.database();

// var signIn = {
//   withGoogle: () => {
//     const googleProvider = new firebase.auth.GoogleAuthProvider();
//     auth
//       .signInWithPopup(googleProvider)
//       .then(() => {})
//       .catch();
//   },
// };

// var signOut = () => {
//   auth
//     .signOut()
//     .then(() => {
//       window.location = "/";
//     })
//     .catch(() => {});
// };

//export { firebase as default, auth, storage, database };
