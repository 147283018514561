import React from "react";
import PlusButton from "../../svg/plus_button";
import StageItem from "./StageItem";
import StageHeader from "./StageHeader";
import ThumbUp from "../../png/thumb_up.png";
import ThumbDown from "../../png/thumb_down.png";

function Stage({
  type,
  title,
  icon,
  iconLeft,
  id,
  userUID,
  leads = [],
  onAdd,
  onDragOver,
  onDragStart,
  onDrop,
  modalConfirm,
  handleModalConfirm,
  handleBusinessPageModal,
}) {
  if (type) iconLeft = { success: ThumbUp, fail: ThumbDown }[type];
  let totalValue = leads.reduce((total, { uniqueValue = 0 }) => {
    return (total += uniqueValue);
  }, 0);
  let totalValueRec = leads.reduce((total, { recurringValue = 0 }) => {
    return (total += recurringValue);
  }, 0);
  //totalValue += totalValueRec;
  return (
    <div
      className="stageColumn defaultMarginTop flexShrinkNone"
      onDrop={(e) => onDrop(e, id)}
      onDragOver={(e) => onDragOver(e)}
    >
      <StageHeader
        text={title}
        className={type}
        icon={icon}
        iconLeft={iconLeft}
        full={!icon}
        totalPrice={totalValue}
        totalRec={totalValueRec}
        length={leads.length}
      />
      <div className="smallMarginTop contentStageLead breakWords">
        {leads.map((item) => (
          <StageItem
            type={type}
            modalConfirm={modalConfirm}
            handleModalConfirm={handleModalConfirm}
            key={item.id}
            lead={item}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            handleBusinessPageModal={handleBusinessPageModal}
          />
        ))}
      </div>
      {onAdd && (
        <div className="flex center defaultMarginTop">
          <PlusButton className="pointer" onClick={onAdd} />
        </div>
      )}
    </div>
  );
}

export default Stage;
