import React from "react";
import Phone from "../../svg/phone";
import Whatsapp from "../../svg/whatsapp";
import Mail from "../../svg/mail";
import Linkedin from "../../svg/linkedin";

const formatDate = (value) => (value <= 9 ? `0${value}` : value);

const upperSort = (property) => {
  return (a, b) => {
    var nameA =
      typeof a[property] === "string" ? a[property].toUpperCase() : a[property];
    var nameB =
      typeof b[property] === "string" ? b[property].toUpperCase() : b[property];
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };
};

const lowerSort = (property) => {
  return (a, b) => {
    var nameA =
      typeof a[property] === "string" ? a[property].toUpperCase() : a[property];
    var nameB =
      typeof b[property] === "string" ? b[property].toUpperCase() : b[property];
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  };
};

const sortByDate = (array, atualSort) => {
  if (atualSort == "lowerDate") {
    array.sort(lowerSort("lastcontact"));
  } else {
    array.sort(upperSort("lastcontact"));
  }
  return array;
};

let mountTable = ({ atualFilter, atualSort, data = [] }, callback) => {
  data = data || [];
  let array = data.map((item) => {
    if (item.lastcontact) {
      return { ...item, lastcontact: item.lastcontact };
    } else {
      return { ...item, lastcontact: item.created };
    }
  });
  array = sortByDate(array, atualSort);
  array = array
    .filter((item) => {
      if (atualFilter.id == "all") {
        return true;
      }
      if (atualFilter.id == "active" && item.active) {
        return true;
      }
      if (atualFilter.id == "disabled" && !item.active) {
        return true;
      }
      return false;
    })
    .map(
      (
        {
          name = "",
          companyName = "",
          customerCompanyName = "",
          position = "",
          lastcontact = null,
          phoneMain = "",
          phoneSecondary = "",
          email = "",
          whatsApp = "",
          socialNetwork = "",
          id = "",
          _id = "",
        },
        index,
        array
      ) => {
        let auxLastcontact = new Date(lastcontact);

        return {
          fields: {
            name: { value: name },
            companyName: { value: customerCompanyName || companyName },
            position: { value: position },
            lastcontact: {
              value:
                formatDate(auxLastcontact.getHours()) +
                ":" +
                formatDate(auxLastcontact.getMinutes()) +
                " - " +
                formatDate(auxLastcontact.getDate()) +
                "/" +
                formatDate(auxLastcontact.getMonth() + 1) +
                "/" +
                auxLastcontact.getFullYear(),
            },
            contact: {
              value: (
                <div className="flex center">
                  <Phone
                    width="13px"
                    height="13px"
                    style={{ marginRight: "10px" }}
                    fill={phoneMain || phoneSecondary ? "#545557" : "#d2d2d2"}
                    onClick={() =>
                      window.open(`tel:${phoneMain || phoneSecondary}`)
                    }
                  />
                  <Mail
                    width="22px"
                    height="22px"
                    style={{ margin: "0 10px" }}
                    fill={email ? "#545557" : "#d2d2d2"}
                    onClick={() => window.open(`mailto:${email}`)}
                  />
                  <Whatsapp
                    width="15px"
                    height="15px"
                    style={{ margin: "0 10px" }}
                    fill={whatsApp ? "#545557" : "#d2d2d2"}
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${whatsApp}`
                      )
                    }
                  />
                  <Linkedin
                    width="15px"
                    height="15px"
                    style={{ margin: "0 0 2px 10px" }}
                    fill={socialNetwork ? "#545557" : "#d2d2d2"}
                    onClick={() => window.open(`${socialNetwork}`)}
                  />
                </div>
              ),
            },
          },
          id: id || _id,
        };
      }
    );

  if (callback) callback();
  return array;
};

export default mountTable;
