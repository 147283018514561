import React, { useState, useEffect } from "react";
import {
  getCompanyCustomFields,
  getInformationsByType,
  putCompanyCustomFields,
  putCompanyInformations,
} from "../../request";
import Base from "../../components/Base";
import ProgressBar from "../../components/ProgressBar";
import ConfigurationPageTitle from "../../components/ConfigurationPageTitle";
import CustomField from "./CustomField";
import ResponseOptions from "./ResponseOptions";
import { toast } from "react-toastify";

const CustomFieldsPage = () => {
  const [customFields, setCustomFields] = useState();
  const [responseOptions, setResponseOptions] = useState({});

  const customFieldsMock = [
    {
      key: "CONTACTS",
      label: "",
    },
    {
      key: "COMPANIES",
      label: "",
    },
    {
      key: "LEADS",
      label: "",
    },
  ];

  const getTitle = (key) =>
  ({
    SEGMENTS: "Segmento de mercado",
    REASONS_LOSED: "Motivos do descarte",
    LEAD_SOURCES: "Origem do lead",
    PAIN_POINTS: "Dores",
  }[key]);

  const [isLoading, setIsLoading] = useState(null);

  const handleCustomFields = (value, property) => {
    setCustomFields(previousState => {
      let payload = { ...previousState, [property]: value };
      handlePutCustomFields(payload);
      return payload;
    });
  };

  const handleResponseOptions = (value, property) => {
    setResponseOptions((prev) => {
      let payload = { ...prev, [property]: value };
      handlePutResponseOptions(payload);
      return payload;
    });
  };

  const handlePutResponseOptions = (responseOptions) => {
    let aux = Object.entries(responseOptions).map(([key, item]) => ({
      key,
      data: item.map(({ label }) => ({ label })),
    }));
    putCompanyInformations(aux).then(() => {
      toast.success("Salvo com sucesso!");
    });
  };
  const handlePutCustomFields = (customFields) => {
    let aux = Object.entries(customFields).map(([key, item]) => ({
      key,
      label: item.label,
    }));
    localStorage.setItem("customFields", JSON.stringify(aux));
    putCompanyCustomFields(aux).then(() => { });
  };

  useEffect(() => {
    getCompanyCustomFields().then((response) => {
      let data = response.data ? [{ key: 'CONTACTS', label: response.data.CONTACTS.label }, { key: 'COMPANIES', lable: response.data.COMPANIES.label }, { key: 'LEADS', label: response.data.LEADS.label }] : customFieldsMock;
      setCustomFields(() =>
        data.reduce(
          (fields, item) => ({
            ...fields,
            [item.key]: { label: item.label },
          }),
          {}
        )
      );
    });

    getInformationsByType(
      "SEGMENTS",
      "REASONS_LOSED",
      "LEAD_SOURCES",
      "PAIN_POINTS"
    ).then(({ data }) => {
      //const data = res[0].__collections__.informations;
      const informations = data[0];
      const info = Object.values(informations);

      setResponseOptions(() =>
        info.reduce(
          (fields, item) => ({
            ...fields,
            [item.key]: item.data,
          }),
          {}
        )
      );
    });
  }, []);

  useEffect(() => { }, [customFields]);

  useEffect(() => { }, [responseOptions]);

  return (
    <>
      {
        <ProgressBar
          className="loading"
          loading={isLoading}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }
      <ConfigurationPageTitle title="Dados personalizáveis" />
      <div className="fullWidth flex column">
        {customFields && (
          <CustomField
            item={customFields.CONTACTS}
            handleFields={(value) => handleCustomFields(value, "CONTACTS")}
            title={"Contatos"}
          />
        )}
        {customFields && (
          <CustomField
            item={customFields.COMPANIES}
            handleFields={(value) => handleCustomFields(value, "COMPANIES")}
            title={"Empresas"}
          />
        )}
        {customFields && (
          <CustomField
            item={customFields.LEADS}
            handleFields={(value) => handleCustomFields(value, "LEADS")}
            title={"Negócios"}
          />
        )}
        <div className="flexGrow card team defaultMarginTop">
          <h3>Opções de resposta</h3>
          {Object.entries(responseOptions).map(([key, options]) => (
            <ResponseOptions
              key={key}
              options={options}
              handleFields={(value) => handleResponseOptions(value, key)}
              title={getTitle(key)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Base(CustomFieldsPage);
