import React, { useState, useEffect } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(...registerables);
ChartJS.register(ChartDataLabels);

const colorGenerator = (quantity) => {
  let colors = [];
  const basecColors = [
    "#2DB5BC",
    "#003f5c",
    "#2f4b7c",
    "#665191",
    "#a05195",
    "#d45087",
    "#d43d51",
    "#f95d6a",
    "#ff7c43",
    "#eb7d53",
    "#ffa600",
    "#f8b76b",
    "#ffee9b",
    "#b2ce82",
    "#68ac74",
    "#00876c",
  ];

  for (let index = 0; index < quantity; index++) {
    if (index > 16) {
      colors.push(basecColors[index - 10]);
    } else {
      colors.push(basecColors[index]);
    }
  }

  return colors;
};

const LostDealsByReason = ({ lostDeals, date }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  console.log(date);
  const [data, setData] = useState([]);

  const options = {
    interaction: {
      mode: "index",
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value) => {
          return value > 0 ? value : "";
        },
        color: "#fff",
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return [
              context.label,
              context.dataset.label + ": " + context.formattedValue,
            ];
          },
        },
      },
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  useEffect(() => {
    setData(lostDeals);
  }, [lostDeals]);

  useEffect(() => {
    let [labels, allTime, range] = [[], [], []];
    if (data.length) {
      data.forEach((reason) => {
        labels.push(reason.label);
        allTime.push(reason.allTime);
        range.push(reason.range);
      });
    }

    const colors = colorGenerator(data.length || 0);

    const chartDataObj = {
      labels: labels,
      datasets: [
        {
          label: "Geral",
          data: allTime,
          backgroundColor: colors,
          datalabels: {
            align: "center",
            anchor: "center",
          },
          borderRadius: 5,
        },
        { weight: 0.4 },
        {
          label: date,
          data: range,
          backgroundColor: colors,
          datalabels: {
            align: "center",
            anchor: "center",
          },
          borderRadius: 5,
        },
      ],
    };

    setChartData(chartDataObj);
  }, [data]);

  return <Doughnut options={options} data={chartData} className="largeChart" />;
};
export default LostDealsByReason;
