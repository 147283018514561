import React from 'react';

function CheckRoundedEnabled({ width = "23", height = "23", ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="flex center defaultMarginLeft" width={width} height={height} viewBox="0 0 28 28" {...props}>
      <g transform="translate(-1598 -241)">
        <g transform="translate(1600 243)">
          <circle className="a" cx="12" cy="12" r="12" fill="#fff" stroke="#00a19a" strokeWidth="4px" />
        </g>
        <path className="b" fill="#00a19a" d="M14,2A12,12,0,1,0,26,14,12,12,0,0,0,14,2ZM11.6,20l-6-6,1.692-1.692L11.6,16.6,19.908,8.3,21.6,10Z" transform="translate(1598 241)" />
      </g>
    </svg>
  )

}

export default CheckRoundedEnabled;