import {
  createMuiTheme,
  FormControlLabel,
  ThemeProvider,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "../Modal";
import ModalTitle from "../Modal/ModalTitle";
import Select from "../Select";
import "./style.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00a19a",
    },
  },
});

const LostLeadsDateLimitModal = ({
  modal,
  toggleModal,
  lostLeadsLimitDate,
  setLostLeadsLimitDate,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [selectedDays, setSelectedDays] = React.useState(lostLeadsLimitDate);

  const toggleChecked = () => {
    setChecked(!checked);
  };

  //console.log("selected days", selectedDays);
  const days = [
    { value: 30, label: "30 dias" },
    { value: 60, label: "60 dias" },
    { value: 90, label: "90 dias" },
  ];

  useEffect(() => {
    if (checked) {
      if (selectedDays == "30 dias") {
        setLostLeadsLimitDate(30);
      } else if (selectedDays == "60 dias") {
        setLostLeadsLimitDate(60);
      } else if (selectedDays == "90 dias") {
        setLostLeadsLimitDate(90);
      }
    } else {
      setLostLeadsLimitDate(0);
    }
  }, [selectedDays]);

  useEffect(() => {
    if (!checked) {
      setLostLeadsLimitDate(0);
    } else {
      setLostLeadsLimitDate(90);
    }
  }, [checked]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal
          active={modal}
          closeModal={toggleModal}
          content={
            <>
              <ModalTitle
                title={"Ocultar leads perdidos"}
                icon={require("../../png/activity.png")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={toggleChecked}
                    color="primary"
                  />
                }
                color="primary"
              />
              {checked == true ? (
                <Select
                  placceholder="Selecione"
                  label="Data limite de leads perdidos"
                  options={days}
                  value={days.value}
                  onChange={(value) => setSelectedDays(value)}
                />
              ) : null}
            </>
          }
        />
      </ThemeProvider>
    </>
  );
};

export default LostLeadsDateLimitModal;
