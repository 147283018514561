import React, { useState, useEffect } from 'react';
import ModalTitle from '../ModalTitle';
import Question from '../../../components/Question';
import Input from '../../../components/InputHook';
import Button from '../../../components/Button';
import PlusButton from '../../../svg/plus_button';
import { putQualificationTemplate } from '../../../request';
import { toast } from 'react-toastify';

function QualificationFlowEdit({ status, closeModal, template }) {

  const [fields, setFields] = useState(template);
  const [loadingPutQualification, setLoadingPutQualification] = useState(false);
  const [focus, setFocus] = useState(false);
  const [focusChoice, setFocusChoice] = useState(false);

  const handleFocusChoice = (value) => {
    setFocusChoice(value)
  }

  const handleFields = (value, property, index) => {
    let auxPrev = { ...fields };
    if (index || index === 0) {
      auxPrev[property][index] = value;
    } else {
      auxPrev[property] = value;
    }
    setFields(auxPrev);
  }

  const handleSendQualification = ({ created, companyID, userUID, ...fields }) => {
    setLoadingPutQualification(true)

    let isInvalid = false;
    if (fields.questions?.length > 0) {
      for (const question of fields.questions) {
        isInvalid = question.choices.some((choice) => !choice.title);
        if (isInvalid) {
          toast.error('O título das respostas não pode ser em branco');
          setLoadingPutQualification(false)
          closeModal() 
          return;
        }
      }
    }

    putQualificationTemplate(fields).then((response) => {
      toast.success("Fluxo de qualificação atualizado com sucesso")
    })
    .catch((error) => {
      if (error.response?.data === "auth/id-token-expired") {
        toast.error("O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema.");
        return;
      }
      if (error.response?.data === "INVALID_TITLE") {
        toast.error('O título das respostas não pode ser em branco');
        return;
      }
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error('Não foi possível atualizar o fluxo de qualificação');
    })
    .finally(() => {
      setLoadingPutQualification(false)
      closeModal()   
    });
  }

  useEffect(() => {
    if (status) setFields(template)
  }, [status])

  return (
    <>
      <ModalTitle title="Editar fluxo de qualificação" className="qualificationTitle" icon={require('../../../png/gear.png')} />
      <div className="flex column card border">
        <Input className="fullWidth" isDefault={true} value={fields.description} withoutMargin={true} onChange={(value) => handleFields(value, "description")} inputStyle={{ fontWeight: 700, fontSize: 14 }} />
        {
          fields && fields.questions.map((item, index) => (
          <>
            <Question focus={focus} focusChoice={focusChoice} handleFocusChoice={ (value)=> handleFocusChoice(value)} key={item.title + item.order + "edit" + index} name="mock1" edit={true} index={index} question={item} title={item.title} handleFields={handleFields} />
            <div className="flex center defaultMarginTop" >
              <PlusButton className="pointer" style={{marginRight: '1rem'}} onClick={() => {
                setFields((prev) => {
                    prev.questions.splice(index + 1, 0 , {
                      order: index + 1,
                      "title": "",
                      "choices": [
                        {
                          "title": "",
                          "hint": "",
                          "score": 0
                        }
                      ]
                    })
                    setFocus(true);
                    setFocusChoice(false);
                    return { ...prev }
                  })
                  
                }}
              />
              <Button text='Remover' width='6rem' onClick={() => {
                setFields((prev) => {
                  prev.questions.splice(index , 1)
                  setFocus(true);
                  setFocusChoice(false);
                  return { ...prev }
                })
              }}/>
            </div>
          </>
          ))
        }
        {fields.questions.length === 0 &&
        <div className="flex center defaultMarginTop">
          <PlusButton className="pointer" onClick={() => {
            setFields((prev) => {
                prev.questions.push({
                  order: 1,
                  "title": "",
                  "choices": [
                    {
                      "title": "",
                      "hint": "",
                      "score": 0
                    }
                  ]
                })
                setFocus(true);
                setFocusChoice(false);
                return { ...prev }
              })
              
            }}
          />
        </div>
        }
      </div>
      <div className="flex end defaultMarginTop">
        <Button text={"Salvar"} isLoading={loadingPutQualification} paddingHorizontal="25px" onClick={() => handleSendQualification(fields)} />
      </div>
    </>
  );

}

export default QualificationFlowEdit;
