import React, { Fragment } from 'react';

class Gear extends React.Component {

    render() {
        return (
            <Fragment>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26.741"><path d="M3.35,20.761l2.674,4.613a.681.681,0,0,0,.825.29l3.32-1.337a9.457,9.457,0,0,0,2.251,1.315l.49,3.543a.691.691,0,0,0,.669.557h5.348a.69.69,0,0,0,.669-.579l.49-3.543A9.917,9.917,0,0,0,22.336,24.3l3.32,1.337a.681.681,0,0,0,.825-.29l2.674-4.613A.687.687,0,0,0,29,19.869l-2.83-2.206a8.4,8.4,0,0,0,.089-1.293,13.026,13.026,0,0,0-.089-1.315L29,12.85a.682.682,0,0,0,.156-.869L26.481,7.368a.681.681,0,0,0-.825-.29l-3.32,1.337A9.457,9.457,0,0,0,20.086,7.1L19.6,3.557A.691.691,0,0,0,18.927,3H13.579a.69.69,0,0,0-.669.579l-.49,3.543a9.381,9.381,0,0,0-2.251,1.293L6.849,7.078a.681.681,0,0,0-.825.29L3.35,11.981a.687.687,0,0,0,.156.869l2.83,2.206a8.725,8.725,0,0,0-.111,1.315,13.026,13.026,0,0,0,.089,1.315l-2.83,2.206A.682.682,0,0,0,3.35,20.761Zm12.9-8.2a3.811,3.811,0,1,1-3.811,3.811A3.823,3.823,0,0,1,16.253,12.56Z" transform="translate(-3.249 -3)" /></svg>
            </Fragment>

        )
    }
}

export default Gear;