import React, { useState, useEffect } from "react";
import ModalTitle from "../ModalTitle";
import TextArea from "../../TextArea";
import Button from "../../Button";
import Select from "../../Select";
import { putResultLead, postEvent } from "../../../request";
import { toast } from "react-toastify";

function Lose({
  pipelineID,
  closeModal,
  leadID,
  status,
  refetch,
  option,
  result,
  disabled,
}) {
  const [fields, setFields] = useState({
    reason: "",
    observation: "",
  });

  const [reasonOption, setReasonOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  const convertReasonFromId = (reason) => {
    if (reason.includes("reasons_losed") && reasonOption) {
      return reasonOption.find((o) => o.id === reason).label;
    } else {
      return reason;
    }
  };

  const handlePutResultLead = (fields) => {
    setIsLoading(true);

    const revalidator = require("revalidator");
    const isValid = revalidator.validate(fields, {
      properties: {
        reason: {
          message: "É obrigatório selecionar um Motivo do descarte",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setIsLoading(false);
      return;
    }

    let field = fields;
    field.reason = convertReasonFromId(fields.reason);

    putResultLead({ id: leadID, status: "lost", ...field }, pipelineID)
      .then(() => {
        toast.success(
          "O negócio foi perdido, todas as atividades pendentes foram encerradas"
        );
        setIsLoading(false);
        if (refetch) refetch();
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar o para etapa Perdeu");
      })
      .finally(() => {
        const eventData = {
          type: "lead",
          description: "Lead foi perdida",
          leadID: leadID,
          status: "lost",
        };
        postEvent(eventData);
        closeModal();
      });
  };

  useEffect(() => {
    if (status) {
      setReasonOption(option);
    } else {
      setFields({
        observation: "",
        reason: "",
      });
    }
  }, [status]);

  useEffect(() => {
    if (option) {
      setReasonOption(option);
    }
  }, [option]);

  let finalResult = result?.reason ? result.reason : fields.reason;

  return (
    <>
      <ModalTitle
        title="Perdeu"
        icon={require("../../../png/thumb_down_cover.png")}
      />
      {disabled && !finalResult.includes("reasons_losed") ? (
        <label class="flex column secondaryLabel smallMarginTop fullWidth">
          <span>Motivo do descarte</span>
          <div class="contentSelect smallMarginTop fullWidth">
            <select class="variantInput" disabled={disabled}>
              <option>{convertReasonFromId(finalResult)}</option>
            </select>
          </div>
        </label>
      ) : (
        <Select
          disabled={disabled}
          placeholder="  "
          property="reason"
          onChange={(value) => handleFields(value, "reason")}
          options={reasonOption}
          isDefault={true}
          label="Motivo do descarte"
          className="fullWidth"
          value={result?.reason ? result.reason : fields.reason}
        />
      )}

      <TextArea
        label="Observações sobre a perda do negócio"
        className="fullWidth"
        disabled={disabled}
        isDefault={true}
        value={result?.observation ? result.observation : fields.observation}
        property="observation"
        onChange={(value) => handleFields(value, "observation")}
      />
      <div className="fullWidth flex end vCenter defaultMarginTop">
        {result ? null : (
          <Button
            text="Confirmar"
            isLoading={isLoading}
            paddingHorizontal="20px"
            marginTop="10px"
            onClick={() => handlePutResultLead(fields)}
          />
        )}
      </div>
    </>
  );
}

export default Lose;
