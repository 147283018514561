import React, { useState, useEffect } from "react";
import { postCustomerContact } from "../../../request";
import Button from "../../Button";
import Input from "../../InputHook";
import InputMasked from "../../InputMaskedHook";
import TextArea from "../../TextAreaHook";
import { toast } from "react-toastify";
import AutoComplete from "../../AutoComplete";

function NewContact({
  closeModal,
  statusModal,
  companiesOptions,
  customerCompanyID,
  setCustomerCompanyID,
  customerCompanyName,
  setCustomerCompanyName,
  isBusinessPage,
  inputValue,
  setInputValue,
}) {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phoneMain, setPhoneMain] = useState("");
  const [phoneSecondary, setPhoneSecondary] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [socialNetwork, setSocialNetwork] = useState("");
  const [observation, setObservation] = useState("");
  const [customField, setCustomField] = useState();
  const [customFieldLabel, setCustomFieldLabel] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!statusModal) {
      setCustomerCompanyID("");
      setCustomerCompanyName("");
      setName("");
      setPosition("");
      setEmail("");
      setPhoneMain("");
      setPhoneSecondary("");
      setWhatsApp("");
      setSocialNetwork("");
      setObservation("");
      setCustomerCompanyID("");
      setCustomField("");
    }
  }, [statusModal]);

  const postCustomer = () => {
    setLoading(true);
    let payload = {
      customerCompanyID,
      companyName: customerCompanyName,
      name,
      position,
      email,
      phoneMain,
      phoneSecondary,
      whatsApp,
      socialNetwork,
      observation,
      customField,
    };

    const revalidator = require("revalidator");
    let isValid = revalidator.validate(payload, {
      properties: {
        name: {
          message: "O Nome é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    if (!isValid.valid) {
      console.warn("Erro: ", isValid.errors, " Status: ", isValid.valid);
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setLoading(false);
      return;
    }

    postCustomerContact(payload)
      .then(() => {
        toast.success("Contato cadastrado com sucesso");
        closeModal();
        setName("");
        setPosition("");
        setEmail("");
        setPhoneMain("");
        setPhoneSecondary("");
        setWhatsApp("");
        setSocialNetwork("");
        setObservation("");
        setCustomerCompanyID("");
        setCustomField("");
        setCustomerCompanyName("");
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível salvar a empresa");
        setLoading(false);
      });
  };

  useEffect(() => {
    const customFields = JSON.parse(localStorage.getItem("customFields"));
    let label = customFields?.reduce((field, { key, label }) => {
      if (key === "CONTACTS") return label;
      return field;
    }, "");
    if (label) setCustomFieldLabel(label);
  }, []);

  return (
    <>
      <div className="flex vCenter">
        <img
          alt=""
          src={require("../../../png/contact.png")}
          className="defaultMarginRight iconTitle"
        />
        <h3>Novo contato</h3>
      </div>
      <div className="fullWidth flex center wrap defaultMarginVertical contentInputs">
        <Input
          label="Nome"
          className="fullWidth inputSpaced"
          disabled={false}
          isDefault={true}
          value={name}
          onChange={setName}
        />
        {/* <Input label="Sobrenome" disabled={false} value={surname} isDefault={true} onChange={setSurname} /> */}
        <div style={{ marginTop: "8px" }} className="flex column halfWidth">
          <label className="secondaryLabel">
            <span>Empresa</span>
          </label>
          <AutoComplete
            disabled={false}
            value={customerCompanyName}
            onChange={(event, option) => {
              if (option && option.inputValue && isBusinessPage) {
                setCustomerCompanyID("");
                setCustomerCompanyName(option.inputValue);
              } else {
                if (option) {
                  setCustomerCompanyName(option.companyName);
                  setCustomerCompanyID(
                    option.id || option._id.valueOf().toString()
                  );
                }
              }
            }}
            options={companiesOptions}
          />
        </div>

        <Input
          label="Cargo"
          disabled={false}
          value={position}
          isDefault={true}
          onChange={setPosition}
        />

        <Input
          label="E-mail"
          className="fullWidth inputSpaced"
          disabled={false}
          value={email}
          isDefault={true}
          onChange={setEmail}
        />
        <InputMasked
          label="Telefone principal"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={phoneMain}
          onChange={setPhoneMain}
          required={true}
          maskChar={null}
        />
        <InputMasked
          label="Telefone secundário"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={phoneSecondary}
          onChange={setPhoneSecondary}
          required={true}
          maskChar={null}
        />
        <InputMasked
          label="Whatsapp"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={whatsApp}
          onChange={setWhatsApp}
          required={true}
          maskChar={null}
        />
        <Input
          label="Rede social"
          disabled={false}
          value={socialNetwork}
          isDefault={true}
          onChange={setSocialNetwork}
        />
        {customFieldLabel && (
          <Input
            className="fullWidth"
            label={customFieldLabel}
            disabled={false}
            value={customField}
            isDefault={true}
            onChange={setCustomField}
          />
        )}
        <TextArea
          label="Observações"
          disabled={false}
          value={observation}
          isDefault={true}
          onChange={setObservation}
          className="fullWidth"
        />
      </div>
      <div className="flex end">
        <Button
          text="Salvar"
          paddingHorizontal="30px"
          className="defaultMarginTop"
          isLoading={loading}
          onClick={postCustomer}
        />
      </div>
    </>
  );
}

export default NewContact;
