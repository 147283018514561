import React, { useState } from "react";
import { postActivity, putLead } from "../../../request";
import Select from "../../Select";
import TextArea from "../../TextArea";
import Button from "../../Button";
import ModalTitle from "../../Modal/ModalTitle";
import InputHour from "../../InputHour/index";
import InputMasked from "../../InputMaskedHook/index";
import { removeEmptyKeys } from "../../../script";
import { payloadActivityValidation } from "../../../utils";
import { toast } from "react-toastify";

function Qualified({
  leadID,
  closeModal,
  refetch,
  teamMembers,
  pipelineID,
  leadInfo,
}) {
  const [fields, setFields] = useState({
    leadID,
    activityTypeID: "",
    startTime: "",
    endTime: "",
    description: "",
    date: "",
    memberProfileID: "",
  });

  const [loadingPostActivity, setLoadingPostActivity] = useState(false);

  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  const handlePostActivity = ({ date, endTime, startTime, ...rest }) => {
    rest.date = date;
    rest.endTime = endTime;
    rest.startTime = startTime;
    const revalidator = require("revalidator");
    let isValid = revalidator.validate(rest, {
      properties: {
        memberProfileID: {
          message: "O Vendedor responsável é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    isValid = payloadActivityValidation(rest);
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setLoadingPostActivity(false);
      return;
    }

    if (fields.activityTypeID) {
      setLoadingPostActivity(true);

      date = date.split("/").reverse();
      date.splice(1, 1, +date[1] - 1);

      endTime = new Date(...[...date, ...endTime.split(":")]).getTime();
      startTime = new Date(...[...date, ...startTime.split(":")]).getTime();

      let payload = removeEmptyKeys(rest);

      let leadPayload = leadInfo;
      leadPayload.memberProfileID = payload.memberProfileID;
      leadPayload = removeEmptyKeys(leadPayload);

      //console.log(pipelineID, leadPayload);
      putLead(leadPayload, pipelineID)
        .then(() => {
          toast.success("Negócio atualizado com sucesso");
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível atualizar o negócio");
        });

      postActivity({
        ...payload,
        endTime,
        startTime,
      })
        .then(() => {
          toast.success("Atividade agendada com sucesso");
          setFields({
            leadID,
            activityTypeID: "",
            startTime: "",
            endTime: "",
            description: "",
            date: "",
          });
          setLoadingPostActivity(false);
          refetch();
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível cadastrar a atividade");
          setLoadingPostActivity(false);
        })
        .finally(() => closeModal());
    } else {
      console.warn("Campos obrigatórios não informados");
      toast.warning(
        "Os campos descrição da atividade e tipo de atividade são obrigatórios"
      );
    }
  };

  return (
    <>
      <ModalTitle
        title="Qualificou"
        icon={require("../../../png/thumb_up_cover.png")}
      />
      <div className="flex">
        <InputMasked
          label="Data da reunião"
          placeholder={"00/00/0000"}
          disabled={false}
          isDefault={true}
          value={fields.date}
          onChange={(value) => {
            handleFields(`${value}`, "date");
          }}
          style={{ maxWidth: "95px" }}
          mask="99/99/9999"
          maskChar={null}
        />
        <InputHour
          label="Hora de início"
          placeholder={"00:00"}
          isDefault={true}
          value={fields.hour}
          onChange={(value) => handleFields(value, "startTime")}
          style={{ maxWidth: "95px" }}
          mask="99:99"
        />
        <InputHour
          label="Hora de fim"
          placeholder={"00:00"}
          isDefault={true}
          value={fields.hour}
          onChange={(value) => handleFields(value, "endTime")}
          style={{ maxWidth: "95px" }}
          mask="99:99"
        />
        <Select
          placeholder="Selecione um responsável"
          className="fullWidth"
          label="Vendedor responsável"
          onChange={(value) => handleFields(value, "memberProfileID")}
          options={teamMembers}
          valueOption="name"
          isDefault={true}
          value={fields.memberProfileID}
        />
      </div>

      <TextArea
        isBusinessPage={false}
        label="Atividade"
        className="fullWidth"
        isDefault={true}
        value={fields.description}
        onChange={(value) => handleFields(value, "description")}
        controlled={true}
        setType={(value) => handleFields(`${value || ""}`, "activityTypeID")}
      />

      <div className="fullWidth flex end vCenter defaultMarginTop">
        <Button
          text="Confirmar"
          paddingHorizontal="20px"
          marginTop="10px"
          isLoading={loadingPostActivity}
          onClick={() => handlePostActivity(fields)}
        />
      </div>
    </>
  );
}

export default Qualified;
