import React from 'react';
import Common from './Common';
import Full from './Full';
import Spinner from '../../../svg/spinner';

function StageHeader({ full, isLoading, ...rest }) {

    return (
        <>
            {
                // <Common {...rest} />
                !full ? (<Common {...rest} />) : (<Full {...rest} />)
                // <Full {...rest} />
                
            }
             {
                isLoading ?
                    <Spinner fill="#fff" className="smallMarginRight" /> : null
            }
        </>
    )

}

export default StageHeader;