import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Contacts from "../../../svg/searchresult/contacts";
import Companies from "../../../svg/searchresult/companies";
import Business from "../../../svg/searchresult/business";
import { useHistory } from "react-router-dom";
import Modal from "../../../components/Modal";
import BusinessDetailPage from "../../BusinessDetailPage";

const Entity = ({ id, name, type }) => {
  const history = useHistory();
  const [businessPageModal, setBusinessPageModal] = useState({
    status: null,
    id: "",
  });
  const [detailLead, setDetailLead] = useState(null);

  useEffect(() => {
    setDetailLead(BusinessDetailModalFunction(businessPageModal));
  }, [businessPageModal]);

  const BusinessDetailModalFunction = ({ status, id }) => {
    return (
      <Modal
        active={status}
        content={status ? <BusinessDetailPage id={id} /> : null}
        closeModal={() => {
          setBusinessPageModal({
            status: false,
            id: "",
          });
        }}
      />
    );
  };

  const handleBusinessPageModal = (modalStatus, id) => {
    setBusinessPageModal({
      status: modalStatus,
      id: id,
    });
  };
  return (
    <div className="fullWidth flex wrap teamItem">
      <div className="flex flexGrow between vCenter">
        {type === "lead" ? <Business /> : null}

        {type === "contact" ? <Contacts /> : null}

        {type === "company" ? <Companies /> : null}

        <p
          style={{ marginLeft: "20px", marginRight: "auto" }}
          className="strong"
        >
          {name}
        </p>
      </div>
      <div className="flex flexGrow end">
        <Button
          text="Visualizar"
          paddingHorizontal="25px"
          onClick={() => {
            if (type === "lead") {
              handleBusinessPageModal(true, id);
            } else if (type === "contact") {
              history.push(`/contatos/${id}`);
            } else {
              history.push(`/empresas/${id}`);
            }
          }}
        />
      </div>
      <>{detailLead}</>
    </div>
  );
};

export default Entity;
