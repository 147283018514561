import React from 'react';

function Check({ height, width, ...props }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} {...props} viewBox="0 0 34.803 28.712">
            <g transform="translate(0 0)">
                <path d="M8.9,0-7.544,16.444l-6.091-6.091-6.134,6.135L-7.544,28.712,15.034,6.134Zm0,4.1,2.03,2.03L-7.544,24.607l-8.121-8.118,2.03-2.032,6.091,6.092Z" transform="translate(19.769)" />
            </g>
        </svg>
    );
}

export default Check;