import React from 'react';

function Cam({ width = "17", height = "11.333", ...props }) {
    return (
        <svg data-tip="Vídeo" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 11.333" {...props}>
            <path d="M-.778-.25V-3.556A.947.947,0,0,0-1.722-4.5H-13.056A.947.947,0,0,0-14-3.556V5.889a.948.948,0,0,0,.944.944H-1.722a.948.948,0,0,0,.944-.944V2.583L3,6.361V-4.028Z" transform="translate(14 4.5)" />
        </svg>
    );
}

export default Cam;