import React, { Fragment } from 'react';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    cssContent = Object.assign(
        {},
        this.props.background ? { background: this.props.background } : {},
        this.props.width ? { width: (+this.props.width * 2.3) } : {},
        this.props.height ? { height: (+this.props.height * 2.3) } : {}
    )

    element = (<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width={this.props.width ? this.props.width : "30"} height={this.props.height ? this.props.height : "30"} viewBox="0 0 59.5 59.5">
        <g transform="translate(0 16)">
            <g className="b" transform="translate(0 -16)">
                <path className="a" fill={this.props.fill ? this.props.fill : "#00a19a"} d="M21.75,27.188C11.821,27.188-8,32.171-8,42.063V49.5H51.5V42.063c0-9.892-19.821-14.875-29.75-14.875m0-7.437A14.875,14.875,0,1,0,6.875,4.875,14.871,14.871,0,0,0,21.75,19.75" transform="translate(8 10)" />
            </g>
        </g>
    </svg>)


    render() {
        return (
            <Fragment>
                {this.props.urlImage && this.props.urlImage != "" ? (
                    <img src={this.props.urlImage} className="userImage" style={this.cssContent}/>        
                    )  : (
                this.props.background ? (
                    <div className="rounded defaultPadding flex center" style={this.cssContent}>
                        {this.element}
                    </div>

                    ) : (
                        <Fragment>
                            {this.element}
                        </Fragment>
                    )
                    
                    )
                }
            </Fragment>
        )
    }
}

export default Profile;