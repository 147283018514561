import React from 'react';

class ArrowBottom extends React.Component{

    render(){

        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" className="defaultMarginLeft" viewBox="0 0 12 8"><path fill="#fff" d="M6,0l6,8H0Z" transform="translate(12 7) rotate(180) scale(0.7)"/></svg>
        )

    }

}

export default ArrowBottom;