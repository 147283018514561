import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import Base from "../../components/Base";
import Button from "../../components/Button";
import Input from "../../components/InputHook";
import InputMasked from "../../components/InputMaskedHook";
import Select from "../../components/Select";
import Trash from "../../svg/trash";
import EditLarge from "../../svg/edit-large";
import TextArea from "../../components/TextArea";
import Edit from "../../svg/edit";
import {
  getInformationsByType,
  putCustomerCompany,
  getCustomerCompany,
  listMyLeads,
  deleteLead,
  deleteCustomerCompany,
} from "../../request";
import { toast } from "react-toastify";
import Title from "../../components/Title";
import BackIcon from "../../svg/backIcon";
import Modal from "../../components/Modal";

const CompaniesDetailPage = () => {
  const history = useHistory();
  const customerCompanyID = history.location.pathname.split("/").reverse()[0];
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingPutCompany, setIsLoadingPutCompany] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [segment, setSegment] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const [phoneMain, setPhoneMain] = useState("");
  const [phoneSecondary, setPhoneSecondary] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [socialNetwork, setSocialNetwork] = useState("");
  const [observation, setObservation] = useState("");
  const [customField, setCustomField] = useState("");
  const [customFieldLabel, setCustomFieldLabel] = useState("");
  const [toDelete, setToDelete] = useState(true);
  const [toDeleteModal, setToDeleteModal] = useState(false);

  // Endereço
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  // Options for dropdowns
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    getInformationsByType("SEGMENTS", "STATES")
      .then((res) => {
        res.data.forEach((info) => {
          if (info.key && info.key === "STATES") {
            setStatesOptions(
              info.data.sort((a, b) => a.label.localeCompare(b.label))
            );
          }
          if (info.SEGMENTS) {
            const informations = info || "";
            if (informations) {
              setSegmentsOptions(
                informations.SEGMENTS.data.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )
              );
            }
          }
        });
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível obter a lista de responsáveis");
      });
  }, []);

  const putCustomer = () => {
    if (!companyName) {
      toast.warning("O nome da empresa é obrigatório");
      return;
    }

    /* TODO: Retirar hard coded quando for implementar o auto complete */
    setIsLoadingPutCompany(true);
    let payload = {
      id: customerCompanyID,
      companyName,
      segment,
      site,
      email,
      phoneMain,
      phoneSecondary,
      whatsApp,
      socialNetwork,
      observation,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      customField,
    };

    putCustomerCompany(payload)
      .then(() => {
        toast.success("Empresa atualizada com sucesso");
        setIsDisabled(true);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar a empresa");
      })
      .finally(() => setIsLoadingPutCompany(false));
  };

  const refresh = () => {
    history.push("/empresas");
    window.location.reload();
  };

  const deleteLeadAndCompany = async () => {
    setIsLoadingPutCompany(true);
    try {
      toast.warn("Aguarde enquanto os dados são excluídos.");
      console.log("1 - list my leads");
      let leads = await listMyLeads();
      let leadList = leads.data;
      console.log("2- leads.data", leadList);
      let leadsToDelete = [];
      leadList.forEach((item) => {
        if (item.customerCompanyID == customerCompanyID) {
          console.log("3", item.id);
          leadsToDelete.push(item.id);
        }
      });
      toast.success("Aguarde enquanto os dados são excluídos.");
      for (let lead of leadsToDelete) {
        await deleteLead(lead);
        console.log(lead);
      }
      await deleteCustomerCompany(customerCompanyID);
      toast.success("Os dados foram excluídos com sucesso!");
      setIsLoadingPutCompany(false);
      history.push("/empresas");
      window.location.reload();
    } catch (error) {
      if (error.response?.data === "auth/id-token-expired") {
        toast.error(
          "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
        );
        return;
      }
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error("Não foi possível deletar a negócio");
    }
  };

  useEffect(() => {
    let label = JSON.parse(localStorage.getItem("customFields"))?.reduce(
      (field, { key, label }) => {
        if (key === "COMPANIES") return label;
        return field;
      },
      ""
    );
    if (label) setCustomFieldLabel(label);
    handleGetCustomerCompany();
  }, []);

  const handleGetCustomerCompany = (reset = false) => {
    setIsLoading(true);

    getCustomerCompany(
      customerCompanyID // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
    )
      .then(({ data: { ...rest } }) => {
        setIsLoadingPutCompany(false);
        setCompanyName(rest.companyName);
        setSegment(rest.segment);
        setSite(rest.site);
        setEmail(rest.email);
        setPhoneMain(rest.phoneMain);
        setPhoneSecondary(rest.phoneSecondary);
        setWhatsApp(rest.whatsApp);
        setSocialNetwork(rest.socialNetwork);
        setObservation(rest.observation);
        setAddress(rest.address);
        setNumber(rest.number);
        setComplement(rest.complement);
        setNeighborhood(rest.neighborhood);
        setCity(rest.city);
        setState(rest.state);
        setCustomField(rest.customField);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Modal
        className="newContactModal modal-sm"
        active={toDeleteModal}
        statusModal={toDeleteModal}
        content={
          <div>
            <div>
              <h4>Deseja mesmo excluir esta empresa e os respectivos leads?</h4>
              <br />
              <p>
                Não será possível acessar os dados após efetuar esta operação!
              </p>
            </div>
            <div className="flex end">
              <Button
                text={"Excluir"}
                background={"red"}
                paddingHorizontal="30px"
                className="defaultMarginTop"
                marginRight="10px"
                isLoading={isLoadingPutCompany}
                onClick={() => deleteLeadAndCompany()}
              />
              <Button
                text={"Cancelar"}
                paddingHorizontal="30px"
                className="defaultMarginTop"
                onClick={() => {
                  setToDeleteModal(false);
                }}
              />
            </div>
          </div>
        }
      />
      <ProgressBar
        className="loading"
        loading={isLoading}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />
      <ProgressBar
        className="loading"
        loading={isLoadingPutCompany}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />

      <div style={{ marginBottom: "15px" }} className="fullWidth flex">
        <Title text="Empresas" />
      </div>
      <div>
        <BackIcon
          className="pointer flexShrinkNone"
          onClick={() => {
            history.goBack();
          }}
        />
      </div>

      <div className="halfWidth flex card centerSelf">
        <div className="flexGrow defaultMarginRight bodyColumn">
          <div className="flex between">
            {isDisabled ? (
              <h3 style={{ wordBreak: "break-all" }}>
                {companyName || "Nome não informado"}
              </h3>
            ) : (
              <Input
                className="halfWidth"
                placeholder={companyName ? companyName : "Nome da empresa"}
                disabled={isDisabled}
                isDefault={true}
                value={companyName}
                onChange={setCompanyName}
                withoutMargin={true}
              />
            )}

            <div className="editAndDelete end">
              {isDisabled ? (
                <Button
                  icon={<EditLarge />}
                  marginRight="10px"
                  paddingHorizontal="10px"
                  background="none"
                  onClick={() => {
                    setIsDisabled(false);
                    setToDelete(true);
                  }}
                />
              ) : (
                <Button
                  isLoading={isLoadingPutCompany}
                  marginRight="150"
                  className="flex smallMarginTop endSelf"
                  text="Salvar"
                  alignSelf="end"
                  paddingHorizontal="30px"
                  onClick={putCustomer}
                />
              )}

              {
                <Button
                  icon={<Trash />}
                  paddingHorizontal="10px"
                  background="none"
                  onClick={() => setToDeleteModal(true)}
                />
              }
            </div>

            {/* {
              isDisabled ?
                (<Edit width="20" height="12" className="defaultMarginLeft pointer flex flexShrinkNone endSelf" onClick={() => {
                  setIsDisabled(false);
                }} />) :
                (<Button isLoading={isLoadingPutCompany} className="flex smallMarginTop endSelf" text="Salvar" alignSelf="end" paddingHorizontal="30px" onClick={putCustomer} />)
            } */}
          </div>

          <div className="flex center wrap defaultMarginTop">
            <Select
              disabled={isDisabled}
              label="Segmento de mercado"
              placeholder="  "
              isDefault={true}
              value={segment}
              onChange={setSegment}
              options={segmentsOptions}
            />
            <Input
              disabled={isDisabled}
              label="Site"
              value={site}
              isDefault={true}
              onChange={setSite}
            />
            <Input
              className="fullWidth"
              disabled={isDisabled}
              label="E-mail"
              value={email}
              isDefault={true}
              onChange={setEmail}
            />

            <InputMasked
              disabled={isDisabled}
              label="Telefone principal"
              type="text"
              isDefault={true}
              mask="(99) 99999-9999"
              value={phoneMain}
              onChange={setPhoneMain}
              required={true}
              maskChar={null}
            />
            <InputMasked
              disabled={isDisabled}
              label="Telefone secundário"
              type="text"
              isDefault={true}
              mask="(99) 99999-9999"
              value={phoneSecondary}
              onChange={setPhoneSecondary}
              required={true}
              maskChar={null}
            />

            <InputMasked
              disabled={isDisabled}
              label="Whatsapp"
              type="text"
              isDefault={true}
              mask="(99) 99999-9999"
              value={whatsApp}
              onChange={setWhatsApp}
              required={true}
              maskChar={null}
            />
            <Input
              disabled={isDisabled}
              label="Rede social"
              value={socialNetwork}
              isDefault={true}
              onChange={setSocialNetwork}
            />

            <Input
              disabled={isDisabled}
              label="Endereço"
              value={address}
              isDefault={true}
              onChange={setAddress}
              className="inputSpaced smallMarginTop"
            />
            <Input
              disabled={isDisabled}
              label="Número"
              value={number}
              isDefault={true}
              onChange={setNumber}
              className="inputSpaced smallMarginTop"
            />

            <Input
              className=""
              disabled={isDisabled}
              label="Complemento"
              value={complement}
              isDefault={true}
              onChange={setComplement}
            />
            <Input
              className=""
              disabled={isDisabled}
              label="Bairro"
              value={neighborhood}
              isDefault={true}
              onChange={setNeighborhood}
            />

            <Input
              disabled={isDisabled}
              label="Cidade"
              value={city}
              isDefault={true}
              property="city"
              onChange={setCity}
            />
            <Select
              disabled={isDisabled}
              label="Estado"
              placeholder="  "
              isDefault={true}
              value={state}
              onChange={setState}
              options={statesOptions}
            />
            {customFieldLabel && (
              <Input
                className="fullWidth"
                disabled={isDisabled}
                label={customFieldLabel}
                value={customField}
                isDefault={true}
                onChange={setCustomField}
              />
            )}

            <TextArea
              className="fullWidth"
              disabled={isDisabled}
              label="Observações"
              value={observation}
              onChange={setObservation}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Base(CompaniesDetailPage);
