const stages = (state = { current: { id: "" }, refecth: () => { } }, action) => {
    switch (action.type) {
        case "SET_CURRENT_STAGES":
            return { ...state, current: action.value };
        case "RESET_CURRENT_STAGES":
            return { ...state, current: { id: "" } };
        default:
            return state;
    }
}

export default stages;