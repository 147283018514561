import axios from "axios";
import FormData from "form-data";
import { url } from "../script";
import { api, reset } from "./api";

// Create `axios-cache-adapter` instance

const getDefaultHeaders = (isThereToken) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  let token = "";
  if (isThereToken) {
    if (typeof isThereToken === "string") {
      token = isThereToken;
    } else {
      token = localStorage.getItem("token") || "";
    }
    if (token) {
      headers.authorization = token;
    }
  }
  return headers;
};

export const login = async (data) =>
  axios({
    method: "post",
    url: `${url}/auth/login`,
    data,
  });

// Requisição para criar usuário
export const postCompanyProfile = async (data) => {
  return axios({
    method: "post",
    url: `${url}/company/postCompanyProfile`,
    data,
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/company/getAllCompaniesProfiles`);
      },
    },
  });
};

// Requisição para checar a role do usuário
export const getUserMe = async () =>
  // Promise.resolve({ data: "manager" })
  // TODO: Remover hard coded
  axios({
    method: "get",
    url: `${url}/user/getUserMe`,
    headers: getDefaultHeaders(true),
  });

// Requisição para listar usuários
export const getAllCompaniesProfiles = async () => {
  return axios({
    method: "get",
    url: `${url}/company/getAllCompaniesProfiles`,
    headers: getDefaultHeaders(true),
  });
};

export const updateUsers = async (data) => {
  return axios({
    method: "put",
    url: `${url}/user/updateUsers`,
    headers: getDefaultHeaders(true),
    data,
  });
};

//Requisição para resgatar os dados da empresa ao qual o usuário logado pertence
export const getCompanyProfile = async () =>
  axios({
    method: "get",
    url: `${url}/company/getCompanyProfile`,
    headers: getDefaultHeaders(true),
  });

//Deleta os dados da empresa

export const deleteCustomerCompany = async (id) => {
  return api.delete(`${url}/customerCompany/deleteCustomerCompany/${id}`, {
    headers: getDefaultHeaders(true),
    data: { id: id },
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/customerCompany/deleteCustomerCompany/${id}`
        );
      },
    },
  });
};

//Requisição para atualizar os dados da empresa ao qual o usuário logado pertence
export const putCompanyProfile = async (data) => {
  return axios({
    method: "put",
    url: `${url}/company/putCompanyProfile`,
    data,
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/company/getAllCompaniesProfiles`);
      },
    },
  });
};

// Requisição para resgatar os dados do usuário logado
export const getUserProfile = async (token) =>
  axios({
    method: "get",
    url: `${url}/user/getUserProfile`,
    headers: getDefaultHeaders(token),
  });

//Requisição para resgatar os dados do usuário logado
export const getMemberProfile = async () =>
  axios({
    method: "get",
    url: `${url}/user/getMemberProfile`,
    headers: getDefaultHeaders(true),
  });

// Delete membro da empresa
export const deleteMemberProfile = async (id) => {
  return api.delete(`${url}/user/deleteMemberProfile/${id}`, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/user/deleteMemberProfile/${id}`);
      },
    },
  });
};

// Cria um nome membro da empresa (equipe)
export const postMemberProfile = async (data) =>
  axios({
    method: "post",
    url: `${url}/user/postMemberProfile`,
    headers: getDefaultHeaders(true),
    data,
  });

// Requisição para atualizar os dados do usuário logado
export const putMemberProfile = async (data) =>
  axios({
    method: "put",
    url: `${url}/user/putMemberProfile`,
    headers: getDefaultHeaders(true),
    data,
  });

// Lista todos os membros do time
export const getCompanyTeams = async () =>
  // api.get(`${url}/getAllCompanyTeamsMembers`, {
  //   headers: getDefaultHeaders(true),
  // });
  axios({
    method: "get",
    url: `${url}/user/getAllCompanyTeamsMembers`,
    headers: getDefaultHeaders(true),
  });

// Requisição para listar todos contatos
export const listCustomerContacts = async () =>
  api.get(`${url}/clientContact/listCustomerContacts`, {
    headers: getDefaultHeaders(true),
  });

// Requisição para cadastrar novo contato
export const postCustomerContact = async (data) => {
  return api.post(`${url}/clientContact/postCustomerContact`, data, {
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/clientContact/listCustomerContacts`
        );
      },
    },
    headers: getDefaultHeaders(true),
  });
};

// Requisição para atualizar contato
export const putCustomerContact = async (data) => {
  return api.put(`${url}/clientContact/putCustomerContact`, data, {
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/clientContact/listCustomerContacts`
        );
      },
    },
    headers: getDefaultHeaders(true),
  });
};

// Requisição para Deletar o Contato

export const deleteCustomerContact = async (id) =>
  axios({
    method: "delete",
    url: `${url}/clientContact/deleteCustomerContact/${id}`,
    headers: getDefaultHeaders(true),
    data: { id: id },
  });

// Requisição para listar o contato por id
export const getCustomerContact = async (id) =>
  axios({
    method: "get",
    url: `${url}/clientContact/getCustomerContact/${id}`,
    headers: getDefaultHeaders(true),
  });

// Requisição para listar todas empresas
export const listCustomerCompanies = async () =>
  axios({
    method: "get",
    url: `${url}/customerCompany/listCustomerCompanies`,
    headers: getDefaultHeaders(true),
  });

// Requisição para cadastrar nova empresa
export const postCustomerCompany = async (data) => {
  return axios({
    method: "post",
    url: `${url}/customerCompany/postCustomerCompany`,
    headers: getDefaultHeaders(true),
    data,
  });
};

// Requisição para atualizar empresa
export const putCustomerCompany = async (data) => {
  return axios({
    method: "put",
    url: `${url}/customerCompany/putCustomerCompany`,
    headers: getDefaultHeaders(true),
    data,
  });
};

// Requisição para listar a empresa por id
export const getCustomerCompany = async (id) =>
  axios({
    method: "get",
    url: `${url}/customerCompany/getCustomerCompany/${id}`,
    headers: getDefaultHeaders(true),
  });

// Requisição para resgatar as informações comuns da plataforma a partir do seu tipo
export const getInformationsByType = async (...types) => {
  // TODO fazer o cache das informations genéricas aqui no front?
  /*
  const informations = require('./informations');
  const filteredInformations = informations.filter(info => types.includes(info.key));
  return Promise.resolve({ data: filteredInformations });
  */
  const hdr = types.includes("ACTIVITY_TYPE") ? false : true;

  return api.get(`${url}/info/getInformationsByType?types=${types.join(",")}`, {
    headers: getDefaultHeaders(hdr),
  });
};

// Helper para buscar apenas os tipos de atividades das informations
export const listActivitiesTypes = async () =>
  getInformationsByType("ACTIVITY_TYPE").then(({ data }) => data[0]);

// Requisição para resgatar todas as informações comuns da plataforma
export const getAllInformations = async () => {
  const headers = getDefaultHeaders(true);
  return api.get(`${url}/info/getAllInformations`, {
    headers,
  });
};

// Retorna os cargos/times da empresa
export const getCompanyPositions = async () =>
  Promise.resolve({
    data: [
      { name: "manager", label: "Gestão" },
      { name: "seller", label: "Vendas" },
      { name: "pre-seller", label: "Pré-vendas" },
    ],
  });
// api.get(`${url}/getCompanyPositions`, {
//   headers: getDefaultHeaders(true),
// });

// Requisição para salvar um novo negócio
export const getAllStagesByPipelineID = async (pipelineID) => {
  const headers = getDefaultHeaders(true);
  // return axios({
  //   method: "get",
  //   url: `${url}/getAllStagesByPipelineID`,
  //   headers
  // });
  return api.get(`${url}/pipelines/getAllStagesByPipelineID/${pipelineID}`, {
    headers,
  });
};

export const deletePipeline = async (pipelineID) => {
  return api.delete(`${url}/pipelines/deletePipeline/${pipelineID}`, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/pipelines/getAllPipelinesByCompanyID`
        );
        await config.store.removeItem(
          `${url}/pipelines/getAllStagesByPipelineID/${pipelineID}`
        );
        await config.store.removeItem(
          `${url}/lead/getAllLeadsByPipelineID/${pipelineID}`
        );
      },
    },
  });
};

export const deleteLead = async (leadID, pipelineID) => {
  return api.delete(`${url}/lead/deleteLead/${leadID}`, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/lead/getMyLeads`);
        await config.store.removeItem(
          `${url}/lead/getAllLeadsByPipelineID/${pipelineID}`
        );
      },
    },
  });
};

// Requisição para salvar um novo negócio
export const postLead = async (data) => {
  return api.post(`${url}/lead/postLead`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/lead/getMyLeads`);

        await config.store.removeItem(
          `${url}/lead/getAllLeadsByPipelineID/${data.pipelineID}`
        );
        await config.store.removeItem(`${url}/activities/getActivities`);
        await config.store.removeItem(
          `${url}/clientContact/listCustomerContacts`
        );
        await config.store.removeItem(
          `${url}/customerCompany/listCustomerCompanies`
        );
      },
    },
  });
};

// Requisição para editar um novo negócio
export const putLead = async (data, pipelineID) => {
  return api.put(`${url}/lead/putLead`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/lead/getMyLeads`);

        await config.store.removeItem(
          `${url}/lead/getAllLeadsByPipelineID/${pipelineID}`
        );
        await config.store.removeItem(`${url}/activities/getActivities`);
        await config.store.removeItem(
          `${url}/clientContact/listCustomerContacts`
        );
        await config.store.removeItem(
          `${url}/customerCompany/listCustomerCompanies`
        );
      },
    },
  });
};

// Requisição para buscar informações de um negócio
export const getLead = async (leadID) =>
  axios({
    method: "get",
    url: `${url}/lead/getLead/${leadID}`,
    headers: getDefaultHeaders(true),
  });

// Lista todos os leads em que a etapa atual seja de acordo com o time do usuário logado
export const listMyLeads = async () =>
  api.get(`${url}/lead/getMyLeads`, {
    headers: getDefaultHeaders(true),
  });

// Requisição para buscar informações de um negócio
export const getAllLeadsByPipelineID = async (pipelineID, filter) => {
  const headers = getDefaultHeaders(true);
  filter = filter ? `&${filter}` : "";

  /*return axios({
    method: "get",
    url: `${url}/getAllLeadsByPipelineID${filter}`,
    headers,
  });*/

  return filter
    ? axios.get(`${url}/lead/getAllLeadsByPipelineID/${pipelineID}?${filter}`, {
        headers,
      })
    : axios.get(`${url}/lead/getAllLeadsByPipelineID/${pipelineID}`, {
        headers,
      });
};

// Lista as atividades do usuário logado (menu principal Atividades)
export const listMyActivities = async () => {
  return api.get(`${url}/activities/getActivities`, {
    headers: getDefaultHeaders(true),
  });
};

// Requisição para criar uma nova atividade
export const postActivity = async (data) => {
  return api.post(`${url}/activities/postActivity`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/activities/getActivities`);
      },
    },
  });
};

// Requisição para mudar status de uma ativiidade
export const putActivity = async (data) => {
  return api.put(`${url}/activities/putActivity`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/activities/getActivities`);
      },
    },
  });
};

// Requisição para criar uma nova atividade
export const getAllActivitiesByLeadID = async ({ leadID, status }) => {
  const headers = getDefaultHeaders(true);
  let hstatus = "";
  if (status) hstatus = `?Status=${status}`;
  return axios({
    method: "get",
    url: `${url}/activities/getAllActivitiesByLeadID/${leadID}${hstatus}`,
    headers,
  });
};

// Requisição para resgatar uma qualificação realizada
export const getQualification = async ({ leadID }) => {
  const headers = getDefaultHeaders(true);
  return api.get(
    `${url}/qualificationResults/getQualification?leadID=${leadID}`,
    {
      headers,
    }
  );
};

// Requisição para adquirir o template de qualificação
export const getQualificationTemplate = async (companyID) =>
  axios({
    method: "get",
    url: `${url}/qualificationForms/getQualificationTemplate${
      companyID ? `?companyID=${companyID}` : ``
    }`,
    headers: getDefaultHeaders(true),
  });

export const postQualification = async (data, leadID) => {
  return api.post(`${url}/qualificationResults/postQualification`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/qualificationResults/getQualification?leadID=${leadID}`
        );
      },
    },
  });
};

// Requisição para atualizar uma qualificação
export const putQualification = async (data, leadID) => {
  return api.put(`${url}/qualificationResults/putQualification`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/qualificationResults/getQualification?leadID=${leadID}`
        );
      },
    },
  });
};

// Requisição para atualizar uma qualificação
export const putResultLead = async (data, pipelineID) => {
  return api.put(`${url}/lead/putResultLead`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(`${url}/lead/getMyLeads`);
        await config.store.removeItem(
          `${url}/lead/getAllLeadsByPipelineID/${pipelineID}`
        );
      },
    },
  });
};

export const listMembersByTeamPosition = async (...positions) => {
  const params =
    positions && positions.length ? `?positions=${positions.join(",")}` : "";
  return api.get(`${url}/user/getMemberByTeamPosition${params}`, {
    headers: getDefaultHeaders(true),
    // cache: {
    //   maxAge: 15 * 60 * 1000, // 15 minutes
    // },
  });
};

export const getAllPipelineByCompanyID = async () => {
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/pipelines/getAllPipelinesByCompanyID`,
    headers: headers,
  });
};

// Edita um pipeline
export const putPipeline = async (data) => {
  return api.put(`${url}/pipelines/putPipeline`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/pipelines/getAllPipelinesByCompanyID`
        );
        await config.store.removeItem(
          `${url}/pipelines/getAllStagesByPipelineID/${data.id}`
        );
        await config.store.removeItem(
          `${url}/lead/getAllLeadsByPipelineID/${data.id}`
        );
      },
    },
  });
};

// Cria um novo pipeline
export const postPipeline = async (data) => {
  return api.post(`${url}/pipelines/postPipeline`, data, {
    headers: getDefaultHeaders(true),
    cache: {
      invalidate: async (config) => {
        await config.store.removeItem(
          `${url}/pipelines/getAllPipelinesByCompanyID`
        );
        await config.store.removeItem(
          `${url}/pipelines/getAllStagesByPipelineID/${data.id}`
        );
        await config.store.removeItem(
          `${url}/pipelines/getAllLeadsByPipelineID/${data.id}`
        );
      },
    },
  });
};

// Edita um Teplate de qualificação
export const putQualificationTemplate = async (data) =>
  axios({
    method: "put",
    url: `${url}/qualificationForms/putQualificationTemplate`,
    headers: getDefaultHeaders(true),
    data,
  });

export const getCompanyCustomFields = async () => {
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/company/getCompanyCustomFields`,
    headers: headers,
  });
};

export const putCompanyCustomFields = async (data) =>
  axios({
    method: "put",
    url: `${url}/company/putCompanyCustomFields`,
    headers: getDefaultHeaders(true),
    data,
  });

export const putCompanyInformations = async (data) => {
  return axios({
    method: "put",
    url: `${url}/company/putCompanyInformations`,
    headers: getDefaultHeaders(true),
    data,
  });
};

export const listEntitiesByName = async (searchValue) => {
  const headers = getDefaultHeaders(true);
  //headers["SearchValue"] = searchValue;
  const query = `?SearchValue=${searchValue}`;
  return axios({
    method: "get",
    url: `${url}/info/listEntitiesByName${query}`,
    headers,
  });
};

export const recoverEmail = async (email) => {
  const headers = getDefaultHeaders(false);
  const data = {
    email: email,
  };

  return axios({
    method: "post",
    url: `${url}/auth/recoverEmail`,
    headers,
    data,
  });
};

export const recover = async (password, recToken) => {
  const headers = getDefaultHeaders(recToken);
  const data = {
    password: password,
  };

  return axios({
    method: "post",
    url: `${url}/auth/recover`,
    headers,
    data,
  });
};

export const refreshToken = async () => {
  const headers = getDefaultHeaders(true);
  const refToken = localStorage.getItem("refreshToken");
  const body = {
    refreshToken: refToken,
  };
  return await axios.post(`${url}/auth/refreshToken`, body, headers);
};

export const register = async (data) => {
  const headers = getDefaultHeaders(false);

  return axios({
    method: "post",
    url: `${url}/auth/register`,
    headers,
    data,
  });
};

export const changePass = async (data) => {
  const headers = getDefaultHeaders(true);

  return axios({
    method: "put",
    url: `${url}/user/changePass`,
    headers,
    data,
  });
};

export const postImage = async (img) => {
  try {
    let body = new FormData();
    body.set("key", "0dc11ed657a1d55bbe29a1f719ca200f");
    body.append("image", img);

    return axios({
      method: "post",
      url: "https://api.imgbb.com/1/upload",
      data: body,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDashboardInfo = async (month, year) => {
  const query = `?month=${month}&year=${year}`;
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/info/getDashboardInfo${query}`,
    headers,
  });
};

export const getAverageTicket = async (month, year) => {
  const query = `?month=${month}&year=${year}`;
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/info/getAverageTicket${query}`,
    headers,
  });
};

export const getLostLeadsbyReason = async (month, year) => {
  const query = `?month=${month}&year=${year}`;
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/info/getLostLeadsbyReason${query}`,
    headers,
  });
};

//events
export const getEventsByLeadID = async (leadId) => {
  const params = `${leadId}`;
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/events/getEventsByLeadID/${params}`,
    headers,
  });
};

export const getEventsByActivityID = async (activityId) => {
  const params = `${activityId}`;
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/events/getEventsByActivityID/${params}`,
    headers,
  });
};

export const getEventsByQualificationID = async (qualificationID) => {
  const params = `${qualificationID}`;
  const headers = getDefaultHeaders(true);
  return axios({
    method: "get",
    url: `${url}/events/getEventsByQualificationID/${params}`,
    headers,
  });
};

export const postEvent = async (data) => {
  const headers = getDefaultHeaders(true);
  try {
    return axios({
      method: "post",
      url: `${url}/events/postEvent`,
      headers,
      data,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllUserComments = async (userUID) => {
  const headers = getDefaultHeaders(true);
  try {
    return axios({
      method: "get",
      url: `${url}/comments/getAllUserComments/${userUID}`,
      headers,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllLeadComments = async (leadID) => {
  const headers = getDefaultHeaders(true);
  try {
    return axios({
      method: "get",
      url: `${url}/comments/getAllLeadComments/${leadID}`,
      headers,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const postComment = async (data) => {
  const headers = getDefaultHeaders(true);
  try {
    return axios({
      method: "post",
      url: `${url}/comments/postComment`,
      headers,
      data,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const removeCachedResources = async () => reset();

export default {
  //imagepost
  postImage,
  getUserMe,
  // Atividades
  listMyActivities,
  // Profiles
  postCompanyProfile,
  getAllCompaniesProfiles,
  updateUsers,
  getCompanyProfile,
  putCompanyProfile,
  getUserProfile,
  getMemberProfile,
  postMemberProfile,
  putMemberProfile,
  // Misc
  getCompanyTeams,
  listCustomerContacts,
  postCustomerContact,
  listCustomerCompanies,
  postCustomerCompany,
  getAllInformations,
  getInformationsByType,
  getCompanyPositions,
  listActivitiesTypes,
  //Info
  getDashboardInfo,
  getAverageTicket,
  getLostLeadsbyReason,
  // Negócios
  listMembersByTeamPosition,
  getAllPipelineByCompanyID,
  postLead,
  getLead,
  deleteLead,
  listMyLeads,
  postActivity,
  getQualification,
  getQualificationTemplate,
  postQualification,
  putQualification,
  putQualificationTemplate,
  getCompanyCustomFields,
  putCompanyCustomFields,
  putCompanyInformations,
  listEntitiesByName,
  removeCachedResources,
  //auth
  recoverEmail,
  recover,
  login,
  register,
  //events
  getEventsByLeadID,
  getEventsByActivityID,
  getEventsByQualificationID,
  postEvent,
  //comments
  getAllUserComments,
  getAllLeadComments,
  postComment,
};
