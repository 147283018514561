import React, { useEffect, useState } from 'react';
import Base from '../../components/Base';
import ProgressBar from '../../components/ProgressBar';
import { listActivitiesTypes } from '../../request';
import ConfigurationPageTitle from '../../components/ConfigurationPageTitle'
import { toast } from 'react-toastify';

export const TiposAtividadesPage = () => {
  const [items, setItems] = useState("");
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    listActivitiesTypes('ACTIVITY_TYPE')
      .then(({ data }) => {
        console.table(data);
        setItems(data);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error("O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema.");
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error('Não foi possível carregar os tipo de atividades');
       })
      .finally(() => setIsLoading(false));
  }, []);

  const ActivityTypeItem = ({ label, id }) => (
    <div className="activityType defaultMarginBottom flex vCenter">
      <img src={require(`../../png/activities/${id}.png`)} alt={label} />
      <span>{label}</span>
    </div>
  );

  const ActivitiesTypes = ({ items }) => (
    <div className="fullWidth flex column activitiesTypesConfig">
      {items && items.filter(item => item.id !== "note").map(item =>
        <ActivityTypeItem key={item.id} {...item} />
      )}
    </div>
  );

  return (
    <>
      {<ProgressBar className="loading" loading={isLoading} color="#545557" height="4px" style={{ position: "fixed", top: 0, left: 0, }} />}
      <ConfigurationPageTitle title="Tipos de atividades" />
      <div className="fullWidth flex wrapReverse hCenter">
        <div className="card border flex column hCenter flexGrow3">
          <div className="flex between vCenter"></div>
          <h3 className="defaultMarginBottom">Atividades cadastradas para o negócio</h3>
          <ActivitiesTypes items={items} />
        </div>
      </div>
    </>
  )
}

export default Base(TiposAtividadesPage);