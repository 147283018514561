/* eslint-disable */
import React, { useState } from "react";
import Control from "../../../Control";
import { getMonthString } from "../../../../script/util";

function History({ data }) {
  let { date, description, userName, newStageName } = data;
  let initialsMonth;
  let time;

  if (date) {
    let month;
    date = new Date(date);
    month = date.getMonth();
    time = date.toLocaleTimeString("pt-BR");
    initialsMonth = getMonthString(month).toUpperCase();
  }

  return (
    <div className="flex vCenter defaultMarginTop">
      <div className="flex column center defaultMarginRight timelineItem">
        <span className="dayActivity">{date && date.getDate()}</span>
        <span className="monthActivity">
          {initialsMonth && initialsMonth.substr(0, 3)}
        </span>
        <span className="monthActivity">{time}</span>
      </div>
      <div className="flex column center">
        <span className="monthActivity">{userName}</span>
      </div>
      <div className="flex nowrap fullWidth">
        <div className="flex flexGrow">
          <div className="flex column smallMarginLeft">
            <p className="flex vCenter">
              <span style={{ wordBreak: "normal", whiteSpace: "pre-wrap" }}>
                {description +
                  (newStageName ? `, novo estagio : ${newStageName}.` : "")}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
