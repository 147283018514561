import React from 'react';

class Cadence extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30.787 22.685">
                <g transform="translate(0 0)">
                    <path d="M6.824,0H1.963A1.625,1.625,0,0,0,.343,1.62V21.065a1.625,1.625,0,0,0,1.62,1.62H6.824a1.625,1.625,0,0,0,1.62-1.62V1.62A1.625,1.625,0,0,0,6.824,0M18.167,0H13.306a1.625,1.625,0,0,0-1.62,1.62V21.065a1.625,1.625,0,0,0,1.62,1.62h4.861a1.625,1.625,0,0,0,1.62-1.62V1.62A1.625,1.625,0,0,0,18.167,0M-4.518,0H-9.38A1.625,1.625,0,0,0-11,1.62V21.065a1.625,1.625,0,0,0,1.62,1.62h4.861a1.625,1.625,0,0,0,1.62-1.62V1.62A1.625,1.625,0,0,0-4.518,0" transform="translate(11)" />
                </g>
            </svg>
        );
    }
}

export default Cadence;