import React from 'react';

const Attention = ({ style = {width: "13px", height: "13px"} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="attentionIcon" style={{ ...style }} viewBox="0 0 20 20">
    <g transform="translate(-372.952 -328)">
      <circle fill="#d99b9b" cx="10" cy="10" r="10" transform="translate(372.952 328)" />
      <text className="b" fill="#fff" fontSize="13px" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700" transform="translate(382.849 343)">
        <tspan x="-1.878" y="0">!</tspan>
      </text>
    </g>
  </svg>
);

export default Attention;
