import React from 'react';

function Close({ fill = "#00a19a", width = "12", height = "12", ...rest }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} fill={fill} {...rest} viewBox="0 0 18.385 18.385">
            <g transform="translate(-1312.393 -301.808)">
                <g transform="translate(-5286.327 3486.617) rotate(-45)">
                    <rect width="2" height="24" transform="translate(6917 2415)" />
                    <rect width="2" height="24" transform="translate(6906 2428) rotate(-90)" />
                </g>
            </g>
        </svg>
    )

}

export default Close;
