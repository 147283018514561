import React from 'react';
import clsx from 'clsx';

function Input({
    label,
    maxWidth,
    isDefault,
    withoutMargin,
    style,
    inputStyle,
    className,
    classNameLabel,
    onChange,
    ...rest
}) {

    return (
        <>
            {
                isDefault ?
                    (
                        <label className={clsx("flex column secondaryLabel", className, !withoutMargin && "smallMarginTop", (className && className.indexOf("fullWidth") !== -1) ? "fullWidth" : "halfWidth")} style={style}>
                            {label && (<span>{label}</span>)}
                            {
                                <input
                                    {...rest}
                                    className={clsx("variantInput", !withoutMargin && "smallMarginTop", (className && className.indexOf("inputStage") !== -1) ? "strong inputStage" : "")}
                                    onChange={inpt => { onChange(inpt.target.value) }}
                                    style={inputStyle}
                                />
                            }
                        </label>
                    )
                    :
                    (
                        <label className={clsx("fullWidth", classNameLabel)} style={style}>
                            <input
                                {...rest}
                                style={inputStyle}
                                className={className}
                                placeholder={rest.placeholder}
                                onChange={e => onChange(e.target.value)} />
                        </label>

                    )
            }
        </>
    )

}

export default Input;