import React, { useState } from 'react';
import Button from '../../Button';

function Confirm({ closeModal, message, hasConfirm, onConfirm, hasCancel, onCancel }) {

  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true)
    onConfirm().finally(() => {
      setLoading(false)
      closeModal();
    })
  }

  return (
    <div className="flex column defaultMarginTop">
      <p className="textCenter defaultMarginVertical strong" style={{ lineHeight: "24px" }}>{message}</p>
      <div className="flex fullWdith end defaultMarginTop">
        {hasCancel && <Button text="Cancelar" className="defaultMarginRight" onClick={onCancel} paddingHorizontal={"25px"} color="#00a19a" background="#fff" />}
        {hasConfirm && <Button isLoading={loading} text="Ok" onClick={onConfirm ? handleConfirm : closeModal} paddingHorizontal={"25px"} />}
      </div>
    </div>
  );
}

export default Confirm;
