import React, { useState, useEffect } from "react";
import Score from "../../Score";
import Question from "./Question";
import Observation from "./Observation";
import Button from "../../Button";
import ModalTitle from "../ModalTitle";
import { getQualification } from "../../../request";
import Spinner from "../../../svg/spinner";
import { toast } from "react-toastify";
import { revalidateToken } from "../../../utils";

function Qualification({ closeModal, leadID, name, status }) {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const process = async () => {
      try {
        revalidateToken().then(async () => {
          const { data } = await getQualification({ leadID });
          setFields(data[data.length - 1]);
        });
      } catch (error) {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar a qualificação");
        setError(true);
        closeModal();
      } finally {
        setLoading(false);
      }
    };

    process();
  }, [status]);

  return (
    <>
      {!loading && !error ? (
        <>
          <ModalTitle
            title="Qualificação"
            className="qualificationTitle"
            icon={require("../../../png/check2.png")}
          />

          <div className="card border defaultMarginTop flex between vCenter">
            <h4>{name && name !== "" ? name : "Nome não informado"}</h4>
            <Score text="Score final" number={fields?.score} />
          </div>
          <div className="fullWidth flex column">
            <div className="fullWidth flex column">
              {fields &&
                fields.questions?.map((item, index) => {
                  item.order = index + 1;
                  return <Question name="mock1" question={item} />;
                })}
              <Observation
                text={
                  fields?.observation?.annotation
                    ? fields?.observation?.annotation
                    : ""
                }
              />
            </div>
          </div>
          <div className="fullWidth flex end vCenter">
            <Button
              text="OK"
              className="defaultMarginTop"
              paddingHorizontal="30px"
              marginTop="25px"
              onClick={closeModal}
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default Qualification;
