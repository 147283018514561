import functionalities from "../utils/functionalities";
import { toast } from "react-toastify";
import { refreshToken } from "../request/index";

const refresh = async (refToken) => {
  try {
    //const token = localStorage.getItem("token");
    if (refToken && !functionalities.isTokenExpired(refToken)) {
      return refreshToken().then((obj) => {
        window.localStorage.setItem("token", obj.data.token);
        return obj.data.token;
      });
    } else {
      toast.error("O seu acesso expirou! Faça novamente o login no sistema.");
      window.localStorage.clear();
      return false;
    }
  } catch (error) {
    toast.error("Não foi possível atualizar o token do usuário");
  }
};

async function useRefreshToken() {
  const refToken = localStorage.getItem("refreshToken") || "";
  if (refToken && !functionalities.isTokenExpired(refToken)) {
    return refresh(refToken);
  } else {
    return false;
  }
}
// 0,99hrs 3564000 - 10min 600000 --
export default useRefreshToken;
