import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { listCustomerCompanies } from '../../request';
import Base from '../../components/Base';
import Table from '../../components/Table';
import Select from '../../components/Select';
import Title from '../../components/Title';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import NewCompany from '../../components/Modal/NewCompany';
import mountTable from './functions';
import useRefreshToken from '../../hooks/useRefreshToken';

const CompaniesPage = () => {
  useRefreshToken();
  const history = useHistory();
  const [tbody, setTbody] = useState([]);
  const [res, setRes] = useState([]);
  const [atualSort, setAtualSort] = useState("lowerDate");
  const [initialized, setInitialized] = useState(null);
  const [newCompanyModal, setNewCompanyModal] = useState(false);

  const listCompanies = () => {
    setNewCompanyModal(false);
    listCustomerCompanies().then(({ data }) => {
      setRes(data);
      setTbody(
        mountTable({ atualFilter: { id: 'all' }, atualSort: atualSort, data }, () => setInitialized(true))
      );
    })
    .catch((error) => {
      console.error("Erro: ", error.message, " Status: ", error.code);
      //toast.error('Não foi possível carregar as empresas');
     });
  }

  useEffect(() => {
    listCompanies();
  }, [])

  useEffect(() => {
    listCompanies();
  }, [])

  useEffect(() => {
    setTbody(mountTable({ atualFilter: { id: 'all' }, atualSort: atualSort, data: res }));
  }, [atualSort]);

  const handleEdit = (id) => {
    history.push(`/empresas/${id}`);
  }

  return (
    <>
      <Modal className="createModal modal-sm"
        active={newCompanyModal}
        closeModal={() => { setNewCompanyModal(false) }}
        statusModal={newCompanyModal}
        content={
          <NewCompany closeModal={() => {
            setTbody(null);
            setInitialized(false);
            listCompanies(() => setNewCompanyModal(false));
            window.location.reload();
          }} />
        } />
    <div className="fullWidth flex between titleContent">
        <Title text="Empresas" />
      </div>
      <div className="flex between fullWidth">
        <div className="flex flexGrow">
          <Select placeholder="Ordenação" style={{ maxWidth: "150px" }} property="filter2" onChange={(value) => setAtualSort(value)} options={[
            { id: "lowerDate", label: "Mais recentes" },
            { id: "upperDate", label: "Menos recentes" }
          ]} />
        </div>
        <Button text="Nova empresa" paddingHorizontal="25px" onClick={() => { setNewCompanyModal(true) }} />
      </div>

      <Table 
        thead={
          [
            { name: "Nome", id: "name" },
            { name: "Cidade", id: "city" },
            // { name: "Etapa da venda", id: "sellStep" }, // TODO @Denis verificar nome do atributo
            { name: "Último contato", id: "lastcontact" },
            { name: "Contatos", id: "contact" }
          ]
        }
        tbody={tbody}
        handleEdit={(value)=> handleEdit(value)}
        initialized={initialized}
      />
    </>
  )
}
export default Base(CompaniesPage);