/* eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Cancel from "../../../../svg/cancel";
import CheckRounded from "../../../../svg/checkrounded";
import Attention from "../../../../svg/attention";
import clsx from "clsx";
import { getMonthString } from "../../../../script";
import { ACTIVITY_CANCELED, ACTIVITY_COMPLETED } from "../../../../utils";
import Control from "../../../Control";
import { putActivity, postEvent } from "../../../../request";
import { toast } from "react-toastify";

function Activity({ data }) {
  const refetchActivities = useSelector((state) => state.activities.refetch);
  const action = useSelector((state) => state.activities.action);
  const dispatch = useDispatch();

  let { activityTypeID, startTime, endTime, isOverdue, leadID } = data;
  let activityID = data.id || data._id?.valueOf().toString();
  let initialsMonth;

  if (startTime) {
    let month;
    startTime = new Date(startTime);
    month = startTime.getMonth();
    initialsMonth = getMonthString(month).toUpperCase();
  }

  if (endTime) endTime = new Date(endTime);

  // TODO @Denis pegar do informations
  let allTypes = {
    phone: "Telefonar",
    email: "Enviar e-mail",
    whatsapp: "Enviar WhatsApp",
    "in-person": "Reunir-se presencialmente",
    video: "Chamar por vídeo",
    note: "Nota",
  };

  const handleChanging = (value) => {
    dispatch({ type: "SET_LOADING", value });
  };
  const handlePutActivity = (activityID, status) => {
    handleChanging(true);
    putActivity({ activityID, status })
      .then(() => {
        refetchActivities();
        handleChanging(false);
        const eventData = {
          type: "activity",
          activityID: activityID,
          activityTypeID: activityTypeID,
          description: (status = "completed"
            ? `Atividade de ${startTime} concluida`
            : `Atividade de ${startTime} cancelada`),
          leadID: leadID,
          status: status,
        };
        postEvent(eventData);
        setTimeout(() => handleChanging(null), 4000);
        toast.success("Atividade atualizada com sucesso");
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        handleChanging(false);
        setTimeout(() => handleChanging(null), 4000);
        toast.error("Não foi possível cadastrar o negócio");
      });
  };

  return (
    <>
      <div className="flex vCenter defaultMarginTop">
        <div className="flex nowrap fullWidth activity">
          <div className="flex column center tdDate">
            <span className="dayActivity">
              {startTime && startTime.getDate()}
            </span>
            <span className="monthActivity">
              {initialsMonth && initialsMonth.substr(0, 3)}
            </span>
          </div>

          <div
            className={clsx("flex flexGrow defaultMarginLeft pointer")}
            onClick={() => action({ title: allTypes[activityTypeID], ...data })}
          >
            <div className="tdIcon">
              <Control width="25" icon={activityTypeID} />
            </div>
            <div className="flex column smallMarginLeft">
              <div className="flex smallMarginBottom vCenter">
                <h5>{allTypes[activityTypeID]}</h5>
                {isOverdue && <Attention />}
              </div>
              {activityTypeID == "note" ? (
                <p className="flex between center">
                  <span
                    className="smallMarginRight"
                    style={{ wordBreak: "break-all" }}
                  >
                    {data.description}
                  </span>
                </p>
              ) : (
                <span className="flex between center details">
                  {data.lead && data.lead.customerContactName ? (
                    <>
                      <span className="smallMarginRight">
                        {data.lead.customerContactName}
                      </span>
                      <div className="dot"></div>
                    </>
                  ) : null}
                  {data.lead && data.lead.customerCompanyName ? (
                    <span className="smallMarginLeft">
                      {data.lead.customerCompanyName}
                    </span>
                  ) : null}
                </span>
              )}
            </div>
          </div>

          <div className={clsx("flex end activityAction vCenter between")}>
            <Cancel
              onClick={() => handlePutActivity(activityID, ACTIVITY_CANCELED)}
            />
            <CheckRounded
              onClick={() => handlePutActivity(activityID, ACTIVITY_COMPLETED)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Activity;
