import React, { Fragment } from "react";

class Graph extends React.Component {
  render() {
    return (
      <Fragment>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          fill="#00a19a"
          width="30px"
          height="30px"
          viewBox="0 0 52 52"
        >
          <path
            d="M2,45.5c0,2.2,1.8,4,4,4h42.4c0.9,0,1.6-0.7,1.6-1.6v-2.8c0-0.9-0.7-1.6-1.6-1.6l-38.9,0
	C8.7,43.5,8,42.8,8,42L8,4.1c0-0.9-0.7-1.6-1.6-1.6H3.6C2.7,2.5,2,3.2,2,4.1V45.5z"
          />
          <path
            d="M49.7,14.1c0-1.7-1.3-3-3-3c-0.9,0-1.6,0.4-2.2,0.9l-8.6,8.6L30,15c0,0,0,0,0,0l-0.1-0.1
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0L14.2,26.5
	c-0.6,0.6-1,1.3-1,2.2c0,1.7,1.3,3,3,3c0.7,0,1.4-0.3,1.9-0.7l9.8-9.7l5.7,5.6c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2
	c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0.1
	c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.2
	c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1l0.1-0.1C37.9,27.1,38,27,38,27l10.8-10.7C49.3,15.7,49.7,15,49.7,14.1z"
          />
        </svg>
      </Fragment>
    );
  }
}

export default Graph;
