import React, { useEffect, useCallback, useState } from "react";
import {
  getUserMe,
  getCompanyCustomFields,
  getInformationsByType,
  getAllPipelineByCompanyID,
  listMyLeads,
  listCustomerContacts,
  listCustomerCompanies,
  refreshToken,
  getMemberProfile,
  getUserProfile,
} from "../request";
import { tokenInfo, isTokenExpired } from "../../src/utils/index";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import { toast } from "react-toastify";

const PrivateRoute = ({
  children,
  component,
  redirect,
  hasBase,
  roles,
  ...rest
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentRole, setCurrentRole] = useLocalStorage("role", "");
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [uid, setUid] = useState(localStorage.getItem("uid") || "");
  const [userImageUrl, setUserImageUrl] = useLocalStorage("userImageUrl", "");
  const [validToken, setValidToken] = useState(
    !isTokenExpired(localStorage.getItem("token"))
  );

  const status = useSelector((state) => state.status);
  const page = component || children;

  const tokenErrorHandler = (error) => {
    if (error.response?.data === "auth/id-token-expired") {
      toast.error(
        "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
      );
      return;
    }
    toast.error("Não foi possível atualizar o token do usuário");
  };

  // if (!validToken) {
  //   try {
  //     refreshToken().then(({ data }) => {
  //       setToken(data.token);
  //       setValidToken(true);
  //     });
  //   } catch (error) {
  //     tokenErrorHandler(error);
  //   }
  // }
  useEffect(() => {
    setValidToken(!isTokenExpired(token));
    window.localStorage.setItem("token", token);
  }, [token]);

  // Valida token caso invalido e atualiza infos
  useEffect(() => {
    if (!validToken) {
      refreshToken()
        .then((res) => {
          const newToken = res.data.token;
          setToken(newToken);
          window.localStorage.setItem("token", newToken);
          const userdata = tokenInfo(newToken);
          if (!uid) setUid(userdata.userUID);
          localStorage.setItem("isAuthenticatedWithEmail", "true");
          getUserProfile(newToken).then((data) => {
            const currentUser = data.data;
            const imgURL = currentUser.photoURL
              ? currentUser.photoURL
              : require(`../png/user-profile.png`);
            setUserImageUrl(imgURL);
            window.localStorage.setItem("userImageUrl", imgURL);
            window.localStorage.setItem("userName", currentUser.name);
          });
        })
        .catch((error) => {
          tokenErrorHandler(error);
        });
    }
  }, [validToken]);

  useEffect(() => {
    if (history.location.pathname === "/sucesso") {
      setStatus(true);
    }
    if (validToken) {
      if (!currentRole) {
        getUserMe()
          .then((response) => {
            setCurrentRole(response.data);
            setStatus(true);
          })
          .catch((error) => {
            tokenErrorHandler(error);
            //toast.error('Não foi carregar as informações do usuário');
          });
      } else {
        setStatus(true);
      }

      if (!localStorage.getItem("customFields")) {
        getCompanyCustomFields()
          .then(({ data }) => {
            const customFields = Object.entries(data).map((element) => {
              return { key: element[0], ...element[1] };
            });
            localStorage.setItem("customFields", JSON.stringify(customFields));
          })
          .catch((error) => {
            console.error("Erro: ", error.message, " Status: ", error.code);
            //toast.error('Não foi carregar os campos customizados da empresa');
          });
      }
    }
  }, [history.location.pathname, validToken]);

  const setStatus = useCallback(
    (status) => {
      dispatch({ type: "CHANGE_STATUS", status });
    },
    [dispatch]
  );

  // useEffect(() => {
  //   refreshToken()
  //     .then((data) => {
  //       if (data.token) {
  //         const userdata = tokenInfo(data.token);

  //         if (!uid) setUid(userdata.userUID);

  //         localStorage.setItem("token", data.token);
  //         setToken(data.token);
  //         localStorage.setItem("isAuthenticatedWithEmail", "true");
  //         getUserProfile().then((currentUser) => {
  //           localStorage.setItem("userImageUrl", currentUser.photoURL);
  //           setUserImageUrl(currentUser.photoURL);
  //         });
  //         setStatus(true);
  //       } else {
  //         setStatus(false);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response?.data === "auth/id-token-expired") {
  //         toast.error(
  //           "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
  //         );
  //         setStatus(false);
  //         return;
  //       }
  //       toast.error("Não foi possível atualizar o token do usuário");
  //     });
  // }, [status, setStatus]);

  return (
    <>
      {status !== null ? (
        <Route
          {...rest}
          render={() => {
            if (status) {
              if (roles.indexOf(currentRole) !== -1) {
                return page;
              } else {
                if (history.location.pathname !== "/sucesso")
                  return <Redirect to={"/negocios"} />;
                return page;
              }
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
      ) : (
        null
      )}
    </>
  );
};

export default PrivateRoute;
