import React from 'react';

class Magnify extends React.Component{
    render(){
        return(
            <div onClick={this.props.onClick}>
                <svg xmlns="http://www.w3.org/2000/svg"  className={this.props.class?this.props.class:"pointer"} viewBox="0 0 20 20"><defs></defs><path /**fill="#fff"*/ fill="#545557" d="M17.294,15.579h-.9l-.32-.309a7.444,7.444,0,1,0-.8.8l.309.32v.9L21.3,23,23,21.3Zm-6.861,0a5.146,5.146,0,1,1,5.146-5.146A5.139,5.139,0,0,1,10.433,15.579Z" transform="translate(-3 -3)"/></svg>
            </div>
            
        )
    }
}

export default Magnify;