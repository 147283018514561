import React from 'react';

function EditLarge({ width = "21", height = "21", ...props }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" {...props}>
            <path fill="#545557" d="M3,20.415V25H7.583L21.1,11.482,16.516,6.9ZM24.643,7.938a1.217,1.217,0,0,0,0-1.723l-2.86-2.86a1.217,1.217,0,0,0-1.723,0L17.823,5.591l4.583,4.583Z" transform="translate(-3 -2.997)" />
        </svg>
    );

}


export default EditLarge;