import React from 'react'
import clsx from 'clsx';

function Common({ className, variant, cadence, ...rest }) {
    return (
        <div className={clsx("chip flex around vCenter", className, variant)} {...rest}>
            {cadence}
        </div>
    )
}

export default Common;