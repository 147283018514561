import React from 'react';
import clsx from 'clsx';

export const ModalTitle = (props) => (
  <div className={clsx("flex vCenter defaultMarginBottom titleModal", props.className)}>
    <img alt="" src={props.icon} className="defaultMarginRight iconTitle" />
    <h3>{props.title}</h3>
    { props.children }
  </div>
);

export default ModalTitle;
