import React from 'react';
import { Link } from 'react-router-dom'
import Hr from '../../../components/Hr';
import Button from '../../../components/Button';
import { useHistory } from 'react-router-dom';

function Config(props) {

  const history = useHistory();

  return (
    <div className="flexGrow card border config defaultPadding smallMargin">
      <div className="flex vCenter">
        {props.icon}
        <h3 className="defaultMarginLeft">{props.title}</h3>
      </div>
      <Hr className="noLimit defaultMarginVertical" />
      <p>{props.text}</p>
      <div className="flex center fullWidth defaultMarginTop">
        <Button text="Ver mais" className="mainButton" onClick={() => window.location.href = props.href} />
      </div>
    </div>
  );
}

export default Config;
