import React from 'react';
import clsx from 'clsx';
import Spinner from '../../svg/spinner';
import ReactTooltip from "react-tooltip";

function Button({ className, disabled, onClick, icon, text, isLoading, marginTop, paddingHorizontal, marginBottom, marginRight, alignSelf, background, color , width }) {

    return (
        <button className={clsx("pointer defaultButton flex center", className)} disabled={disabled ? disabled : false} type="button" onClick={onClick} style={
            Object.assign({},
                (marginTop && { marginTop }),
                (marginBottom && { marginBottom }),
                (marginRight && { marginRight }),
                (alignSelf && { alignSelf }),
                (background && { background }),
                (color && { color }),
                (paddingHorizontal && { padding: "0px " + paddingHorizontal }),
                (width && { width }),
            )}>
            {
                isLoading ?
                    <Spinner fill="#fff" className="largeMarginRight fixed" /> :
                    (icon && typeof icon != "object" ? (<img alt="" src={icon} className="smallMarginRight" />) : icon)
            }
            <span className="flexShrinkNone"> {text && text}</span>
        </button>
    )

}

export default Button;