import React from 'react'
import clsx from 'clsx';

function Full({ className, icon, text, variant, duration, ...rest }) {
    return (
        <div className={clsx("chip flex between vCenter", className, variant)} {...rest}>
            {(icon || text) && <div className="flex between">

                <div className="flex vCenter">
                    {icon && <div className="smallMarginRight">{icon}</div>}
                    {text}
                </div>

            </div>}

            {duration &&
                <div>
                    {duration}
                </div>
            }



        </div>
    )
}

export default Full;