import React from 'react';
import TimeField from 'react-simple-timefield';
import clsx from 'clsx';

const InputHour = ({ isDefault, onChange, className, label, style, ...rest }) => (
    <>
        {
            isDefault ?
                (
                    <label className={clsx("halfWidth flex column secondaryLabel smallMarginTop", (className && className.indexOf("fullWidth") !== -1) ? "fullWidth" : "halfWidth", (className && className.indexOf("noLimit") !== -1 && "noLimit"))}>
                        {label && (<span>{label}</span>)}
                        <TimeField {...rest}
                            className={clsx("variantInput smallMarginTop", className)}
                            onChange={(e) => onChange(e.target.value)}
                            style={{ ...style, width: "auto" }}
                        />
                    </label>
                )
                :
                (
                    <label>
                        <TimeField {...rest}
                            className={className}
                            onChange={(e) => onChange(e.target.value)}
                            style={{ ...style, width: "auto" }} />
                    </label>
                )

        }
    </>
)

export default InputHour;