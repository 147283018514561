import React from 'react';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';

const InputMasked = ({ isDefault, onChange, label, className, ...rest }) => (
    <>
        {
            isDefault ?
                (
                    <label className={clsx("halfWidth flex column secondaryLabel smallMarginTop", (className && className.indexOf("noLimit") !== -1 && "noLimit"), (className && className.indexOf("marginLeft") !== -1 && "marginLeft"))}>
                        {label && (<span>{label}</span>)}
                        <ReactInputMask {...rest}
                            className={clsx("variantInput smallMarginTop", (className && className.indexOf("marginLeft") !== -1 && ""))}
                            onChange={(e) => onChange(e.target.value)} />
                    </label>
                )
                :
                (
                    <label>
                        <ReactInputMask {...rest}
                            className={(className && className.indexOf("criarContaScreenInput") !== -1 && "criarContaScreenInput")}
                            onChange={(e) => onChange(e.target.value)} />
                    </label>
                )

        }
    </>
)

export default InputMasked;