import React from 'react';
import clsx from 'clsx';

function Input({
    label,
    maxWidth,
    isDefault,
    withoutMargin,
    style,
    inputStyle,
    className,
    property,
    validation,
    callback,
    onChange,
    ...rest
}) {

    return (
        <>
            {
                isDefault ?
                    (
                        <label className={clsx("flex column secondaryLabel", className, !withoutMargin && "smallMarginTop", (className && className.indexOf("fullWidth") !== -1) ? "fullWidth" : "halfWidth")} style={style}>
                            {label && (<span>{label}</span>)}
                            <input
                                className={clsx("variantInput", !withoutMargin && "smallMarginTop")}
                                onChange={inpt => { onChange(property, inpt.target.value, (validation ? validation : null), callback) }}
                                style={inputStyle}
                                {...rest}
                            />
                        </label>
                    )
                    :
                    (
                        <label className="fullWidth" style={style}>
                            <input
                                {...rest}
                                style={inputStyle}
                                className={className}
                                onChange={e => onChange(e.target.value)} />
                        </label>

                    )
            }
        </>
    )

}

export default Input;