import { putCompanyProfile, updateUsers } from "../../request";

const formatDate = (value) => (value <= 9 ? `0${value}` : value);

const upperSort = (property) => {
  return (a, b) => {
    let nameA =
      typeof a[property] === "string" ? a[property].toUpperCase() : a[property];
    let nameB =
      typeof b[property] === "string" ? b[property].toUpperCase() : b[property];
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };
};

const lowerSort = (property) => {
  return (a, b) => {
    let nameA =
      typeof a[property] === "string" ? a[property].toUpperCase() : a[property];
    let nameB =
      typeof b[property] === "string" ? b[property].toUpperCase() : b[property];
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  };
};

const sortByEmail = (array, atualSort) => {
  if (atualSort == "lowerEmail") {
    array.sort(lowerSort("email"));
  } else {
    array.sort(upperSort("email"));
  }
  return array;
};

const sortByStatus = (array, atualSort) => {
  if (atualSort == "lowerStatus") {
    array.sort(lowerSort("active"));
  } else {
    array.sort(upperSort("active"));
  }
  return array;
};

let mountTable = ({ atualFilter, atualSort, data = [] }, callback) => {
  data = data || [];
  let array = data;

  if (atualSort.type == "email") {
    array = sortByEmail(array, atualSort.variant);
  }

  if (atualSort.type == "status") {
    array = sortByStatus(array, atualSort.variant);
  }
  //Ordena por data de criação no sistema
  array.sort((a, b) => b.created - a.created);

  array = array
    .filter(({ active }) => {
      if (atualFilter === "0") return true;
      if (atualFilter === "1" && active) return true;
      if (atualFilter === "2" && !active) return true;
      return false;
    })
    .map(
      (
        { email, phone, cnpj, segment, teamSize, goal, created, active, id },
        index
      ) => {
        created = new Date(created);
        return {
          fields: {
            email: { value: email },
            phone: { value: phone },
            cnpj: { value: cnpj },
            segment: { value: segment },
            teamSize: { value: teamSize },
            goal: { value: goal },
            date: {
              value:
                formatDate(created.getHours()) +
                ":" +
                formatDate(created.getMinutes()) +
                " - " +
                formatDate(created.getDate()) +
                "/" +
                formatDate(created.getMonth() + 1),
              isThereCheck: true,
              className: "end",
              isActived: active,
              action: async () => {
                // eslint-disable-next-line no-async-promise-executor
                const userPromise = new Promise(async (resolve) => {
                  const promise = await updateUsers({
                    companyProfileID: id,
                    active: !active,
                    email: email,
                  });
                  resolve(promise);
                });
                // eslint-disable-next-line no-async-promise-executor
                const compPromise = new Promise(async (resolve) => {
                  const promise = await putCompanyProfile({
                    companyProfileID: id,
                    active: !active,
                  });
                  resolve(promise);
                });

                await Promise.all([userPromise, compPromise]).then((data) => {
                  return [data[1]];
                });
              },
            },
          },
        };
      }
    );

  if (callback) callback();
  return array;
};

export default mountTable;
