import React, { useState } from 'react';
import clsx from 'clsx';
import StageHeader from '../../../Stage/StageHeader';
import Input from '../../../InputHook';
import SuggestedActivitie from './SuggestedActivitie';
import Trash from '../../../../svg/trash';

const Stage = ({ item: { suggestedActivities = [], ...item }, index: indexFields, handleFields, setAtualStage, atualStage, type, onDrop, onDragOver, onDragStart }) => {

    const [stage, setStage] = useState({ suggestedActivities, ...item });

    const handleStage = (value, property, index) => {
        let auxPrev = { suggestedActivities, ...item };
        auxPrev.suggestedActivities = [...auxPrev.suggestedActivities];//NOTE: Não apagar, importante para remover a referência do objeto inicial
        if (index || index === 0) {
            auxPrev[property][index] = value;
        } else {
            auxPrev[property] = value;
        }
        setStage(auxPrev);
        handleFields(auxPrev, 'stages', item.id);
    }

    const deleteSuggestedActivity = (item, index) => {
        let auxPrev = { suggestedActivities, ...item };
        auxPrev.suggestedActivities = [...auxPrev.suggestedActivities];//NOTE: Não apagar, importante para remover a referência do objeto inicial
        auxPrev.suggestedActivities.splice(index, 1);
        setStage(auxPrev);
        handleFields(auxPrev, 'stages', item.id);
    }

    return (
        <div style={{position: 'relative'}} className={clsx("stageColumn pointer flexShrinkNone")} onDrop={(e) => onDrop(e, item.id)} onDragOver={(e) => onDragOver(e, item.id)}>
            <StageHeader active={true} text={

                <Input className={"fullWidth inputStage"} style={{position: "relative" }}inputStyle={{ backgroundColor: "transparent", color: "#fff", textAlign: "center" }} value={stage.description} onChange={(value) => handleStage(value, "description")} isDefault={true} withoutMargin={true} />
            } className={clsx("smallMarginBottom", item.step === "pre-seller" && "light")} editable={true} />
            <div style={{position: 'relative'}} className={clsx("dashed rounded contentStage", atualStage !== null && (atualStage === item.id ? "active" : "disabled"))}>
                {
                    suggestedActivities.map(({ id, duration, cadence }, index, { length }) => {
                        return (
                            <>
                                <div className="flex end" style={{position: 'relative'}}>
                                    <Trash
                                        className="pointer flexShrinkNone"
                                        height="10px"
                                        onClick={(e) => deleteSuggestedActivity(item, index)}
                                        style={{ zIndex: 1, position: 'relative', top: "18px", left: "17px" }}
                                    />
                                </div>
                                <SuggestedActivitie draggable="true" key={id + duration + index} id={id} duration={duration} cadence={cadence} index={index} length={length} handleStage={handleStage} onDragStart={(e) => onDragStart(e, id, index, item.id)} />

                            </>
                        )
                    })
                }
            </div>
        </div>
    )

}
export default Stage;
