import React, { useState, useEffect } from "react";
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Activities from "../../components/Activities";
import Base from "../../components/Base";
import ProgressBar from "../../components/ProgressBar";
import { listMyActivities, listMyLeads } from "../../request";
import ActivityModal from "../../components/Modal/Activity";
import Modal from "../../components/Modal";
import Title from "../../components/Title";
import { toast } from "react-toastify";
import useRefreshToken from "../../hooks/useRefreshToken";
import { isTokenExpired } from "../../../src/utils/index";

const ActivitiesPage = () => {
  // const history = useHistory();
  const [activity, setActivity] = useState();
  const [activitiesOverdue, setActivitiesOverdue] = useState([]);
  const [activitiesToday, setActivitiesToday] = useState([]);
  const [activitiesUpcoming, setActivitiesUpcoming] = useState([]);
  const [activityModal, setActivityModal] = useState(null);
  const [leads, setLeads] = useState([]);
  const [validToken, setValidToken] = useState(false);

  if (!isTokenExpired(window.localStorage.getItem("token")) && !validToken) {
    setValidToken(true);
  }

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.activities.loading);

  useEffect(() => {
    if (validToken) {
      loadData();
    }
  }, [validToken]);

  useRefreshToken().then((ntoken) => {
    if (!isTokenExpired(ntoken)) {
      setValidToken(true);
    } else {
      setValidToken(false);
    }
  });

  dispatch({ type: "SET_REFETCH", value: loadData });
  dispatch({ type: "SET_ACTION", value: openModal });

  function setIsLoading(value) {
    dispatch({ type: "SET_LOADING", value: value });
  }

  function loadData() {
    setIsLoading(true);
    listMyActivities()
      .then(({ data }) => {
        const sortOverdue = [...data.overdue].sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );
        setActivitiesOverdue(sortOverdue);
        const sortToday = [...data.today].sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );
        setActivitiesToday(sortToday);
        const sortUpcoming = [...data.upcoming].sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        );
        setActivitiesUpcoming(sortUpcoming);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as atividades");
      })
      .finally(() => {
        if (!leads || leads.length === 0) {
          listMyLeads()
            .then(({ data }) => {
              const sortedData = [...data].sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              setLeads(sortedData);
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível carregar os negócios");
            })
            .finally(() => {
              setIsLoading(false);
              setTimeout(() => setIsLoading(null), 4000);
            });
        }
      });
  }

  function openModal(activity) {
    setActivity(activity);
    setActivityModal(true);
  }

  return (
    <>
      {
        <ProgressBar
          className="loading"
          loading={isLoading}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }

      <Modal
        className="modal-sm"
        active={activityModal}
        closeModal={() => {
          setActivityModal(false);
        }}
        content={
          <ActivityModal
            activity={activity}
            status={activityModal}
            leads={leads}
            closeModal={() => {
              setActivityModal(false);
            }}
            refetch={loadData}
          />
        }
      />

      <div className="fullWidth flex between titleContent">
        <Title text="Atividades" />

        <Button
          text="Nova atividade"
          className="mainButton flexShrinkNone"
          onClick={() => openModal()}
        />
      </div>
      <div className="fullWidth flex changeDirection bodyContent">
        <div className="flexGrow defaultMarginRight bodyColumn fullWidth">
          <Activities title="Atividades atrasadas" data={activitiesOverdue} />
          <Activities title="Atividades de hoje" data={activitiesToday} />
        </div>
        <div className="flexGrow flex between defaultMarginLeft bodyColumn fullWidth">
          <Activities
            title="Atividades futuras"
            data={activitiesUpcoming}
            action={() => {}}
          />
        </div>
      </div>
    </>
  );
};

export default Base(ActivitiesPage);
