import React, { useState, useEffect } from 'react';
import { postActivity } from '../../../request';
import TextArea from '../../TextArea';
import Button from '../../Button';
import ModalTitle from '../../Modal/ModalTitle';
import InputHour from '../../InputHour/index';
import InputMasked from '../../InputMaskedHook/index';
import { removeEmptyKeys } from '../../../script';
import {payloadActivityValidation } from "../../../utils"
import { toast } from 'react-toastify';

function ContinueLater({ status, leadID, closeModal, refetch }) {

  
  const [fields, setFields] = useState({
    leadID,// TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
    activityTypeID: "",
    startTime: "",
    endTime: "",
    description: "",
    date: "",
  });

  useEffect(() => {
    if (status === false) {
      //resetFields()
      setFields({
        leadID,
        activityTypeID: "",
        startTime: "",
        endTime: "",
        description: "",
        date: "",
      });
    }
  }, [status])

  const [loadingPostActivity, setLoadingPostActivity] = useState(false);

  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  }

  const handlePostActivity = ({ date, endTime, startTime, ...rest }) => {
    rest.date = date
    rest.endTime = endTime
    rest.startTime = startTime
    const isValid = payloadActivityValidation(rest)
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map(item => {return (<span>{item.message}<br/><br/></span>)})}
          </>
        )
      }
      toast.warning(<Msg/>)
      setLoadingPostActivity(false)
      return;
    }

    setLoadingPostActivity(true);

    date = date.split("/").reverse();
    date.splice(1, 1, (+date[1] - 1));

    endTime = new Date(...[...date, ...endTime.split(":")]).getTime();
    startTime = new Date(...[...date, ...startTime.split(":")]).getTime();

    let payload = removeEmptyKeys(rest);
    postActivity({
      ...payload,
      endTime,
      startTime
    }).then(() => {
      toast.success('Atividade agendada com sucesso');
      setFields({
        leadID,
        activityTypeID: "",
        startTime: "",
        endTime: "",
        description: "",
        date: "",
      })
      setLoadingPostActivity(false);
      refetch();
    }).catch((error) => {
      if (error.response?.data === "auth/id-token-expired") {
        toast.error('O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema.');
        return;
      }
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error('Não foi possível agendar a atividade');
      setLoadingPostActivity(false);
    })
      .finally(() => closeModal());
  }

  return (
    <>
      <ModalTitle title="Continuar depois" icon={require('../../../png/clock_cover.png')} />
      <div className="flex reverseEnd flexShrinkNone">
        <div className="fullWidth flex">
          <InputMasked label="Data" placeholder={"00/00/0000"} isDefault={true} value={fields.date} onChange={(value) => { handleFields(`${value}`, "date") }} style={{ maxWidth: "200px" }} mask="99/99/9999" maskChar={null} />
          <InputHour label="Hora de início" isDefault={true} value={fields.hour} onChange={(value) => handleFields(value, "startTime")} mask="99:99"/>
          <InputHour label="Hora de fim" isDefault={true} value={fields.hour} onChange={(value) => handleFields(value, "endTime")} mask="99:99"/>
        </div>
      </div>

      <TextArea isBusinessPage={false} label="Atividade" className="fullWidth" isDefault={true} value={fields.description} onChange={(value) => handleFields(value, "description")} controlled={true} setType={(value) => handleFields(`${value || ""}`, "activityTypeID")} />

      <div className="fullWidth flex end vCenter defaultMarginTop">
        <Button text="Agendar" paddingHorizontal="20px" marginTop="10px" isLoading={loadingPostActivity} onClick={() => handlePostActivity(fields)} />
      </div>

    </>
  );
}

export default ContinueLater;
