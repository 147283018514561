import React, {Fragment} from 'react';


class Calendar extends React.Component{
    
    render(){
        return(
            <Fragment>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.4 26"><g transform="translate(-951 -530)"><path d="M23.8,3.6H22.5V1H19.9V3.6H9.5V1H6.9V3.6H5.6A2.588,2.588,0,0,0,3.013,6.2L3,24.4A2.6,2.6,0,0,0,5.6,27H23.8a2.608,2.608,0,0,0,2.6-2.6V6.2A2.608,2.608,0,0,0,23.8,3.6Zm0,20.8H5.6V10.1H23.8ZM8.2,12.7h6.5v6.5H8.2Z" transform="translate(948 529)"/></g></svg>
            </Fragment>
        
        )

    }

}

export default Calendar;