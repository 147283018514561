/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "../Button";
import Comment from "./Comment";
import Subtitle from "../Subtitle";
import clsx from "clsx";
import Spinner from "../../svg/spinner";

function Comments({ title, data = [], className, loading }) {
  const [active, setActive] = useState(false);
  const [commentData, setCommentData] = useState(data);

  useEffect(() => {
    if (!active && data.length > 3) {
      setCommentData([data[0], data[1], data[2]]);
    } else {
      setCommentData(data);
    }
  }, [data, active]);

  return (
    <>
      <div className={clsx("fullWidth activityGroup", className)}>
        <div className="flex between defaultMarginBottom">
          <Subtitle
            title={title}
            quantity={data.length}
            className="defaultMarginBottom"
          />
          {data.length > 3 && (
            <Button
              text={active ? "Ver menos" : "Ver todos"}
              className="seeAll"
              onClick={() => {
                setActive(!active);
              }}
            />
          )}
        </div>

        <div className={clsx("flex column fullWidth")}>
          {!loading ? (
            commentData.length ? (
              commentData.map((item) => <Comment key={item.id} data={item} />)
            ) : (
              <p className="strong textCenter smallMarginTop defaultPaddingTop emptyContent">
                Nenhum comentário encontrado
              </p>
            )
          ) : (
            <div className="fullWidth flex center">
              <Spinner fill="#000" className="smallMarginRight" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Comments;
