import React, { useState, useEffect } from "react";
import {
  postMemberProfile,
  putMemberProfile,
  getCompanyPositions,
} from "../../../request";
import Button from "../../Button";
import Input from "../../InputHook";
import InputMasked from "../../InputMaskedHook";
import Select from "../../Select";
import { toast } from "react-toastify";

function NewTeamMember({ closeModal, teams, teamMember, status }) {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [positionsOptions, setPositionsOptions] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    getCompanyPositions()
      .then((res) => setPositionsOptions(res.data))
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar os cargos da empresa");
      });
  }, []);

  // Para a edição de membro
  useEffect(() => {
    if (status) {
      setIsEditing(!!teamMember);
      if (teamMember) {
        setName(teamMember.name);
        setPosition(teamMember.position);
        setEmail(teamMember.email);
        setPhone(teamMember.phone || "");
        setSecondPhone(teamMember.secondPhone || "");
      } else {
        resetFields();
      }
    }
  }, [status]);

  const saveTeamMember = () => {
    if (!name || !position || !email) {
      toast.warning("Os campos nome, cargo e e-mail são obrigatórios");
      return;
    }
    // O time selecionado é baseado no cargo na empresa
    const selectedTeam = teams.find((team) => team.Team.name === position);

    setLoading(true);
    let payload = {
      teamID: selectedTeam.id,
      name,
      position,
      email,
      phone,
      secondPhone,
    };
    //console.log(payload);
    if (isEditing) {
      //delete payload.position;
      payload.id = teamMember.id;
    }

    const endpointFunc = isEditing ? putMemberProfile : postMemberProfile;

    endpointFunc(payload)
      .then(() => {
        if (isEditing) {
          toast.success(`Membro atualizado com sucesso!`);
        } else {
          toast.success(`Membro cadastrado com sucesso!
          Senha enviada ao novo usuário no email cadastrado.`);
        }
        resetFields();
        closeModal();
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível validar as informações do novo membro");
        setLoading(false);
      });
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSecondPhone("");
    // TODO @Anderson, não está limpando a opção selecionada no cargo (position)
    setPosition("");
  };

  return (
    <>
      <div className="flex vCenter">
        <img
          alt=""
          src={require("../../../png/contact.png")}
          className="defaultMarginRight iconTitle"
        />
        <h3>{teamMember ? "Editar membro" : "Novo membro"}</h3>
      </div>

      <div className="fullWidth flex center wrap defaultMarginVertical contentInputs">
        <Input
          label="Nome"
          className="fullWidth inputSpaced"
          disabled={false}
          isDefault={true}
          value={name}
          onChange={setName}
        />
        <Select
          label="Cargo na empresa"
          /**disabled={isEditing}*/ placeholder="  "
          isDefault={true}
          value={position}
          valueKey="name"
          onChange={setPosition}
          options={positionsOptions}
        />
        <Input
          label="E-mail"
          disabled={isEditing}
          value={email}
          isDefault={true}
          onChange={setEmail}
        />
        <InputMasked
          label="Telefone principal"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={phone}
          onChange={setPhone}
          required={true}
          maskChar={null}
        />
        <InputMasked
          label="Telefone secundário"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={secondPhone}
          onChange={setSecondPhone}
          required={true}
          maskChar={null}
        />
      </div>
      <div className="flex end">
        <Button
          text="Salvar"
          paddingHorizontal="30px"
          className="defaultMarginTop"
          isLoading={loading}
          onClick={saveTeamMember}
        />
      </div>
    </>
  );
}

export default NewTeamMember;
