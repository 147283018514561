import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Input from "../../components/Input";
import logo from "../../png/logo.png";
import { toast } from "react-toastify";
import Close from "../../svg/close";
import { recoverEmail } from "../../request/index";

const RecoverPasswordPage = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");

  const sendEmail = (email) => {
    if (email) {
      recoverEmail(email)
        .then(() => {
          toast.success("E-mail enviado");
        })
        .catch((error) => {
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Falha ao enviar email");
        });
    } else {
      toast.warning("O campo E-mail é obrigatório");
    }
  };

  return (
    <div className="backgroundGradient resgatarSenhaScreen">
      <Header></Header>
      <main className="flex center">
        <div className="flex center column card">
          <div className="flex fullWidth end smallMarginBottom">
            <Close
              onClick={() => {
                history.goBack();
              }}
              className="flex pointer startSelf"
            />
          </div>
          <figure className="smallMarginBottom ">
            <img className="logo" src={logo} alt="" />
          </figure>
          <hr className="smallMarginTop defaultMarginBottom" />
          <div className="defaultMarginVertical textCenter successMessage">
            <p>Entre com seu e-mail para resgatar a senha.</p>
            <p>Um link com as instruções será enviado.</p>
          </div>
          <Input
            type="text"
            placeholder="E-mail"
            property="email"
            onChange={setEmail}
          />
          <Button
            text="Resgatar senha"
            className="fullWidth defaultMarginTop"
            onClick={() => sendEmail(email)}
          />
        </div>
      </main>
    </div>
  );
};

export default RecoverPasswordPage;
