import React, { useState, useEffect } from "react";
import {
  getMemberProfile,
  putMemberProfile,
  getCompanyPositions,
  postImage,
} from "../../request";
import ProgressBar from "../../components/ProgressBar";
import Base from "../../components/Base";
import Title from "../../components/Title";
import Input from "../../components/InputHook";
import InputMaskedHook from "../../components/InputMaskedHook";
import Button from "../../components/Button";
import FileInput from "../../components/FileInput";
import Edit from "../../svg/edit";
import Modal from "../../components/Modal";
import ChangePassword from "../../components/Modal/ChangePassword";
import { toast } from "react-toastify";

const DataUserPage = () => {
  const [fields, setFields] = useState({
    position: "",
    email: "",
    phone: "",
    secondPhone: "",
    urlImage: "",
  });

  const [userImage, setUserImage] = useState("");
  const [isDisabledImage, setIsDisabledImage] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [changePassword, setChangePasswordModal] = useState(null);

  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      let companyTeams = await getCompanyPositions();

      getMemberProfile()
        .then(async ({ data: { active, created, updated, ...data } }) => {
          // Nome do usuário
          data.name = data.name || data.companyName || "Nome não informado";
          // Cargo na empresa / role / team
          const selectedPosition =
            companyTeams.data.find((el) => el.name === data.position) ||
            data.position === "admin"
              ? { label: "Admin" }
              : "";
          data.position = selectedPosition.label;
          // Verifica image do perfil do usuário logado
          const photoURL = data.photoURL;
          const imageURL =
            photoURL && photoURL !== ""
              ? photoURL
              : require(`../../png/user-profile.png`);
          setUserImage(imageURL);
          setFields({ ...fields, ...data });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error(
            "Não foi possível carregar as informações do perfil do usuário"
          );
          setIsLoading(false);
        });
    })();
  }, []);

  const updateUser = () => {
    setIsLoadingSpinner(true);

    const revalidator = require("revalidator");
    let isValid = revalidator.validate(fields, {
      properties: {
        name: {
          message: "O Nome é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        phone: {
          message: "O Telefone principal é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setIsLoadingSpinner(false);
      return;
    }
    delete fields.position;
    putMemberProfile(fields)
      .then(() => {
        setIsDisabled(true);
        setIsLoadingSpinner(false);
      })
      .then(() => setIsLoadingSpinner(false))
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error(
          "Não foi possível carregar as informações do perfil do usuário"
        );
        setIsLoadingSpinner(false);
      });
  };

  const putImage = async (file) => {
    postImage(file)
      .then(async (resp) => {
        const url = resp.data.data.url;
        fields.photoURL = url || require(`../../png/user-profile.png`);
        putMemberProfile({ photoURL: url }).then(async () => {
          toast.success("Imagem atualizada com sucesso");
          setUserImage(url || require(`../../png/user-profile.png`));
          localStorage.setItem("userImageUrl", url);
          setFields({ ...fields });
          setIsDisabledImage(true);
          return;
        });
      })
      .catch((error) => {
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível salvar a imagem");
        return undefined;
      });
  };

  // Event listener for if upload photo button is clicked
  const uploadFile = (e) => {
    if (e.length > 0) {
      let file = e[0];
      // Upload file
      putImage(file)
        .then(() => {
          //Remove Position do update
          delete fields.position;
          putMemberProfile(fields)
            .then(() => {
              setIsDisabled(true);
              setIsLoadingSpinner(false);
            })
            .then(() => {
              toast.success("Imagem do perfil atualizada com sucesso");
              setIsLoadingSpinner(false);
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível atualizar o perfil do seu Time");
              setIsLoadingSpinner(false);
              // TODO Implementar tratamento de erro. mostrar alguma mensagem de erro para o usuário, erro com token expirado!
            });
        })
        .catch((error) => {
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível atualizar sua imagem");
        });
    } else {
      toast.warning("Não foi possível atualizar sua imagem");
    }
  };

  return (
    <>
      {
        <ProgressBar
          className="loading"
          loading={isLoading}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }

      <div className="fullWidth flex between titleContent">
        <Title text="Dados do usuário" />
      </div>
      <div className="fullWidth flex wrapReverse hCenter">
        <div className="card border flex column hCenter flexGrow3 dataUserContent startSelf">
          <div className="flex between defaultMarginBottom vCenter">
            {isDisabled ? (
              <h3>{fields.name ? fields.name : ""}</h3>
            ) : (
              <Input
                placeholder={fields.name ? fields.name : "Nome"}
                disabled={isDisabled}
                isDefault={true}
                value={fields.name}
                onChange={(value) => handleFields(value, "name")}
                withoutMargin={true}
              />
            )}
            {isDisabled ? (
              <Edit
                className="pointer"
                onClick={() => {
                  setIsDisabled(!isDisabled);
                }}
              />
            ) : (
              <Button
                isLoading={isLoadingSpinner}
                className="smallMarginTop"
                text="Salvar"
                alignSelf="end"
                paddingHorizontal="30px"
                onClick={() => {
                  updateUser();
                  setIsDisabled(false);
                }}
              />
            )}
          </div>

          <div className="fullWidth flex center wrap defaultMarginTop contentInputs">
            {/* Não é possível alterar o cargo por esta tela, ao menos por enquanto */}
            <Input
              label="Cargo na empresa"
              disabled={true}
              isDefault={true}
              value={fields.position}
              onChange={(value) => handleFields(value, "position")}
            />
            <Input
              label="Email"
              type="email"
              disabled={true}
              value={fields.email}
              isDefault={true}
              onChange={(value) => handleFields(value, "email")}
            />

            <InputMaskedHook
              label="Telefone principal"
              type="tel"
              disabled={isDisabled}
              value={fields.phone || ""}
              isDefault={true}
              mask="(99) 99999-9999"
              onChange={(value) => handleFields(value, "phone")}
              maskChar={null}
            />
            <InputMaskedHook
              label="Telefone secundário"
              type="tel"
              disabled={isDisabled}
              value={fields.secondPhone || ""}
              isDefault={true}
              mask="(99) 99999-9999"
              onChange={(value) => handleFields(value, "secondPhone")}
              maskChar={null}
            />

            <Button
              text="Mudar senha"
              marginTop="40px"
              paddingHorizontal="30px"
              onClick={() => {
                setChangePasswordModal(true);
              }}
            />
          </div>

          <Modal
            className="modal-sm loginScreenChangePass"
            active={changePassword}
            closeModal={() => {
              setChangePasswordModal(false);
            }}
            content={
              <ChangePassword
                status={changePassword}
                closeModal={() => {
                  setChangePasswordModal(false);
                }}
              />
            }
          />
        </div>

        <div className="card border flex column  flexGrow3 dataUserImageContent">
          <div className="flex between defaultMarginBottom vCenter">
            {isDisabledImage ? (
              <>
                <h3>Imagem do perfil</h3>
              </>
            ) : null}
            {isDisabledImage ? (
              <Edit
                className="pointer"
                onClick={() => {
                  setIsDisabledImage(!isDisabledImage);
                }}
              />
            ) : null}
          </div>
          <img
            src={userImage || require(`../../png/user-profile.png`)}
            className="userImage"
          />
          {isDisabledImage ? null : (
            <div className="fullWidth flex center wrap defaultMarginTop contentInputs">
              <FileInput value="" onChange={(e) => uploadFile(e)} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Base(DataUserPage);
