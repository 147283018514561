import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Title from "../../components/Title";
import Base from "../../components/Base";
import ProgressBar from "../../components/ProgressBar";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Stage from "../../components/Stage";
import Confirm from "../../components/Modal/Confirm";
import NewBusiness from "../../components/Modal/NewBusiness";
import Lose from "../../components/Modal/Lose";
import Won from "../../components/Modal/Won";
import {
  getAllLeadsByPipelineID,
  getAllStagesByPipelineID,
  getAllPipelineByCompanyID,
  putLead,
  putResultLead,
  getInformationsByType,
  listCustomerCompanies,
  listCustomerContacts,
  listMembersByTeamPosition,
  postEvent,
  refreshToken,
  listMyActivities,
} from "../../request";
import {
  STAGE_SELLER,
  STAGE_PRE_SELLER,
  STAGE_LOST,
  STAGE_WON,
  ROLE_MANAGER,
} from "../../utils";
import { toast } from "react-toastify";
import useRefreshToken from "../../hooks/useRefreshToken";
import { toDate } from "date-fns";
import { revalidateToken } from "../../../src/utils/index";
import BusinessDetailPage from "../BusinessDetailPage";
import LostLeadsDateLimitMenu from "../../components/LostLeadsDateLimitMenu";

const BusinessPage = () => {
  useRefreshToken();
  const history = useHistory();
  const dispatch = useDispatch();

  const [newBusinessModal, setNewBusinessModal] = useState(null);
  const [loseModal, setLoseModal] = useState(null);
  const [callbackLoseModal, setCallbackLoseModal] = useState(() => () => {});
  const [wonModal, setWonModal] = useState(null);
  const [callbackWonModal, setCallbackWonModal] = useState(() => () => {});
  // const [pipeline, setPipeline] = useState([]);
  const [stages, setStages] = useState([]);
  const [pipelineID, setPipelineID] = useState("");
  const [leadID, setLeadID] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingSelect, setIsLoadingSelect] = useState(null);
  const [pipelineDefault, setPipelineDefault] = useState(
    window.localStorage.getItem("pipeline_default")
  );

  // Dropdown Options
  const [pipelinesOptions, setPipelinesOptions] = useState([]);
  const [reasonOption, setReasonOption] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [customerCompanyID, setCustomerCompanyID] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [contactsOptions, setContactsOptions] = useState([]);
  const [customerContactID, setCustomerContactID] = useState("");
  const [customerContactName, setCustomerContactName] = useState("");
  const [segment, setSegment] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const [phoneMain, setPhoneMain] = useState("");
  const [socialNetwork, setSocialNetwork] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneMain, setContactPhoneMain] = useState("");
  const [contactPhoneSecondary, setContactPhoneSecondary] = useState("");
  const [contactWhatsApp, setContactWhatsApp] = useState("");
  const [contactPosition, setContactPosition] = useState("");
  const [currentFilter, setCurrentFilter] = useState(null);
  const [businessPageModal, setBusinessPageModal] = useState({
    status: null,
    id: "",
  });
  const [detailLead, setDetailLead] = useState(null);
  const [closeBusinessPageModal, setCloseBusinessPageModal] = useState(false);
  const [lostLeadsLimitDate, setLostLeadsLimitDate] = useState(0);
  const [loadedLeads, setLoadedLeads] = useState([]);
  const [activity, setActivity] = useState();
  const [activitiesOverdue, setActivitiesOverdue] = useState([]);
  const [activitiesToday, setActivitiesToday] = useState([]);
  const [activitiesUpcoming, setActivitiesUpcoming] = useState([]);

  useEffect(() => {
    setDetailLead(BusinessDetailModalFunction(businessPageModal));
  }, [businessPageModal]);

  const BusinessDetailModalFunction = ({ status, id }) => {
    return (
      <Modal
        active={status}
        content={status ? <BusinessDetailPage id={id} /> : null}
        closeModal={() => {
          setBusinessPageModal({
            status: false,
            id: "",
          });
          handleGetAllLeads();
        }}
      />
    );
  };

  const handleBusinessPageModal = (modalStatus, id) => {
    setBusinessPageModal({
      status: modalStatus,
      id: id,
    });
  };

  const listCompanies = () => {
    listCustomerCompanies()
      .then(({ data }) => {
        if (data.length) {
          //console.log(data);
          setCompaniesOptions(data);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as empresas");
      });
  };

  const listContacts = () => {
    listCustomerContacts()
      .then(({ data }) => {
        if (data.length) {
          setContactsOptions(data);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as empresas");
      });
  };

  const openNewBusinessModal = () => {
    if (pipelineID) {
      dispatch({ type: "SET_CURRENT_STAGES", value: "" });
      setNewBusinessModal(true);
    } else {
      toast.warning("Selecione um funil para poder criar um novo negócio");
    }
  };

  const onDragStart = (e, id) => {
    e.dataTransfer.setData("leadID", id);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const [modalConfirm, setModalConfirm] = useState({
    status: false,
    message: "",
    hasCancel: true,
    hasConfirm: true,
    onConfirm: () => {},
  });

  const handleModalConfirm = (value, property, value2, property2) => {
    if (value instanceof Object) {
      setModalConfirm({
        ...modalConfirm,
        ...value,
        ...value2,
      });
    } else {
      setModalConfirm({
        ...modalConfirm,
        [property]: value,
        [property2]: value2,
      });
    }
  };

  const handleSetPipelineDefault = (id) => {
    window.localStorage.setItem("pipeline_default", id);
    setPipelineDefault(id);
    toast.success("Funil definido como padrão");
  };

  const moveCard = (stages, leadID, newStageID, newCard) => {
    stages = stages
      .map((item) => {
        item.leads = item.leads
          .sort((a, b) => b.updated - a.updated)
          .filter((item) => item.id !== leadID);
        return item;
      })
      .map((item) => {
        if (item.id === newStageID) item.leads.push(newCard);
        item.leads.sort((a, b) => b.updated - a.updated);
        return item;
      });
    setStages(stages);
  };

  const onDrop = (e, newStageID) => {
    e.preventDefault();
    let aux = JSON.parse(JSON.stringify(stages));
    let leadID = e.dataTransfer.getData("leadID");
    let newCard = aux
      .map((item) => item.leads)
      .flat()
      .find((item) => item.id === leadID);
    let isLose = aux.some(
      (item) => item.id === newStageID && item.step === "lost"
    );

    if (!isLose) {
      let isWon = aux.some(
        (item) => item.id === newStageID && item.step === "won"
      );
      let put = !isWon ? putLead : putResultLead;
      let event = {
        type: "lead",
        description: !isWon ? `Lead movido de estágio` : `Lead foi ganha`,
        status: !isWon ? "pending" : "won",
        leadID: newCard.id,
        newStageID: newStageID,
      };
      let rest = !isWon
        ? {
            stageID: newStageID,
            "result.status": "pending",
            "result.observation": "",
            "result.reason": "",
          }
        : { status: "won", observation: "" };
      if (isWon) {
        setWonModal(true);
        setLeadID(e.dataTransfer.getData("leadID"));
        setCallbackWonModal(
          () => () => moveCard(aux, leadID, newStageID, newCard)
        );
      } else {
        moveCard(aux, leadID, newStageID, newCard);
        revalidateToken().then(() => {
          put({ id: newCard.id, ...rest }, pipelineID)
            .then(() => {
              toast.success("Etapa do negócio atualizada com sucesso");
              handleGetAllLeads();
            })
            .catch((error) => {
              // console.log("Passou catch atualiziar drop businessPage");
              console.error({ error });
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível atualizar a etapa do negócio!");
            });
        });
      }
      postEvent(event);
    } else {
      getInformationsByType("REASONS_LOSED").then((resp) => {
        const data = resp.data[0].REASONS_LOSED.data;
        setReasonOption(data);
        setLoseModal(true);
      });
      setLeadID(e.dataTransfer.getData("leadID"));
      setCallbackLoseModal(
        () => () => moveCard(aux, leadID, newStageID, newCard)
      );
    }
  };

  useEffect(() => {
    // Busca os Pipelines (Funis)

    listMembersByTeamPosition(STAGE_PRE_SELLER, STAGE_SELLER, ROLE_MANAGER)
      .then(({ data }) => {
        setIsLoadingSelect(true);
        setFilterOptions([
          { id: "", label: "Todos" },
          { id: "status=pending", label: "Em andamento" },
          { id: "status=won", label: "Ganhos" },
          { id: "status=lost", label: "Perdidos" },
        ]);
        data.forEach((member) => {
          setFilterOptions((filterOptions) => [
            ...filterOptions,
            {
              id: `memberProfileID=${member.id}`,
              label: `Responsável: ${member.name}`,
            },
          ]);
        });
        listCustomerCompanies()
          .then(({ data }) => {
            let cities = [];
            data.forEach((company) => {
              if (company.city) {
                cities.push(company.city);
              }
            });
            cities = [...new Set(cities)];
            //console.log(cities);
            cities.forEach((city) => {
              let comps = [];
              data.forEach((company) => {
                if (company.city === city) {
                  comps.push(company.id);
                  //console.log(comps);
                }
              });
              setFilterOptions((pastOptions) => [
                ...pastOptions,
                {
                  id: `customerCompanyID=${comps}`,
                  label: `Cidade: ${city}`,
                },
              ]);
            });
          })
          .catch((error) => {
            if (error.response?.data === "auth/id-token-expired") {
              toast.error(
                "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
              );
              return;
            }
            console.error("Erro: ", error.message, " Status: ", error.code);
            toast.error("Não foi possível carregar as empresas");
            //console.log(error);
          });
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível obter a lista de responsáveis");
      });

    getAllPipelineByCompanyID()
      .then(({ data }) => {
        setPipelinesOptions(data);
        //Seta sempre o primeiro funil
        if (
          data.length > 0 &&
          window.localStorage.getItem("pipeline_default")
        ) {
          setPipelineID(window.localStorage.getItem("pipeline_default"));
        } else {
          setPipelineID(data[0].id || "");
        }
      })
      .catch((error) => {
        // console.log("Passou catch funis drop businessPage");
        console.error(error || "Erro desconhecido");
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar os funis da empresa");
      })
      .finally(() => setIsLoadingSelect(false));
  }, []);

  const handleGetAllLeads = () => {
    listLeads(currentFilter);
  };

  const filterLostLeads = (lostLeads) => {
    let leads = lostLeads || [];
    let limitDate = new Date();
    limitDate.setDate(limitDate.getDate() - lostLeadsLimitDate);
    return lostLeadsLimitDate
      ? leads.filter(
          (lead) =>
            !(
              lead.result.status === "lost" &&
              new Date(lead?.updated) < limitDate
            )
        )
      : leads;
  };

  const listLeads = (filter) => {
    revalidateToken().then(() => {
      setCurrentFilter(filter);
      setIsLoadingSelect(true);
      getAllLeadsByPipelineID(pipelineID, filter)
        .then(({ data, ...rest }) => {
          const leads = filterLostLeads(data);
          setLoadedLeads(data);
          // setStages((stages) =>
          //   stages.map((stage) => {
          //     stage.leads = leads
          //       .sort((a, b) => {
          //         return new Date(b?.updated) - new Date(a?.updated);
          //       })
          //       .filter((item) => {
          //         return item.stageID === stage.id;
          //       });
          //     return stage;
          //   })
          // );
          listCompanies();
          listContacts();
        })
        .catch((error) => {
          // console.log("Passou catch negocios businessPage");
          console.error({ error });
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          toast.error("Não foi possível carregar os negócios do funil");
        })
        .finally(() => {
          setIsLoading(false);
          setIsLoadingSelect(false);
        });
    });
  };

  useEffect(() => {
    if (pipelineID) {
      setIsLoadingSelect(true);
      revalidateToken().then(() => {
        getAllStagesByPipelineID(pipelineID)
          .then(({ data }) => {
            setStages(data.sort((a, b) => a.order - b.order));
            handleGetAllLeads();
          })
          .catch((error) => {
            // console.log("Passou catch etapas businessPage");
            console.error({ error });
            if (error.response?.data === "auth/id-token-expired") {
              toast.error(
                "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
              );
              return;
            }
            toast.error("Não foi possível carregar as etapas do funil");
          });
      });
      //.finally(()=> setIsLoadingSelect(false))
    }
  }, [pipelineID]);

  useEffect(() => {
    if (
      modalConfirm.status === false &&
      pipelineID &&
      modalConfirm.message === ""
    ) {
      revalidateToken().then(() => {
        getAllStagesByPipelineID(pipelineID)
          .then(({ data }) => {
            setStages(data.sort((a, b) => a.order - b.order));
            handleGetAllLeads();
          })
          .catch((error) => {
            if (error.response?.data === "auth/id-token-expired") {
              toast.error(
                "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
              );
              return;
            }
            toast.error("Não foi possível carregar as etapas do funil");
          });
      });
    }
  }, [modalConfirm]);

  useEffect(() => {
    if (newBusinessModal === false) handleGetAllLeads();
  }, [newBusinessModal]);

  // useEffect(() => {
  //   try {
  //     if (businessPageModal === false) handleGetAllLeads();
  //   } catch (error) {
  //     if (error.response?.data === "auth/id-token-expired") {
  //       toast.error(
  //         "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
  //       );
  //       return;
  //     }
  //     toast.error("Não foi possível carregar as etapas do funil");
  //   }
  // }, [businessPageModal]);

  useEffect(() => {
    console.log(lostLeadsLimitDate);
    listMyActivities().then(({ data }) => {
      const sortOverdue = [...data.overdue].sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );
      setActivitiesOverdue(sortOverdue);
      const sortToday = [...data.today].sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );
      setActivitiesToday(sortToday);
      // const sortUpcoming = [...data.upcoming].sort(
      //   (a, b) => new Date(a.startTime) - new Date(b.startTime)
      // );
      // setActivitiesUpcoming(sortUpcoming);

      if (loadedLeads && loadedLeads.length) {
        const leads = filterLostLeads(loadedLeads).map((lead) => {
          // if (sortUpcoming.some((item) => item.leadID === lead.id)) {
          //   lead.activityStatus = "upcoming";
          // } else
          if (sortToday.some((item) => item.leadID === lead.id)) {
            lead.activityStatus = "today";
          } else if (sortOverdue.some((item) => item.leadID === lead.id)) {
            lead.activityStatus = "overdue";
          } else {
            lead.activityStatus = null;
          }
          //console.log(lead.activityStatus);
          return lead;
        });

        setStages((stages) =>
          stages.map((stage) => {
            stage.leads = leads
              .sort((a, b) => {
                return new Date(b?.updated) - new Date(a?.updated);
              })
              .filter((item) => {
                return item.stageID === stage.id;
              });
            return stage;
          })
        );
      }
    });
  }, [lostLeadsLimitDate, loadedLeads]);

  return (
    <>
      <Modal
        disableBackgroundClosing={true}
        className="newBusinessModal modal-sm"
        active={newBusinessModal}
        closeModal={() => {
          setNewBusinessModal(false);
        }}
        content={
          pipelineID && (
            <NewBusiness
              customerCompanyID={customerCompanyID}
              setCustomerCompanyID={setCustomerCompanyID}
              customerCompanyName={customerCompanyName}
              setCustomerCompanyName={setCustomerCompanyName}
              customerContactID={customerContactID}
              setCustomerContactID={setCustomerContactID}
              customerContactName={customerContactName}
              setCustomerContactName={setCustomerContactName}
              segment={segment}
              setSegment={setSegment}
              site={site}
              setSite={setSite}
              email={email}
              setEmail={setEmail}
              phoneMain={phoneMain}
              setPhoneMain={setPhoneMain}
              socialNetwork={socialNetwork}
              setSocialNetwork={setSocialNetwork}
              contactEmail={contactEmail}
              setContactEmail={setContactEmail}
              contactPhoneMain={contactPhoneMain}
              setContactPhoneMain={setContactPhoneMain}
              contactPhoneSecondary={contactPhoneSecondary}
              setContactPhoneSecondary={setContactPhoneSecondary}
              contactPosition={contactPosition}
              setContactPosition={setContactPosition}
              contactWhatsApp={contactWhatsApp}
              setContactWhatsApp={setContactWhatsApp}
              pipelineID={pipelineID}
              closeModal={() => {
                setNewBusinessModal(false);
              }}
              status={newBusinessModal}
              isBusinessPage={true}
              companiesOptions={companiesOptions}
              contactsOptions={contactsOptions}
            />
          )
        }
      />
      <Modal
        className="modal-sm"
        active={loseModal}
        closeModal={() => setLoseModal(false)}
        content={
          <Lose
            closeModal={() => setLoseModal(false)}
            leadID={leadID}
            status={loseModal}
            option={reasonOption}
            refetch={() => {
              revalidateToken().then(() => {
                getAllStagesByPipelineID(pipelineID);
              });
              callbackLoseModal();
            }}
            pipelineID={pipelineID}
          />
        }
      />
      <Modal
        className="modal-sm"
        active={wonModal}
        closeModal={() => setWonModal(false)}
        content={
          <Won
            closeModal={() => setWonModal(false)}
            leadID={leadID}
            status={wonModal}
            refetch={() => {
              revalidateToken().then(() => {
                getAllStagesByPipelineID(pipelineID);
              });
              callbackWonModal();
            }}
            pipelineID={pipelineID}
          />
        }
      />

      {
        <ProgressBar
          className="loading"
          loading={isLoading}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }
      {
        <ProgressBar
          className="loading"
          loading={isLoadingSelect}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }
      <div className="fullWidth flex between titleContent">
        <Title text="Negócios" />
      </div>
      <div className="flex between fullWidth">
        <Select
          placeholder="Funil..."
          style={{ maxWidth: "250px", marginRight: "2rem" }}
          property="filter"
          options={pipelinesOptions}
          valueOption="description"
          value={pipelineID}
          setIsLoadingSelect={setIsLoadingSelect}
          onChange={(value) => {
            setPipelineID(value);
          }}
        />

        {pipelineDefault !== pipelineID && (
          <Button
            text={"Usar como funil principal"}
            width={"14rem"}
            onClick={() => handleSetPipelineDefault(pipelineID)}
          />
        )}

        <div className="flex flexGrow end">
          <Select
            placeholder="Filtrar por"
            className="defaultMarginRight"
            style={{ maxWidth: "150px" }}
            property="filter"
            options={filterOptions}
            //value={currentFilter}
            onChange={listLeads}
          />

          <Button
            text="Novo negócio"
            className="verySmallMarginTop"
            paddingHorizontal="20px"
            onClick={() => {
              openNewBusinessModal();
            }}
          />
          <LostLeadsDateLimitMenu
            lostLeadsLimitDate={lostLeadsLimitDate}
            setLostLeadsLimitDate={setLostLeadsLimitDate}
          />
        </div>
      </div>
      <Modal
        className="modal-xs modalConfirm"
        active={modalConfirm.status}
        closeModal={() => {
          handleModalConfirm(false, "status");
        }}
        content={
          <Confirm
            onCancel={() => handleModalConfirm(false, "status")}
            closeModal={() => {
              handleModalConfirm(false, "status", "", "message");
            }}
            {...modalConfirm}
          />
        }
      />

      <div
        className="fullWidth flex nowrap auto flexGrow"
        style={{ maxHeight: "70vh", height: "100%" }}
      >
        {stages.map((item, index) => {
          let type = ""; // STAGE_SELLER
          if (item.step === STAGE_PRE_SELLER) {
            type = "active light";
          } else if (item.step === STAGE_WON) {
            type = "success";
          } else if (item.step === STAGE_LOST) {
            type = "fail";
          }
          return (
            <Stage
              modalConfirm={modalConfirm}
              handleModalConfirm={handleModalConfirm}
              handleBusinessPageModal={handleBusinessPageModal}
              key={item.id}
              type={type}
              id={item.id}
              title={item.description}
              leads={item.leads}
              onAdd={() => {
                dispatch({ type: "SET_CURRENT_STAGES", value: item });
                setNewBusinessModal(true);
              }}
              onDragOver={onDragOver}
              onDragStart={onDragStart}
              onDrop={onDrop}
            />
          );
        })}
      </div>
      <>{detailLead}</>
    </>
  );
};

export default Base(BusinessPage);
