import React from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function Sidebar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.changemenu.items);

  const setItemMenu = (index) => {
    dispatch({
      type: "SET_ITEM_MENU",
      index,
    });
  };

  return (
    <>
      <ReactTooltip style={{ display: "none" }} />
      <aside className="flex hCenter flexShrinkNone">
        <ul className="flex column sideBar">
          {items.map((item, index) => {
            return (
              <li
                key={`sideBar${index}`}
                data-tip={item.title}
                onClick={() => {
                  setItemMenu(index);
                }}
              >
                <Link to={item.href}>
                  <div
                    className={clsx(
                      "contentIcon",
                      history.location.pathname.indexOf(item.href) !== -1 &&
                        "active"
                    )}
                  >
                    {item.icon}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
