import React from 'react'
import Phone from '../../svg/phone';
import Mail from '../../svg/mail';
import AccountMultiple from '../../svg/accountmultiple';
import Whatsapp from '../../svg/whatsapp';
import Cam from '../../svg/cam';
import Common from './Common';
import Full from './Full';

function Chip({ type, ...rest }) {

    let getInfo = (type) => {
        let obj = {
            'phone': { icon: <Phone height="13" width="13" fill="#fff" />, text: "Telefonar" },
            'email': { icon: <Mail height="14" width="14" fill="#fff" style={{ marginTop: 2 }} />, text: "Email" },
            'in-person': { icon: <AccountMultiple width="15" height="15" fill="#fff" />, text: "Reunião" },
            'whatsapp': { icon: <Whatsapp width="13" height="13" fill="#fff" />, text: "WhatsApp" },
            'video': { icon: <Cam width="13" height="13" fill="#fff" style={{ marginTop: 2 }} />, text: "Chamada" }
        }
        if (type) {
            return obj[type];
        } else {
            return { icon: null, text: null }
        }
    }

    const { icon, text } = getInfo(type);

    return (
        <>
            {(icon || text) ? <Full icon={icon} text={text} {...rest} /> : <Common {...rest} />}
        </>
    )
}

export default Chip;