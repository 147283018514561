import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { register, getInformationsByType } from "../../request";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputMasked from "../../components/InputMaskedHook";
import Select from "../../components/Select";
import logo from "../../png/logo.png";
import { toast } from "react-toastify";
import Close from "../../svg/close";

const CreateAccountPage = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [pass, setPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [segment, setSegment] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [goal, setGoal] = useState("");

  // Options for dropdowns
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [teamSizesOptions, setTeamSizesOptions] = useState([]);
  const [goalsOptions, setGoalsOptions] = useState([]);

  useEffect(() => {
    getInformationsByType("GENERIC_SEGMENTS", "TEAM_SIZES", "GOALS")
      .then(({ data }) => {
        data.forEach((info) => {
          if (info.key === "GENERIC_SEGMENTS") setSegmentsOptions(info.data);
          else if (info.key === "TEAM_SIZES") setTeamSizesOptions(info.data);
          else if (info.key === "GOALS") setGoalsOptions(info.data);
        });
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error(
          "Não foi possível carregar algumas informações do cadastro"
        );
      });
  }, []);

  const createAcc = async (data) => {
    let blankValidation =
      data.name &&
      data.email &&
      data.phone &&
      data.cnpj &&
      data.pass &&
      data.repeatPass &&
      data.segment &&
      data.teamSize &&
      data.goal;
    const iqualPass = data.pass === data.repeatPass;

    if (blankValidation) {
      if (iqualPass) {
        const obj = {
          name: data.name,
          email: data.email,
          cnpj: data.cnpj,
          phone: data.phone,
          segment: data.segment,
          teamSize: data.teamSize,
          goal: data.goal,
          pass: data.pass,
        };
        await register(obj)
          .then((res) => {
            if (res.status === 201) {
              toast.success(`Cadastro Realizado!              
            Aguarde o contato de um de nossos representantes para liberar sua conta.`);
            }
            history.push("/");
          })
          .catch((error) => {
            console.error("Erro: ", error.message, " Status: ", error.code);
            toast.error("Não foi possível criar Conta");
          });
      } else {
        toast.warning("As senhas devem ser iguais");
      }
    } else {
      toast.warning("Todos os campos são obrigatórios");
    }
  };

  return (
    <div className="backgroundGradient criarContaScreen">
      <Header></Header>
      <main className="flex center">
        <div className="flex center column card">
          <div className="flex fullWidth end smallMarginBottom">
            <Close
              onClick={() => {
                history.goBack();
              }}
              className="pointer"
            />
          </div>
          <div className="flex between vCenter fullWidth">
            <h2>Crie sua conta</h2>
            <figure className="smallMarginBottom ">
              <img className="logo smallMarginRight" alt="logo" src={logo} />
            </figure>
          </div>
          <hr className="defaultMarginTop" />
          <form className="defaultMarginTop flex vCenter wrap">
            <div className="flex column dadosContent">
              <Input
                className="criarContaScreenInput"
                type="text"
                placeholder="Nome completo"
                value={name}
                onChange={setName}
                required={true}
              />
              <Input
                className="criarContaScreenInput"
                type="text"
                placeholder="E-mail"
                value={email}
                onChange={setEmail}
                required={true}
              />
              <InputMasked
                className="criarContaScreenInput"
                type="text"
                placeholder="Telefone"
                mask="(99) 99999-9999"
                value={phone}
                onChange={setPhone}
                required={true}
                maskChar={null}
              />
              <InputMasked
                className="criarContaScreenInput"
                type="text"
                placeholder="CNPJ"
                mask="99.999.999/9999-99"
                value={cnpj}
                onChange={setCnpj}
                required={true}
                maskChar={null}
              />
              <Input
                className="criarContaScreenInput"
                type="password"
                placeholder="Criar senha"
                onChange={setPass}
                value={pass}
                required={true}
              />
              <Input
                className="criarContaScreenInput"
                type="password"
                placeholder="Repetir senha"
                onChange={setRepeatPass}
                value={repeatPass}
              />
            </div>
            <hr className="verticalHr limited defaultMarginHorizontal" />
            <div className="flex column defaultPaddingTop startSelf">
              <h3>Fale mais sobre seu negócio</h3>
              <Select
                placeholder="Qual seu segmento de mercado?"
                value={segment}
                onChange={setSegment}
                options={segmentsOptions}
                className="defaultMarginTop criarContaScreenSelect"
              />
              <Select
                placeholder="Qual o tamanho da sua equipe?"
                value={teamSize}
                onChange={setTeamSize}
                options={teamSizesOptions}
                className="defaultMarginTop criarContaScreenSelect"
              />
              <Select
                placeholder="Qual seu objetivo com a Vendee?"
                value={goal}
                onChange={setGoal}
                options={goalsOptions}
                className="defaultMarginTop criarContaScreenSelect"
              />
            </div>
          </form>
          <p></p>
          <Button
            text="Criar conta"
            onClick={() => {
              const info = {
                name,
                email,
                phone,
                cnpj,
                pass,
                repeatPass,
                segment,
                teamSize,
                goal,
              };
              createAcc(info);
            }}
            paddingHorizontal="40px"
            className="defaultMarginTop"
          ></Button>
        </div>
      </main>
    </div>
  );
};

export default CreateAccountPage;
