import React from 'react';

function Note({ width = "22", height = "22", ...props }) {
    return (
        <svg data-tip="Nota" xmlns="http://www.w3.org/2000/svg" {...props} width={width} height={height} viewBox={"0 -1 2 22"}>
         <path d="M.425,0a8.925,8.925,0,0,0,0,17.85H.95V21c5.1-2.457,8.4-7.35,8.4-12.075A8.932,8.932,0,0,0,.425,0" />
        </svg>
    );
}

export default Note;
