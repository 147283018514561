import React from "react";
import Calendar from "../svg/calendar";
import AccountMultiple from "../svg/accountmultiple";
import Domain from "../svg/domain";
import Suitcase from "../svg/suitcase";
import Gear from "../svg/gear";
import DashboardIcon from "../svg/dashboard";

const changeMenu = (
  state = {
    items: [
      {
        href: "/dashboard",
        title: "Dashboard",
        icon: <DashboardIcon />,
        active: false,
      },
      {
        href: "/atividades",
        title: "Atividades",
        icon: <Calendar />,
        active: false,
      },
      {
        href: "/contatos",
        title: "Contatos",
        icon: <AccountMultiple />,
        active: false,
      },
      { href: "/empresas", title: "Empresas", icon: <Domain />, active: false },
      {
        href: "/negocios",
        title: "Negócios",
        icon: <Suitcase />,
        active: false,
      },
      {
        href: "/configuracoes",
        title: "Configurações",
        icon: <Gear />,
        active: false,
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case "SET_ITEM_MENU":
      var newArray = [];
      newArray = state.items.map((item, indexMap) => {
        if (action.index === indexMap) {
          return item;
        } else {
          item.active = false;
          return item;
        }
      });
      newArray[action.index].active = !newArray[action.index].active;
      return { items: newArray };

    default:
      return state;
  }
};

export default changeMenu;
