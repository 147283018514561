import React from "react";
import clsx from "clsx";
import ArrowBottom from "../../svg/arrowbottom";
import CheckRounded from "../../svg/checkrounded";
import Spinner from "../../svg/spinner";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    this.setState({ response: "Nenhum resultado retornado." });
  }

  render() {
    return (
      <div className="flex column tableRegisteredUsers fullWidth table">
        <div className="flex thead tr">
          {this.props.thead.map((item) => {
            var className = clsx("title td flexGrow", item.className);
            return (
              <div
                key={item.name}
                className={className}
                onClick={() => {
                  if (item.sort) item.sort();
                }}
              >
                <span>{item.name}</span>
                {item.sort && <ArrowBottom />}
              </div>
            );
          })}
        </div>
        {this.props?.tbody?.length ? (
          this.props.tbody.map((item, indexTr) => {
            let id = item.id;
            return (
              <div
                key={`tr${indexTr}`}
                className={
                  this.props.handleEdit ? "flex tr pointer" : "flex tr"
                }
                onClick={
                  this.props.handleEdit ? () => this.props.handleEdit(id) : null
                }
              >
                {Object.entries(item.fields).map(([key, item], indexTd) => {
                  var className = clsx(
                    "td flexGrow flex vCenter",
                    item.className
                  );

                  return (
                    <div
                      key={`td${item.name + item.value + indexTr + indexTd}`}
                      className={className}
                    >
                      {item.value}
                      {item.isThereCheck && (
                        <CheckRounded
                          active={item.isActived}
                          onClick={() => {
                            this.props.loading(true);
                            item
                              .action()
                              .then(() => {
                                this.props.refetch();
                              })
                              .finally(() => {
                                this.props.loading(false);
                                setTimeout(() => {
                                  this.props.loading(null);
                                }, 4000);
                              });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : this.props.initialized ? (
          <p className="strong textCenter smallMarginTop defaultPaddingTop">
            Nenhum resultado encontrado.
          </p>
        ) : (
          <div className="smallMarginTop defaultPaddingTop">
            <Spinner></Spinner>
          </div>
        )}
      </div>
    );
  }
}

export default Table;
