import React, { useState, useEffect } from 'react';
import Score from '../../../Score';
import Observation from '../Observation';


function Question({ question = [] }) {
    const [itemSelected, setItemSelected] = useState();

    const getItemSelected = (question) => {
        return question.choices.filter((item, index) => {
            return item.selected === true;
        })[0]
    }

    useEffect(() => {
        setItemSelected(
            getItemSelected(question)
        )
    }, [])

    return (
        <>
            {itemSelected &&
                <>
                    <div className="card border flex between vCenter defaultMarginTop">
                        <div className="defaultMarginRight">
                            <p className="strong">{question.order}) {question.title}</p>
                            <p className="defaultMarginTop">
                                {itemSelected.title}
                            </p>
                        </div>
                        <Score text="Score" number={itemSelected.score} />
                    </div>

                    {question?.observation && <Observation text={question?.observation} />}
                </>
            }
        </>

    );
}

export default Question;