import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Dropdown from "../Dropdown";
import User from "../../svg/user";
import Magnify from "../../svg/magnify";
import Bell from "../../svg/bell"
import logo from "../../png/logo.png";
import useLocalStorage from "../../hooks/useLocalStorage";
import { listEntitiesByName, listMyActivities } from "../../request";
import { toast } from "react-toastify";
import ProgressBar from "../../components/ProgressBar";
import { useHistory } from "react-router-dom";
import AlertDropdown from "../AlertDropdown";

function Header() {
  const isLogged = useSelector((state) => state.status);
  const [status, setStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  let userImageUrl = useLocalStorage("userImageUrl", "");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [activities, setActivities] = useState(null);
  const [atvCount, setAtvCount] = useState(0);
  const [alertSeen, setAlertSeen] = useState(JSON.parse(window.localStorage.getItem('alertSeen')) || {seen: false, date: Date.now(), count: 0});
  const history = useHistory();

  useEffect(()=>{
    listMyActivities().then(({data})=>{
      if (data){
        setActivities(data);
        setAtvCount((data?.overdue?.length + data?.today?.length) || 0)
        if ((data?.overdue?.length + data?.today?.length) > alertSeen.count){
          setAlertSeen({seen: false, date: Date.now(), count: atvCount});
          window.localStorage.setItem("alertSeen", JSON.stringify({seen: false, date: Date.now(), count: (data?.overdue?.length + data?.today?.length) || 0}));
        }
      }
    }).catch((error) => {
      if (error.response?.data === "auth/id-token-expired") {
        toast.error(
          "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
        );
        return;
      }
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error("Não foi possível obter a lista de responsáveis");
    });
  },[]); 
  

  const handleOpen = () =>{
    if (!alertStatus){
      setAlertStatus(true);
      setAlertSeen({seen: true, date: Date.now(), count: atvCount});
      window.localStorage.setItem("alertSeen", JSON.stringify({seen: true, date: Date.now(), count: atvCount}));
    }
  }

  const handleClose = () => {
    console.log(alertStatus);
    setAlertStatus(false);
    console.log(alertStatus);
  }

  const handleSearch = () => {
    setIsLoading(true);
    if (searchValue !== "" && searchValue.length >= 2) {
      listEntitiesByName(searchValue)
        .then(({ data }) => {
          history.push({
            pathname: "/busca",
            state: { entities: data },
          });
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível obter o resultado da pesquisa");
        })
        .finally(() => setIsLoading(false));
    } else {
      toast.warning(
        "É necessário digitar pelo menos 2 caracteres para utilizar a Busca"
      );
    }
  };


  const alertPin = (count)=>{
   if (count){
    return (
      <>
        <div style={{
          height: "12px",
          width: "12px",
          position:'absolute',
          right:"0", 
          top: "0", 
          borderRadius: "6px",
          backgroundColor: "orange",
           }}>

        </div>
      </>
    )
   }else{
    return (null)
   }



  }

  return (
    <>
      {isLogged ? (
        <>
          <ProgressBar
            className="loading"
            loading={isLoading}
            color="#545557"
            height="4px"
            style={{ position: "fixed", top: 0, left: 0 }}
          />
          <div
            className={clsx(
              "contentDropdown flex center absolute fullScreenWidth fullScreenHeight",
              status && "active"
            )}
            onClick={() => setStatus(false)}
          ></div>
          <header
            className="header flex between vCenter headerPanel"
            dragable="false"
            onDragStart="return false;"
          >
            <div className="flex flexGrow vCenter">
              <a
                href="https://www.vendee.com.br/"
                target="_blank"
                dragable="false"
                onDragStart="return false;"
              >
                <figure
                  className="logoHeader flex center defaultMarginLeft pointer"
                  dragable="false"
                  onDragStart="return false;"
                >
                  <img
                    src={logo}
                    alt=""
                    dragable="false"
                    onDragStart="return false;"
                  />
                  {/* TODO -> Adicionar alt */}
                </figure>
              </a>
              <div className="contentSearch defaultMarginLeft flex center">
                <input
                  type="text"
                  value={searchValue ? searchValue : ""}
                  onChange={(event) => setSearchValue(event.target.value)}
                  className="flexGrow"
                  onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                />
                <Magnify className="pointer" onClick={() => handleSearch()} />
              </div>
            </div>
            <nav>
              <ul className="flex row">
                <li 
                className="flex end pointer" 
                style={{marginTop: "5px" ,
                  height:"24px",
                   marginRight: "10px"
                   , alignContent: "center"}}
                   onClick={() => {
                    handleOpen();}}>                  
                <div className="flex row" style={{
                  height:"24px",
                  width: '24px'}}>
                <Bell  className="defaultMarginRight userSvg pointer"/>
                </div>
                {atvCount && !alertSeen.seen && <div style={{
                        height: "12px",
                        width: "12px",
                        marginLeft: '-10px',
                        position:'relative',
                        right:"0", 
                        top: "0", 
                        borderRadius: "6px",
                        backgroundColor: "orange",
                        }}>

                      </div>}
                        {alertStatus && atvCount && <AlertDropdown props={activities} onClose={handleClose}/>}
                </li>
                <li>
                  {userImageUrl && userImageUrl[0] ? (
                    <img 
                      style={{ borderRadius: "100px" }}
                      src={userImageUrl[0]}
                      className="pointer smallMarginRight userSvg"
                      onClick={() => setStatus(true)}
                    />
                  ) : (
                    <User
                      className="defaultMarginRight userSvg pointer"
                      fill="#FFF"
                      onClick={() => setStatus(true)}
                    />
                  )}
                  {status && <Dropdown />}
                </li>
              </ul>
            </nav>
          </header>
        </>
      ) : (
        <header className="header flex between vCenter">
          <Link to="/">
            <figure className="logoHeader flex center defaultMarginLeft pointer">
              <img src={logo} alt="" />
              {/* TODO -> Adicionar alt */}
            </figure>
          </Link>
        </header>
      )}
    </>
  );
}

export default Header;
