import React, { useState } from 'react';
import Input from '../../InputHook';

function OptionEdit({ index: indexQuestion, choice, handleQuestions, focusChoice }) {

    const [title, setTitle] = useState(choice.title);
    const [hint, setHint] = useState(choice.hint);
    const [score, setScore] = useState(choice.score);

    const handleChoices = (value, property, index) => {
        let auxChoice = { ...choice };
        if (index || index === 0) {
            auxChoice[property][index] = value;
        } else {
            auxChoice[property] = value;
        }
        handleQuestions(auxChoice, "choices", indexQuestion);
    }

    return (
        <div className="flex fullWidth answer">
            <div className="fullWidth defaultMarginRight">
                <Input autoFocus={focusChoice} placeholder="Digite uma resposta" withoutMargin={true} type="text" value={title} onChange={setTitle} onBlur={() => handleChoices(title, "title")} className="defaultMarginTop flex column fullWidth hCenter" inputStyle={{ fontWeight: 600 }} isDefault={true} />

                <div className="flex hint fullWidth relative smallMarginTop">
                    <div className="flex fullWidth baseline ">
                        <span className="strong">Dica:</span> <Input placeholder="Digite uma dica para a resposta" withoutMargin={true} type="text" value={hint} onChange={setHint} onBlur={() => handleChoices(hint, "hint")} className="flex column fullWidth hCenter flexGrow" isDefault={true} inputStyle={{ border: 0 }} />
                    </div>
                </div>
            </div>
            <div className="scoreTag border rounded flex center defaultMarginTop defaultMarginLeft">
                <span className="smallMarginLeft defaultMarginRight">Score</span>
                <Input withoutMargin={true} type="number" value={score} onChange={setScore} onBlur={() => handleChoices(score, "score")} className="flex column hCenter" inputStyle={{ borderColor: "#00a19a", color: "#00a19a", maxWidth: 70, height: 24 }} />

            </div>

        </div>
    );
}

export default OptionEdit;