import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import Base from '../../components/Base';
import Config from './Config';
import Calendar from '../../svg/calendar';
import AccountMultiple from '../../svg/accountmultiple';
import Suitcase from '../../svg/suitcase';
import Gear from '../../svg/gear';
import Check from '../../svg/check';
import Cadence from '../../svg/cadence';
import Note from '../../svg/note';
//TODO Verificar porque o hoot não conseguiu buscar a informação
//import useLocalStorage from '../../hooks/useLocalStorage';
import useRefreshToken from '../../hooks/useRefreshToken';

const ConfigurationsPage = () => {
  useRefreshToken();
  const history = useHistory();
  const role = localStorage.getItem("role")
  const items = [
    { role: "all", href: "/perfil-empresa", icon: <Gear />, title: "Dados da empresa", text: "Cadastre os dados da sua empresa" },
    { role: "all", href: "/equipe", icon: <AccountMultiple />, title: "Equipe", text: "Inclua e edite usuários de sua equipe comercial" },
    { role: "all", href: "/tipos-atividades", icon: <Calendar />, title: "Tipos de atividades", text: "Tipos de atividades cadastradas" },
    { role: "all", href: "/campos-personalizados", icon: <Suitcase />, title: "Dados personalizáveis", text: "Crie e edite campos personalizados" },
    { role: "all", href: "/fluxo-qualificacao", icon: <Check />, title: "Fluxo de qualificação", text: "Edite seu questionário de qualificação de oportunidades" },
    { role: "all", href: "/funil-cadencia", icon: <Cadence />, title: "Funis e cadências", text: "Crie funis para gestão do seu processo e suas cadências de interações" },
    { role: "all", href: "/releases", icon: <Note />, title: "Releases", text: "Veja o que estamos trazendo de novidades para a plataforma" },
    { role: "admin", href: "/usuarios", icon: <Gear />, title: "Usuários" },
  ];
  return (
    <>
      <div className="fullWidth flex between titleContent">
          <Title text="Configurações" />
      </div>
      <div className="fullWidth flex column">
        <div className="fullWidth flex row center wrap">
          {
            //Filtra menu "usuarios" apenas para o admin
            items.filter(item => item.role === "all" || item.role === role)
              .map((item, idx) => (
              <Fragment key={idx} >
              { (idx > 0 && idx % 3 === 0) && <div className="fullWidth" /> }
                <Config title={item.title} text={item.text} icon={item.icon} href={`configuracoes${item.href}`} />
              </Fragment>
            ))
          }
        </div>
      </div>
    </>
  )
}

export default Base(ConfigurationsPage);
