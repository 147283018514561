import React, { useState, useEffect } from "react";
import Base from "../../components/Base";
import Table from "../../components/Table";
import Select from "../../components/Select";
import Title from "../../components/Title";
import ProgressBar from "../../components/ProgressBar";
import mountTable from "./functions";
import { getAllCompaniesProfiles } from "../../request";
import { toast } from "react-toastify";

const RegisteredUsersPage = () => {
  const [tbody, setTbody] = useState([]);
  const [res, setRes] = useState([]);
  const [atualSort, setAtualSort] = useState({
    type: "email",
    variant: "upperEmail",
  });
  const [atualFilter, setAtualFilter] = useState("0");
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(null);

  const listCompanieProfiles = () => {
    setInitialized(false);
    setTbody([]);
    getAllCompaniesProfiles()
      .then(({ data }) => {
        setRes(data);
        setTbody(
          mountTable({ atualFilter, atualSort, data }, () =>
            setInitialized(true)
          )
        );
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error(
          "Não foi possível carregar as empresas cadastradas no sistema"
        );
      });
  };

  const handleSortByEmail = (atualSort) => {
    if (atualSort.variant != "upperEmail") {
      setAtualSort({ type: "email", variant: "upperEmail" });
    } else {
      setAtualSort({ type: "email", variant: "lowerEmail" });
    }
  };

  const handleSortByStatus = (atualSort) => {
    if (atualSort.variant != "upperStatus") {
      setAtualSort({ type: "status", variant: "upperStatus" });
    } else {
      setAtualSort({ type: "status", variant: "lowerStatus" });
    }
  };

  useEffect(() => listCompanieProfiles(), []);
  useEffect(() => {
    setTbody([]);
    setTbody(mountTable({ atualFilter, atualSort, data: res }));
  }, [atualFilter, atualSort]);

  return (
    <>
      <ProgressBar
        className="loading"
        loading={isLoading}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />
      <div className="fullWidth flex between titleContent">
        <Title text="Usuários cadastrados" />
      </div>
      <Select
        value={atualFilter}
        style={{ maxWidth: "150px" }}
        onChange={(value) => setAtualFilter(value)}
        options={[
          { id: "0", label: "Todos" },
          { id: "1", label: "Ativos" },
          { id: "2", label: "Inativos" },
        ]}
      />

      <Table
        thead={[
          {
            name: "E-mail",
            id: "email",
            sort: () => handleSortByEmail(atualSort),
          },
          { name: "Telefone", id: "phone" },
          { name: "CNPJ", id: "cnpj" },
          { name: "Segmento", id: "segment" },
          { name: "Equipe", id: "teamSize" },
          { name: "Objetivo", id: "goal" },
          {
            name: "Status",
            id: "status",
            className: "flex end vCenter",
            sort: () => handleSortByStatus(atualSort),
          },
        ]}
        loading={setIsLoading}
        tbody={tbody}
        initialized={initialized}
        refetch={listCompanieProfiles}
      />
    </>
  );
};

export default Base(RegisteredUsersPage);
