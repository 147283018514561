import React from 'react';
import Edit from '../../../svg/edit';
import Profile from '../../../svg/profile';
const Member = ({ member, onEdit, urlImage, isManager }) => (
  <div className="fullWidth flex wrap teamItem">
    <div className="flex flexGrow vCenter halfWidth">
      <div className="defaultMarginRight">
        <Profile fill="#545557" width="18" height="18" background="#F5F5F5" urlImage={urlImage}/>
      </div>
      <p className="strong">{member.name}</p>
    </div>
    <div className="flexGrow flex flexGrow between halfWidth">
      <hr className="verticalHr" />
      <p className="flex column" style={{marginLeft: '20px', marginRight: 'auto'}}>
        <span>{member.email}</span>
        <span className="smallMarginTop">{member.phone}  {member.secondPhone && " | " + member.secondPhone}</span>
      </p>
        {isManager ? '' : <Edit className="flex center pointer" onClick={() => { onEdit(member) }} />}      
    </div>
  </div>
)

export default Member;