import React from 'react';

function Linkedin({ width = "19.99", height = "19.094", ...props }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} width={width} height={height} viewBox="0 0 19.99 19.094">
            <g transform="translate(616 883.466)">
                <path
                    d="M214-162.02c0-.036.007-.072.007-.107.011-2.063.026-4.125.03-6.188,0-1.893,0-3.787-.006-5.68,0-.3-.013-.594-.019-.891l.005-.006s0-.005.006-.005l4.2,0c.019,0,.038,0,.069,0,0,.12,0,.238,0,.356s0,.239,0,.358v1.073l.017.008c.016-.023.034-.045.049-.068a5.036,5.036,0,0,1,.919-1.038,4.131,4.131,0,0,1,2.089-.942,5.331,5.331,0,0,1,2.389.161,4.337,4.337,0,0,1,2.706,2.333,5.778,5.778,0,0,1,.482,1.526,6.728,6.728,0,0,1,.121,1.082.343.343,0,0,0,.013.064v7.962h-4.264c0-.039-.005-.077-.005-.116q0-3.343,0-6.687a6.035,6.035,0,0,0-.1-1.168,2.957,2.957,0,0,0-.3-.869,1.734,1.734,0,0,0-1.127-.88,2.626,2.626,0,0,0-.892-.063,2.085,2.085,0,0,0-.81.231,2.421,2.421,0,0,0-.807.732,2.323,2.323,0,0,0-.419.889,3.8,3.8,0,0,0-.063.787q0,3.527,0,7.053c0,.03,0,.061.006.091Z"
                    transform="translate(-823.087 -702.352)"
                />
                <path d="M-584.244-124.886c0-.033,0-.067,0-.1q0-6.33,0-12.66v-.106c.063-.019,4.16-.025,4.277-.007,0,.02,0,.041.006.063s0,.055,0,.083q0,6.3,0,12.61c0,.039,0,.078,0,.117Z" transform="translate(-31.49 -739.486)" />
                <path
                    d="M-616-881.43a.854.854,0,0,0,.03-.111,2.082,2.082,0,0,1,1.239-1.686,2.67,2.67,0,0,1,1.239-.238,2.53,2.53,0,0,1,1.127.253,2.077,2.077,0,0,1,1.164,1.577,2.271,2.271,0,0,1-.094,1.206,2.122,2.122,0,0,1-1.229,1.23,2.5,2.5,0,0,1-.784.178,2.9,2.9,0,0,1-1.145-.108,2.119,2.119,0,0,1-1.489-1.633c-.013-.057-.017-.115-.027-.172s-.021-.085-.031-.128Z"
                />
            </g>
        </svg>

    );

}

export default Linkedin;