import React, { useState } from "react";
import { useEffect } from "react";
import LostLeadsDateLimitModal from "../LostLeadsDateLimitModal";
import "./style.css";

const LostLeadsDateLimitMenu = ({
  lostLeadsLimitDate,
  setLostLeadsLimitDate,
}) => {
  const [clicked, setClicked] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setClicked(!clicked);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    console.log(clicked);
  }, [clicked]);

  return (
    <>
      <ul className="dropbtn icons btn-right showLeft" onClick={toggle}>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      {clicked ? (
        <div className="lost-leads-overlay" onClick={toggle}>
          <div className="dropdown-lost-leads-menu">
            <a onClick={toggleModal}>Ocultar Perdidos</a>
          </div>
        </div>
      ) : null}
      <LostLeadsDateLimitModal
        modal={modal}
        toggleModal={toggleModal}
        lostLeadsLimitDate={lostLeadsLimitDate}
        setLostLeadsLimitDate={setLostLeadsLimitDate}
      />
    </>
  );
};

export default LostLeadsDateLimitMenu;
