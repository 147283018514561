import React, { useState, useEffect } from "react";
import Question from "../../Question";
import Observation from "./Observation";
import Button from "../../Button";
import ModalTitle from "../ModalTitle";
import {
  getQualification,
  getQualificationTemplate,
  postQualification,
  putQualification,
} from "../../../request";
import clsx from "clsx";
import Spinner from "../../../svg/spinner";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function QualificationEdit({
  status,
  closeModal,
  companyID,
  leadID,
  name,
  setContinueLaterModal,
  setQualifiedModal,
  setUnqualifiedModal,
}) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [atualQuestion, setAtualQuestion] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [loadingPutQualification, setLoadingPutQualification] = useState(false);

  const selectChoice = (idx) => {
    setForm((prev) => {
      let obj = { ...prev };
      obj.questions[atualQuestion].choices.map(
        (item, i) => (item.selected = i === idx)
      );
      return obj;
    });
  };

  const handleAnnotation = (value) => {
    setForm((prev) => {
      let obj = { ...prev };
      obj.observation.annotation = value;
      return obj;
    });
  };

  const qualify = ({ created, updated, ...form }) => {
    setLoadingPutQualification(true);
    let obj = form;
    let isFinished =
      form.questions.length == atualQuestion + 1 &&
      form.questions[atualQuestion].choices.reduce((status, item) => {
        if (item.selected) {
          status = true;
          return status;
        }
        return status;
      }, false);

    if (isFinished) {
      obj = { ...obj, done: true };
    }

    const handleModal = ({ qualified, id: value }) => {
      if (!isFinished) {
        setContinueLaterModal(true);
      } else {
        if (qualified) {
          setQualifiedModal(true);
        } else {
          dispatch({ type: "SET_CURRENT", value });
          setUnqualifiedModal(true);
        }
      }
    };

    if (!alreadyExists) {
      postQualification({ ...obj }, leadID)
        .then(({ data }) => {
          toast.success("Qualificação cadastrada com sucesso");
          setAlreadyExists(true);
          handleModal(data);
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
            );
            return;
          }
          if (error.response?.data === "INVALID_TITLE") {
            toast.error("O título das respostas não pode ser em branco");
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível cadastrar a qualificação");
        })
        .finally(() => {
          setLoadingPutQualification(false);
          closeModal();
        });
    } else {
      //delete obj.score;
      putQualification({ ...obj }, leadID)
        .then(({ data }) => {
          toast.success("Qualificação atualizada com sucesso");
          handleModal(data);
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          if (error.response?.data === "INVALID_TITLE") {
            toast.error("O título das respostas não pode ser em branco");
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível atualizar a qualificação");
        })
        .finally(() => {
          setLoadingPutQualification(false);
          closeModal();
        });
    }
  };

  const nextQuestion = (form) => {
    var obj = { ...form };
    if (obj.current === atualQuestion) {
      obj.current++;
    }
    setAtualQuestion((prev) => {
      prev++;
      return prev;
    });
    setForm(obj);
  };

  const previousQuestion = () => {
    setAtualQuestion((prev) => prev - 1);
  };

  useEffect(() => {
    setForm({});
    getQualification({ leadID })
      .then(({ data }) => {
        if (data.length) {
          setAlreadyExists(true);
          setForm(data[data.length - 1]);
          setAtualQuestion(
            data[data.length - 1].current ? data[data.length - 1].current : 0
          );
        } else {
          setAlreadyExists(false);
          getQualificationTemplate(companyID)
            .then(
              ({
                data: { questions, current, id: qualificationTemplateID },
              }) => {
                let obj = {
                  current: current ? current : 0,
                  questions,
                  qualificationTemplateID,
                  observation: {
                    annotation: "",
                    notQualified: "",
                  },
                  leadID,
                };
                setForm(obj);
                setAtualQuestion(current ? current : 0);
              }
            )
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error(
                "Não foi possível carregar a template de qualificação"
              );
            });
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualize a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar a qualificação");
      });
  }, []);

  useEffect(() => {
    if (form.questions) {
      setCanContinue(
        form.questions[atualQuestion]?.choices.reduce((isSetted, item) => {
          if (item.selected) return true;
          return isSetted;
        }, false)
      );
    }
  }, [form, atualQuestion]);

  return (
    <>
      <ModalTitle
        title="Qualificação"
        className="qualificationTitle"
        icon={require("../../../png/check2.png")}
      />

      <div className="card border defaultMarginTop flex between vCenter">
        <h4>{name && name !== "" ? name : "Nome não informado"}</h4>
        {/* TODO: Atribuir nome do negócio */}
      </div>
      {form.questions && form.questions.length ? (
        <>
          <div className="fullWidth flex column">
            <Question
              name={"question_" + atualQuestion}
              index={atualQuestion}
              title={form.questions[atualQuestion].title}
              question={form.questions[atualQuestion]}
              onChange={selectChoice}
            />
            <Observation
              type="edit"
              onChange={(value) => handleAnnotation(value)}
              value={form && form.observation.annotation}
            />
          </div>
          <div
            className={clsx(
              "fullWidth flex",
              atualQuestion > 0 && "between",
              atualQuestion === 0 && "end"
            )}
          >
            {atualQuestion > 0 && (
              <Button
                text="Anterior"
                className="defaultMarginTop smallMarginRight"
                paddingHorizontal="30px"
                marginTop="25px"
                onClick={previousQuestion}
              />
            )}
            <div className="flex">
              <Button
                text="Continuar depois"
                isLoading={loadingPutQualification}
                className="defaultMarginTop smallMarginRight"
                paddingHorizontal="30px"
                marginTop="25px"
                onClick={() => (canContinue ? qualify(form) : null)}
              />
              {atualQuestion !== form.questions.length - 1 ? (
                <Button
                  text="Próximo"
                  className="defaultMarginTop"
                  paddingHorizontal="30px"
                  marginTop="25px"
                  disabled={!canContinue}
                  onClick={() => (canContinue ? nextQuestion(form) : null)}
                />
              ) : (
                <Button
                  text="Qualificar"
                  isLoading={loadingPutQualification}
                  className="defaultMarginTop"
                  paddingHorizontal="30px"
                  marginTop="25px"
                  disabled={!canContinue}
                  onClick={() => (canContinue ? qualify(form) : null)}
                />
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default QualificationEdit;
