import React from 'react';
import clsx from 'clsx';

function Common(props) {

    return (
        // <div style={{position: 'relative'}}className={clsx("headerStage flex center divSpanText", props.className, props.active && "active")} onClick={props.onClick && props.onClick}>
            
            <div style={{display:'flex', position: 'relative', width:'100%', heigth:'50%'}} className={clsx("headerStage flex", props.className, props.active && "active")} onClick={props.onClick && props.onClick}>
            {props.icon && <img src={props.icon} alt="" className="smallMarginRight" />}
            {/* {props.iconLeft && <img src={props.iconLeft} alt="" className="smallMarginLeft" />} */}
            {/* <span className="spanText strong textCenter"> */}
            <span className="spanText strong">
                {props.text}
            </span>
            </div>
 

    )

}

export default Common;