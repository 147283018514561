import React from 'react';

function BackIcon({ ...props }) {
    return (
      
        <svg width="30" height="30" viewBox="0 0 61 61" xmlns="http://www.w3.org/2000/svg" {...props}>
            {/* <circle cx="30.5" cy="30.5" r="30.5" fill="#009e9a" /> */}
            <circle cx="30.5" cy="30.5" r="30.5" fill="#545557" />
            <circle transform="translate(-1.42 29.84) rotate(-45)" cx="35.31" cy="16.64" r="2.69" fill="#fff" />
            <circle transform="translate(-15.38 24.06) rotate(-45)" cx="21.36" cy="30.59" r="2.69" fill="#fff" />
            <rect transform="translate(25.04 -13.01) rotate(45)" x="25.54" y="13.41" width="5.38" height="20.63" fill="#fff" />
            <circle transform="translate(-21.23 38.04) rotate(-45)" cx="35.31" cy="44.64" r="2.69" fill="#fff" />
            <circle transform="translate(-15.45 24.09) rotate(-45)" cx="21.36" cy="30.69" r="2.69" fill="#fff" />
            <rect transform="translate(74.75 44.16) rotate(135)" x="25.54" y="27.25" width="5.38" height="20.63" fill="#fff" />
        </svg>

    );
}

export default BackIcon;
