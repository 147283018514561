import { setup } from "axios-cache-adapter";
// setup api instance
export const api = setup({
  cache: {
    maxAge: 24 * 3600 * 1000, // 1 day
    exclude: { query: false },
    debug: false,
  },
});

export const reset = () => {
  api.cache.store = {};
};
