const activities = (state = {}, action) => {
    switch (action.type) {
        case "SET_REFETCH":
            return { ...state, refetch: action.value };
        case "SET_LOADING":
            return { ...state, loading: action.value };
        case "SET_ACTION":
            return { ...state, action: action.value };
        default:
            return state;
    }
}

export default activities;