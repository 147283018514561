import React, { useState } from "react";
import clsx from "clsx";
import UniqueValue from "../../../../svg/uniqueValue";
import RecurringValue from "../../../../svg/recurringValue";

function Full({ className, text, length, totalPrice, totalRec, iconLeft }) {
  const [view, setView] = useState(false);
  const show = () => alert("oi");

  const moneyFormater = (num) => {
    if (num > 999999) {
      return `R$ ${
        Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
      }`;
    } else if (num > 999) {
      return `R$ ${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"}`;
    } else {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(num);
    }
  };

  return (
    <div
      style={{ display: "flex", padding: "12px" }}
      className={clsx("headerStage flex column divSpanText active", className)}
      draggable="false"
      onDragStart="return false;"
    >
      <div className="row" style={{ display: "flex", width: "100%" }}>
        <span className="strong" draggable="false" onDragStart="return false;">
          {text}
        </span>
        <span style={{ width: "70%", position: "center" }}>
          <div className="flex right" style={{ marginRight: "10px" }}>
            <img
              src={require("../../../../png/suitcase_min.png")}
              height="14px"
              alt=""
              draggable="false"
              onDragStart="return false;"
            />
            <span style={{ marginLeft: "5px", paddingTop: "2px" }}>
              {length}
            </span>
          </div>
        </span>
        {className == "fail" && (
          <img
            src={iconLeft}
            width="20px"
            alt=""
            draggable="false"
            onDragStart="return false;"
          />
        )}
        {className == "success" && (
          <img
            src={iconLeft}
            width="20px"
            alt=""
            draggable="false"
            onDragStart="return false;"
          />
        )}
        <button onMouseOver={() => alert("oi")}>
          {className == "success"}
        </button>
      </div>

      <div className="flex" style={{ height: "25px" }}></div>
      <div className="row" style={{ display: "flex", width: "100%" }}>
        <div className="flex row">
          <UniqueValue
            style={{
              fill: "white",
              width: "20px",
              height: "20px",
              marginRight: "4px",
              verticalAlign: "middle",
              display: `${totalPrice || !totalRec ? "" : "none"}`,
            }}
          />

          <p
            style={{
              display: `${totalPrice || !totalRec ? "" : "none"}`,
              padding: "3px",
            }}
          >
            {moneyFormater(totalPrice)}
          </p>
        </div>
        <span style={{ width: "70%" }}> </span>
        <div className="flex row">
          <span
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <RecurringValue
              style={{
                fill: "white",
                width: "20px",
                height: "20px",
                marginRight: "4px",
                verticalAlign: "middle",
                display: `${totalRec ? "" : "none"}`,
              }}
            />
            <p
              style={{
                display: `${totalRec ? "" : "none"}`,
                padding: "3px",
              }}
            >
              {moneyFormater(totalRec)}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Full;
