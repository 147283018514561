import React, { useState, useEffect } from "react";
import { putPipeline, postPipeline } from "../../../request";
import ModalTitle from "../ModalTitle";
import Chip from "../../Chip";
import Stage from "./Stage";
import Input from "../../InputHook";
import Hr from "../../Hr";
import PlusButton from "../../../svg/plus_button";
import Button from "../../Button";
import Trash from "../../../svg/trash";
import { toast } from "react-toastify";

function PipelineAndCadenceEdit({ pipeline, status, closeModal }) {
  const [fields, setFields] = useState({
    description: "",
    stages: [],
  });
  const [atualStage, setAtualStage] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleFields = (value, property, id) => {
    let auxPrev = { ...fields };
    if (id) {
      auxPrev[property] = auxPrev[property].map((item) => {
        if (item.id == id) {
          return value;
        }
        return item;
      });
    } else {
      auxPrev[property] = value;
    }
    setFields(auxPrev);
  };

  const addActivitie = (fields, id, atualStage) => {
    let auxPrev = [...fields.stages];
    auxPrev = auxPrev.map(({ suggestedActivities = [], ...rest }) => {
      suggestedActivities = [...suggestedActivities]; //NOTE: Não apagar, importante para remover a referência do objeto inicial
      suggestedActivities = suggestedActivities.map((item) => ({ ...item })); //NOTE: Não apagar, importante para remover a referência do objeto inicial
      if (rest.id === atualStage) {
        suggestedActivities.push({ duration: "1h", cadence: "1h", id });
      }
      return { ...rest, suggestedActivities };
    });
    return { ...fields, stages: auxPrev };
  };

  const removeActivitie = (fields, stageID, indexActivity) => {
    let auxPrev = [...fields.stages];

    stageID = +auxPrev.reduce((total, item, index) => {
      if (item.id === stageID) return `${index}`;
      return total;
    }, "");

    auxPrev = auxPrev.map(
      ({ suggestedActivities = [], ...rest }, indexStage) => {
        suggestedActivities = [...suggestedActivities]; //NOTE: Não apagar, importante para remover a referência do objeto inicial
        suggestedActivities = suggestedActivities
          .map((item) => ({ ...item }))
          .filter((item, index) => {
            return !(indexStage === stageID && index == indexActivity);
          }); //NOTE: Não apagar, importante para remover a referência do objeto inicial
        return { ...rest, suggestedActivities };
      }
    );
    return { ...fields, stages: auxPrev };
  };
  const getMockStages = () => [
    {
      description: "Nova etapa pré-venda",
      step: "pre-seller",
      order: 1,
      suggestedActivities: [],
      id: Math.round(Math.random() * 1000),
    },
    {
      description: "Nova etapa venda",
      step: "seller",
      order: 2,
      suggestedActivities: [],
      id: Math.round(Math.random() * 1000),
    },
  ];

  const clearFields = () => {
    setFields({ description: "", stages: getMockStages() });
  };

  const handleSendPipeline = ({
    created,
    updated,
    companyID,
    userUID,
    ...rest
  }) => {
    setLoading(true);
    rest.stages = rest.stages
      .sort((a, b) => ("" + a.step).localeCompare(b.step))
      .filter((item) => {
        return item.step !== "won" && item.step !== "lost";
      })
      .sort((a, b) => a.order - b.order)
      .map((item, index) => {
        if (!item.created) {
          delete item.id;
        }
        item.order = index + 1;
        return item;
      });
    let sendPipeline = pipeline ? putPipeline : postPipeline;
    console.log(pipeline);
    if (!rest.description) {
      const today = new Date().toLocaleString("en-GB", { timeZone: "UTC" });
      rest.description = `Novo Funil - ${today}`;
    }
    if (pipeline) {
      rest.id = pipeline.id || pipeline._id.valueOf().toString();
    }
    sendPipeline(rest)
      .then(() => {
        toast.success("Funil atualizado/cadastrado com sucesso");
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar o funil");
      })
      .finally(() => {
        closeModal();
        setLoading(false);
      });
  };

  const onDragStart = (e, activityID, activityIndex, stageID) => {
    e.dataTransfer.setData("activityID", activityID);
    if (stageID) {
      e.dataTransfer.setData("activityIndex", activityIndex);
      e.dataTransfer.setData("lastStageID", stageID);
    }
  };

  const onDragOver = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setAtualStage(id);
  };

  const onDrop = (e, newStageID) => {
    e.preventDefault();
    e.stopPropagation();
    let auxFields = JSON.parse(JSON.stringify(fields));
    if (e.dataTransfer.getData("activityIndex")) {
      auxFields = removeActivitie(
        auxFields,
        e.dataTransfer.getData("lastStageID"),
        e.dataTransfer.getData("activityIndex")
      );
    }
    if (newStageID)
      auxFields = addActivitie(
        auxFields,
        e.dataTransfer.getData("activityID"),
        newStageID
      );
    setFields(auxFields);
    e.dataTransfer.clearData("activityID");
    e.dataTransfer.clearData("lastStageID");
  };

  const deleteStage = (stage) => {
    fields.stages.splice(fields.stages.indexOf(stage), 1);
    let auxFields = JSON.parse(JSON.stringify(fields));

    if (auxFields.stages.length == 2) {
      const defaultStages = getMockStages();
      defaultStages.forEach((element) => {
        auxFields.stages.push(element);
      });
    }
    setFields(auxFields);
  };

  useEffect(() => {
    if (pipeline && status) {
      let {
        companyID,
        created,
        description,
        id,
        stages,
        teamID,
        updated,
        userUID,
      } = pipeline;

      stages = stages.map(({ suggestedActivities = [], ...item }) => ({
        ...item,
        suggestedActivities: [...suggestedActivities],
      }));

      setFields({
        companyID,
        created,
        description,
        id,
        stages,
        teamID,
        updated,
        userUID,
      });
    } else {
      clearFields();
    }
  }, [status, pipeline]);

  return (
    <>
      <ModalTitle
        title="Editar funil e cadências"
        icon={require("../../../png/gear.png")}
      />
      <div
        className="fullWidth funnelAndCadenceContent card border"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <Input
          className="fullWidth"
          placeholder="Digite o nome do funil"
          isDefault={true}
          value={fields.description}
          withoutMargin={true}
          onChange={(value) => handleFields(value, "description")}
          inputStyle={{ fontWeight: 700, fontSize: 14 }}
        />
        <div
          style={{ position: "relative" }}
          className="flex fullWidth defaultMarginTop defaultMarginBottom defaultPaddingBottom contentStages auto"
        >
          {fields.stages &&
            fields.stages
              .filter((item) => item.step == "pre-seller")
              .sort((a, b) => a.order - b.order)
              .map((item, index) => {
                return (
                  <>
                    <Stage
                      onDragStart={onDragStart}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      type="editation"
                      key={item.id + "edit"}
                      item={item}
                      index={index}
                      handleFields={handleFields}
                      setAtualStage={setAtualStage}
                      atualStage={atualStage}
                    />

                    <div style={{ position: "relative" }}>
                      <Trash
                        className="pointer"
                        height="10px"
                        onClick={(e) => deleteStage(item)}
                        fill="white"
                        //style={{ marginTop: "16px", zIndex: 1, position: 'relative', left: "-31px" }}
                        style={{
                          top: "15px",
                          zIndex: 1,
                          position: "absolute",
                          right: "10px",
                        }}
                      />
                    </div>
                  </>
                );
              })}

          {fields.stages &&
          fields.stages.filter((item) => item.step == "pre-seller").length >
            0 ? (
            <div
              className="flex center pointer relative"
              style={{ marginRight: 15, marginLeft: 5 }}
            >
              <Hr className="verticalHr" />

              <div className="absolute rounded flex center contentPlusButton defaultPadding pointer">
                <PlusButton
                  onClick={() => {
                    setFields((prev) => {
                      let { stages } = prev;
                      stages = [
                        ...stages,
                        {
                          description: "Nova etapa pré-venda",
                          step: "pre-seller",
                          suggestedActivities: [],
                          id: `${Math.round(Math.random() * 10000)}`,
                        },
                      ];
                      return { ...prev, stages };
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
          {fields.stages &&
            fields.stages
              .filter((item) => item.step == "seller")
              .sort((a, b) => a.order - b.order)
              .map((item, index) => {
                return (
                  <>
                    <Stage
                      onDragStart={onDragStart}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      type="editation"
                      key={item.id + "edit"}
                      item={item}
                      index={index}
                      handleFields={handleFields}
                      setAtualStage={setAtualStage}
                      atualStage={atualStage}
                    />
                    <div style={{ position: "relative" }}>
                      <Trash
                        className="pointer"
                        height="10px"
                        onClick={(e) => deleteStage(item)}
                        fill="white"
                        //style={{ marginTop: "16px", zIndex: 1, position: 'relative', left: "-31px" }}
                        style={{
                          top: "15px",
                          zIndex: 1,
                          position: "absolute",
                          right: "10px",
                        }}
                      />
                    </div>
                  </>
                );
              })}
          {fields.stages &&
          fields.stages.filter((item) => item.step == "seller").length > 0 ? (
            <div
              className="flex center pointer relative"
              style={{ marginRight: 15, marginLeft: 10 }}
            >
              <div className="absolute rounded flex center contentPlusButton defaultPadding pointer">
                <PlusButton
                  onClick={() => {
                    setFields((prev) => {
                      let { stages } = prev;
                      stages = [
                        ...stages,
                        {
                          description: "Nova etapa venda",
                          step: "seller",
                          suggestedActivities: [],
                          id: `${Math.round(Math.random() * 10000)}`,
                        },
                      ];
                      return { ...prev, stages };
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
        <h5 className="defaultMarginVertical">
          Clique e arraste as atividades
        </h5>
        <div className="flex">
          {["email", "phone", "in-person", "whatsapp", "video"].map((item) => (
            <Chip
              draggable="true"
              onDragStart={(e) => onDragStart(e, item)}
              type={item}
              className="smallMarginRight pointer"
            />
          ))}
        </div>
      </div>

      <div className="flex end fullWidth">
        <Button
          text="Salvar"
          onClick={() => handleSendPipeline({ ...fields })}
          isLoading={loading}
          className="defaultMarginTop"
          paddingHorizontal="15px"
        />
      </div>
    </>
  );
}

export default PipelineAndCadenceEdit;
