import React, { Fragment } from 'react';

class Suitcase extends React.Component {
    render() {
        return (
            <Fragment>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 24.7"><path d="M17.6,7.2V4.6H12.4V7.2ZM4.6,9.8V24.1H25.4V9.8ZM25.4,7.2A2.591,2.591,0,0,1,28,9.8V24.1a2.591,2.591,0,0,1-2.6,2.6H4.6A2.591,2.591,0,0,1,2,24.1L2.013,9.8A2.58,2.58,0,0,1,4.6,7.2H9.8V4.6A2.591,2.591,0,0,1,12.4,2h5.2a2.591,2.591,0,0,1,2.6,2.6V7.2Z" transform="translate(-2 -2)" /></svg>
            </Fragment>

        )
    }
}

export default Suitcase;

