import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isTokenExpired } from "../../src/utils/index";
import { Route, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { refreshToken } from "../../src/request/index";

const CommonRoute = ({ children, component, redirect, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checkStatus, setCheckStatus] = useState(false);

  const status = useSelector((state) => state.status);

  const setStatus = useCallback(
    (status) => {
      dispatch({ type: "CHANGE_STATUS", status });
    },
    [dispatch]
  );

  useEffect(() => {
    const refToken = localStorage.getItem("refreshToken") || "";
    const token = localStorage.getItem("token") || "";
    if (token && !isTokenExpired(token)) {
      setStatus(true);
    }
    if (refToken && !isTokenExpired(refToken)) {
      refreshToken(refToken)
        .then((data) => {
          window.localStorage.setItem("token", data.token);
          setStatus(true);
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível atualizar o token do usuário");
        });
    }
    if (!refToken) {
      setStatus(false);
    }
  }, [setStatus]);

  return (
    <>
      {status !== null ? (
        <Route
          {...rest}
          render={() =>
            !status ? (
              children || component
            ) : (
              <Redirect to={redirect ? redirect : "/dashboard"} />
            )
          }
        />
      ) : (
        null
      )}
    </>
  );
};

export default CommonRoute;
