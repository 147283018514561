import React, { useEffect, useState, Fragment } from "react";
import clsx from "clsx";
import Control from "../Control";
import { getInformationsByType } from "../../request";

function TextArea({ controlled, selectedType, isBusinessPage, ...props }) {
  const [controls, setControls] = useState([]);

  const handleControl = (index, controls) => {
    setControls(
      controls
        .map((item) => ({ ...item, active: false }))
        .map((item, indexMap) => ({
          ...item,
          active: index === indexMap ? !item.active : false,
        }))
    );
  };

  const resetControls = () => {
    if (controls?.length) {
      setControls(controls.map((item) => ({ ...item, active: false })));
    }
  };

  const selectControl = (selectedType) => {
    if (controls?.length) {
      setControls(
        controls.map((item) => ({ ...item, active: item.id === selectedType }))
      );
    }
  };

  useEffect(() => {
    if (controls.length) {
      let id = controls.reduce((all, { id, active }) => {
        if (!!active) {
          all = id;
        }
        return all;
      }, "");
      props.setType(id);
    }
  }, [controls]);

  useEffect(() => {
    if (selectedType === "") {
      resetControls();
    } else {
      // props.setType(selectedType);
      selectControl(selectedType);
    }
  }, [selectedType]);

  useEffect(() => {
    if (controlled) {
      getInformationsByType("ACTIVITY_TYPE").then(({ data }) => {
        setControls(
          data[0]?.data
            ?.filter((item) => item.id != "note")
            .map((item) => ({ ...item, active: false }))
        );
      });
    } else {
      setControls([]);
    }
  }, [controlled]);

  return (
    <>
      <label
        className={clsx(
          "flex column secondaryLabel relative",
          !props.withoutMargin && "smallMarginTop",
          props.className,
          props.className &&
            props.className.indexOf("noLimit") !== -1 &&
            "noLimit"
        )}
      >
        {props.label && <span>{props.label}</span>}
        <textarea
          className={clsx(
            "variantInput smallMarginTop",
            !props.withoutMargin && "smallMarginTop"
          )}
          disabled={props.disabled ? true : false}
          type={props.type ? props.type : "text"}
          value={props.value ? props.value : ""}
          placeholder={props.placeholder ? props.placeholder : ""}
          onChange={(inpt) => {
            props.onChange(inpt.target.value);
          }}
        />
        {controlled && !!controls.length && (
          <div className="fullWidth absolute contentControl flex end vCenter">
            {controls.map(({ id, active }, index, array) => (
              <Fragment key={id}>
                <div
                  className={clsx(
                    "control rounded border flex center pointer",
                    active && "active"
                  )}
                  onClick={() => {
                    handleControl(index, array);
                  }}
                >
                  <Control icon={id} index={index} />
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </label>
    </>
  );
}

export default TextArea;
