import React from "react";

const TicketChart = ({ date, ticket }) => {
  const rounder = (value) => {
    return Math.round(value * 100) / 100;
  };

  //formata o ticket de acordo com o valor
  const calcTicket = (ticket) => {
    if (ticket >= 1000000) {
      return `R$ ${rounder(ticket / 1000000)} M`;
    }
    if (ticket >= 1000) {
      return `R$ ${rounder(ticket / 1000)}K`;
    }
    return `R$ ${rounder(ticket)}`;
  };

  return (
    <>
      <div className="chart">
        <h4 className="largeMarginBottom dateText">{date}</h4>
        <h1 className="largeMarginBottom ticketText ">{calcTicket(ticket)}</h1>
      </div>
    </>
  );
};

export default TicketChart;
