import React from 'react';

function PlusButton({ fill = "#00a19a", ...rest }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 39 39" fill={fill} {...rest}>
            <path d="M23.45,11.75h-3.9v7.8h-7.8v3.9h7.8v7.8h3.9v-7.8h7.8v-3.9h-7.8ZM21.5,2A19.5,19.5,0,1,0,41,21.5,19.507,19.507,0,0,0,21.5,2Zm0,35.1A15.6,15.6,0,1,1,37.1,21.5,15.621,15.621,0,0,1,21.5,37.1Z" transform="translate(-2 -2)" />
        </svg>
    )

}

export default PlusButton;