import React, { useState } from "react";
import { useEffect } from "react";

const SchedulesChart = ({ schedules }) => {
  const [schedule, setSchedules] = useState([]);
  const [counter, setCounter] = useState();

  useEffect(() => {
    setSchedules(schedules);
    setCounter(() => schedule.length);
  }, [schedules, schedule]);
  return (
    <div className="chart">
      <h2 style={{ textAlign: "center" }}>Agendamentos do mês</h2>
      <h1
        style={{
          textAlign: "center",
          fontSize: "50px",
          fontWeight: "700",
          padding: "40px",
          color: "#00a19a",
        }}
      >
        {counter == 0 ? (
          <span style={{ fontSize: "24px", color: "#545557" }}>
            Sem agendamentos neste mês
          </span>
        ) : (
          counter
        )}
      </h1>
    </div>
  );
};

export default SchedulesChart;
