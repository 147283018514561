import React from 'react';
import Title from '../Title';
import ArrowLeft from '../../svg/arrowleft';
import { useHistory } from 'react-router-dom';
import BackIcon from '../../svg/backIcon';

const ConfigurationPageTitle = props => {
  const history = useHistory();
  return (
    <>
      <div className="fullWidth flex between" style={{ marginBottom: "15px" }}>
        <Title text={props.title} />
        {props.children}
      </div>
      <div>
        <BackIcon style={{ marginBottom: "15px" }} className="pointer flexShrinkNone" onClick={() => {
          history.goBack()
        }} />
      </div>

      </>
  )
};

export default ConfigurationPageTitle;


// <>
//         <div className="fullWidth flex between">
//           <ArrowLeft className="pointer flexShrinkNone" onClick={() => {
//             history.goBack()
//           }} style={{marginTop:"5px", marginRight:"25px"}}/>
//           <div className="fullWidth flex between titleContent">
//             <Title text={props.title} />
//             {props.children}
//           </div>
//         </div>
//       </>