import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

function Pace({ height, color, style, id, className, loading }) {

    const [width, setWidth] = useState(0);
    const [interval, setterInterval] = useState();

    useEffect(() => {
        setterInterval(
            setInterval(() => setWidth((prev) => prev + (1 - prev) * 0.2), 500 + 200 * Math.random())
        );
    }, [])

    useEffect(() => {
        if (loading === false) {
            clearInterval(interval);
            setWidth(100);
        }
    }, [loading]);

    const styles = (() => {
        const css = {
            main: {
                height: height || 10,
                backgroundColor: color || '#2c3e50',
                width: `${Math.floor(width * 100)}%`
            },
            container: {
                position: 'absolute',
                width: '100%'
            }
        };

        if (css) {
            css.main = { ...style, ...css.main };
        }

        return css;
    })();

    if (loading != null) {
        return (
            <div style={styles.container} className={clsx("loadingContainer", width == 100 && "hide")}>
                <div {...{ id, className }} style={styles.main} className="loading" />
            </div>
        );
    } else {
        return null;
    }

}

export default Pace;