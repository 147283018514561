import React from 'react';

class Observation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="card border defaultMarginTop flex between vCenter">
                <div className="defaultMarginRight">
                    <p className="strong">Observações</p>
                    <p className="defaultMarginTop">{this.props.text}</p>
                </div>
            </div>

        );
    }
}

export default Observation;