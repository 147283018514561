import React, { useState, useEffect } from "react";
import ModalTitle from "../ModalTitle";
import TextArea from "../../TextArea";
import Button from "../../Button";
import { putResultLead, postEvent } from "../../../request";
import { toast } from "react-toastify";

function Won({
  pipelineID,
  closeModal,
  leadID,
  status,
  refetch,
  result,
  disabled,
}) {
  const [fields, setFields] = useState({
    observation: "",
  });
  const [loading, setLoading] = useState(false);

  const handlePutResultLead = (fields) => {
    setLoading(true);
    putResultLead({ id: leadID, status: "won", ...fields }, pipelineID)
      .then(() => {
        toast.success(
          "O negócio deu certo, todas as atividades pendentes foram encerradas"
        );
        setLoading(false);
        if (refetch) refetch();
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar o para etapa Ganhou");
        setLoading(false);
      })
      .finally(() => {
        const eventData = {
          type: "lead",
          description: "Lead foi ganha",
          leadID: leadID,
          status: "won",
        };
        postEvent(eventData);
        closeModal();
      });
  };
  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  useEffect(() => {
    if (!status) {
      setFields({
        observation: "",
      });
    }
  }, [status]);
  return (
    <>
      <ModalTitle
        title="Ganhou"
        icon={require("../../../png/thumb_up_cover.png")}
      />
      <TextArea
        label="Observações sobre o ganho do negócio"
        className="fullWidth"
        disabled={disabled}
        isDefault={true}
        value={result?.observation ? result.observation : fields.observation}
        onChange={(value) => handleFields(value, "observation")}
      />
      <div className="fullWidth flex end vCenter defaultMarginTop">
        {result ? null : (
          <Button
            text="Confirmar"
            isLoading={loading}
            paddingHorizontal="20px"
            marginTop="10px"
            onClick={() => handlePutResultLead(fields)}
          />
        )}
      </div>
    </>
  );
}

export default Won;
