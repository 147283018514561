/* eslint-disable */
import React, { useState } from "react";
import Control from "../../../Control";
import CheckRoundedEnabled from "../../../../svg/checkroundedenabled";
import Cancel from "../../../../svg/cancel";
import { getMonthString } from "../../../../script/util";
import {
  ACTIVITY_CANCELED,
  ACTIVITY_COMPLETED,
  ACTIVITY_PENDING,
} from "../../../../utils";

function History({ data }) {
  let { activityTypeID, startTime, endTime, status: activity_status } = data;
  let activityID = data.id || data._id?.valueOf().toString();
  let initialsMonth;

  if (startTime) {
    let month;
    startTime = new Date(startTime);
    month = startTime.getMonth();
    initialsMonth = getMonthString(month).toUpperCase();
  }

  if (endTime) endTime = new Date(endTime);

  // TODO @Denis pegar do informations
  let allTypes = {
    phone: "Telefonar",
    email: "Enviar e-mail",
    whatsapp: "Enviar WhatsApp",
    "in-person": "Reunir-se presencialmente",
    video: "Chamar por vídeo",
  };

  const [status, setStatus] = useState(true);
  return (
    <div className="flex vCenter defaultMarginTop">
      <div className="flex column center defaultMarginRight timelineItem">
        <span className="dayActivity">{startTime && startTime.getDate()}</span>
        <span className="monthActivity">
          {initialsMonth && initialsMonth.substr(0, 3)}
        </span>
      </div>

      <div className="flex nowrap fullWidth activity">
        <div className="flex flexGrow">
          <div className="tdIcon">
            <Control width="25" icon={activityTypeID} />
          </div>
          <div className="flex column smallMarginLeft">
            <div className="flex smallMarginBottom vCenter">
              <h5>{allTypes[activityTypeID]}</h5>
            </div>
            <p className="flex vCenter">
              <span style={{ wordBreak: "break-all" }}>{data.description}</span>
              {/*<span>Cláudia Liz Montreal</span>
                            <span className="bullet smallMarginHorizontal"></span>
                            <span>Softframes</span>*/}
            </p>
          </div>
        </div>
        <div className="flex end activityAction vCenter">
          {activity_status && activity_status == ACTIVITY_COMPLETED ? (
            <CheckRoundedEnabled />
          ) : (
            <Cancel
              active={true}
              onMouseOver={null}
              onMouseLeave={null}
              style={{ cursor: "default" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default History;
