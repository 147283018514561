import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Base from "../../components/Base";
import ProgressBar from "../../components/ProgressBar";
import { getCompanyTeams, getUserMe } from "../../request";
import ConfigurationPageTitle from "../../components/ConfigurationPageTitle";
import Teams from "./Teams";
import NewTeamMember from "../../components/Modal/NewTeamMember";
import Modal from "../../components/Modal";
import { toast } from "react-toastify";

export const TeamsPage = () => {
  const [teams, setTeams] = useState([]);
  const [teamMember, setTeamMember] = useState(null);
  const [newTeamMemberModal, setNewTeamMemberModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [isManager, setIsManager] = useState(true);

  const checkManager = () => {
    getUserMe().then((res) => {
      if (res.data === "manager" || res.data === "admin") setIsManager(false);
    });
  };

  useEffect(() => {
    checkManager();
    listCompanyTeams();
  }, []);

  const listCompanyTeams = () => {
    setIsLoading(true);
    getCompanyTeams()
      .then((res) => {
        setTeams(res.data);
        setIsLoading(false);
        setNewTeamMemberModal(false);
        //console.log(isManager);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar os times da empresa");
        setIsLoading(false);
      });
  };

  const openModal = (teamMember) => {
    setTeamMember(teamMember);
    setNewTeamMemberModal(true);
  };

  return (
    <>
      <Modal
        className="createModal modal-sm"
        active={newTeamMemberModal}
        closeModal={() => setNewTeamMemberModal(false)}
        statusModal={newTeamMemberModal}
        content={
          <NewTeamMember
            status={newTeamMemberModal}
            teamMember={teamMember}
            teams={teams}
            closeModal={() => {
              // setInitialized(false);
              listCompanyTeams(() => setNewTeamMemberModal(false));
            }}
          />
        }
      />

      {
        <ProgressBar
          className="loading"
          loading={isLoading}
          color="#545557"
          height="4px"
          style={{ position: "fixed", top: 0, left: 0 }}
        />
      }

      <ConfigurationPageTitle title="Equipe">
        {isManager ? (
          <Button
            text="Novo Membro"
            className="mainButton2"
            disabled="true"
            onClick={"#"}
          />
        ) : (
          <Button
            text="Novo membro"
            className="mainButton"
            onClick={() => openModal()}
          />
        )}
      </ConfigurationPageTitle>

      <div className="fullWidth flex column">
        <Teams
          teams={teams}
          disabled="true"
          onEdit={openModal}
          isManager={isManager}
        />
      </div>
    </>
  );
};

export default Base(TeamsPage);
