import React from 'react';
import CurrencyInput from 'react-currency-input';
import clsx from 'clsx';

const InputCurrency = ({ isDefault, onChange, className, label, ...rest }) => (
    <>
        {
            isDefault ?
                (
                    <label className={clsx("halfWidth flex column secondaryLabel smallMarginTop", (className && className.indexOf("fullWidth") !== -1) ? "fullWidth" : "halfWidth", (className && className.indexOf("noLimit") !== -1 && "noLimit"))}>
                        {label && (<span>{label}</span>)}
                        <CurrencyInput {...rest}
                            className={clsx("variantInput smallMarginTop", className)}
                            onChange={onChange}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix="R$ " />
                    </label>
                )
                :
                (
                    <label>
                        <CurrencyInput {...rest}
                            className={className}
                            onChange={onChange}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix="R$ " />
                    </label>
                )

        }
    </>
)

// Fix autofocus issues with CurrencyInput
// on iOS it will still auto focus even if autoFocus=false
// see https://github.com/jsillitoe/react-currency-input/issues/90
let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
CurrencyInput.prototype.componentDidMount = function() {
    if(!this.props.autoFocus) {
        let setSelectionRange_super = this.theInput.setSelectionRange;
        this.theInput.setSelectionRange = () => {};
        componentDidMount_super.call(this, ...arguments);
        this.theInput.setSelectionRange = setSelectionRange_super;
    }
    else {
        componentDidMount_super.call(this, ...arguments);
    }
};
let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
CurrencyInput.prototype.componentDidUpdate = function() {
    if(!this.props.autoFocus) {
        let setSelectionRange_super = this.theInput.setSelectionRange;
        this.theInput.setSelectionRange = () => {};
        componentDidUpdate_super.call(this, ...arguments);
        this.theInput.setSelectionRange = setSelectionRange_super;
    }
    else {
        componentDidMount_super.call(this, ...arguments);
    }
};

export default InputCurrency;