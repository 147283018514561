import React from 'react';
import Phone from '../../svg/phone';
import Mail from '../../svg/mail';
import Whatsapp from '../../svg/whatsapp';
import AccountMultiple from '../../svg/accountmultiple';
import Cam from '../../svg/cam';
import Note from '../../svg/note';
import ReactTooltip from "react-tooltip";

function Control({ icon, className, isFilled, ...props }) {

    const allControls = {
        whatsapp: <Whatsapp width="19" fill="#545557" />,
        phone: <Phone width="19" fill="#545557" />,
        email: <Mail width="22" fill="#545557" />,
        "in-person": <AccountMultiple data-tip="Reunião" width="17" fill="#545557" />,
        video: <Cam width="22" fill="#545557" />,
        note: <Note width="22" fill="#545557" />
    }

    return (
        <>
            <ReactTooltip style={{display: 'none'}} />
            {allControls[icon]}
        </>
    );

}

export default Control;