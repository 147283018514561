/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "../../components/Button";
import Activity from "./Activity";
import Subtitle from "../../components/Subtitle";
import clsx from "clsx";
import Spinner from "../../svg/spinner";

function Activities({ title, data = [], isHistory, className, loading }) {
  const [active, setActive] = useState(false);

  return (
    <>
      <div className={clsx("fullWidth activityGroup", className)}>
        <div className="flex between defaultMarginBottom">
          <Subtitle
            title={title}
            quantity={data.length}
            className="defaultMarginBottom"
          />
          {data.length > 3 && (
            <Button
              text={active ? "Ver menos" : "Ver todos"}
              className="seeAll"
              onClick={() => {
                setActive(!active);
              }}
            />
          )}
        </div>

        <div className={clsx("flex column fullWidth", isHistory && "timeline")}>
          {!loading ? (
            data.length ? (
              data
                .map((item) => (
                  <Activity key={item.id} data={item} isHistory={isHistory} />
                ))
                .filter((_, index) => (!active ? index < 3 : true))
            ) : (
              <p className="strong textCenter smallMarginTop defaultPaddingTop emptyContent">
                Nenhuma atividade encontrada
              </p>
            )
          ) : (
            <div className="fullWidth flex center">
              <Spinner fill="#000" className="smallMarginRight" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Activities;
