import jwtDecode from "jwt-decode";
import { refreshToken } from "../request";

export const ROLE_ADMIN = "admin";
export const ROLE_MANAGER = "manager";
export const ROLE_SELLER = "seller";
export const ROLE_PRE_SELLER = "pre-seller";
export const ACTIVITY_PENDING = "pending";
export const ACTIVITY_CANCELED = "canceled";
export const ACTIVITY_COMPLETED = "completed";
export const ACTIVITY_CLOSED = "closed";
export const STAGE_WON = "won";
export const STAGE_LOST = "lost";
export const STAGE_PRE_SELLER = "pre-seller";
export const STAGE_SELLER = "seller";

export const payloadBusinessValidation = (rest) => {
  const revalidator = require("revalidator");
  return revalidator.validate(rest, {
    properties: {
      stageID: {
        message: "É obrigatório selecionar uma etapa do funil",
        type: "string",
        required: true,
        allowEmpty: false,
      },
      pipelineID: {
        message: "Problema com o envio das informações. Recarregue a tela",
        type: "string",
      },
      customerCompanyID: {
        message: "É obrigatório selecionar uma empresa do funil",
        type: "string",
        required: false,
        allowEmpty: true,
      },
      customerCompany: {
        message: "A Empresa é obrigatória",
        type: "object",
        required: true,
        allowEmpty: false,
        properties: {
          companyName: {
            message: "A Empresa é obrigatória",
            type: "string",
            required: true,
            allowEmpty: false,
          },
        },
      },
      name: {
        message: "O Nome do negócio é obrigatório",
        type: "string",
        required: true,
        allowEmpty: false,
      },
    },
  });
};

export const tokenInfo = (token) => {
  try {
    if (token) {
      return jwtDecode(token);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const isTokenExpired = (token) => {
  const decoded = tokenInfo(token);
  if (decoded) {
    const exp = decoded.exp;
    return Date.now() > (exp + 1) * 1000;
  } else {
    return true;
  }
};

export const revalidateToken = async () => {
  if (isTokenExpired(window.localStorage.getItem("token"))) {
    return refreshToken().then(({ data }) => {
      window.localStorage.setItem("token", data.token);
      return true;
    });
  } else {
    return true;
  }
};

export const payloadActivityValidation = (rest) => {
  const revalidator = require("revalidator");
  if (rest.activityTypeID == "note") {
    return revalidator.validate(rest, {
      properties: {
        date: {
          message: "A Data é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        leadID: {
          message: "A leadID é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        description: {
          message: "A Atividade é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        activityTypeID: {
          message: "Você deve selecionar de que forma irá entrar em contato",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        startTime: {
          message: "A Hora de início é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        endTime: {
          message: "A Hora de fim é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
  } else {
    return revalidator.validate(rest, {
      properties: {
        date: {
          message: "A Data é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        leadID: {
          message: "A leadID é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        description: {
          message: "A Atividade é obrigatória",
          type: "string",
          required: false,
          allowEmpty: true,
        },
        activityTypeID: {
          message: "Você deve selecionar de que forma irá entrar em contato",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        startTime: {
          message: "A Hora de início é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        endTime: {
          message: "A Hora de fim é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
  }
};
