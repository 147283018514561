import React, { useState, useEffect } from "react";
import { listCustomerContacts, listCustomerCompanies } from "../../request";
import Base from "../../components/Base";
import Table from "../../components/Table";
import Select from "../../components/Select";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import NewContact from "../../components/Modal/NewContact";
import mountTable from "./functions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";

const ContactsPage = () => {
  useRefreshToken();
  const history = useHistory();
  const [atualFilter, setAtualFilter] = useState({ id: "all" });
  const [filterValues] = useState([
    { id: "all", label: "Todos" },
    { id: "active", label: "Ativos" },
    { id: "disabled", label: "Inativos" },
  ]);
  const [tbody, setTbody] = useState([]);
  const [res, setRes] = useState([]);
  const [atualSort, setAtualSort] = useState("lowerDate");
  const [initialized, setInitialized] = useState(null);
  const [newContactModal, setNewContactModal] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [customerCompanyID, setCustomerCompanyID] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");

  const listContact = () => {
    setNewContactModal(false);
    listCustomerContacts()
      .then(({ data }) => {
        setRes(data);
        setTbody(
          mountTable({ atualFilter, atualSort: atualSort, data }, () =>
            setInitialized(true)
          )
        );
        listCompanies();
      })
      .catch((error) => {
        console.error("Erro: ", error.message, " Status: ", error.code);
        //toast.error('Não foi possível carregar os contatos');
      });
  };

  const listCompanies = () => {
    listCustomerCompanies()
      .then(({ data }) => {
        if (data.length) {
          setCompaniesOptions(data);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as empresas");
      });
  };

  useEffect(() => {
    listContact();
  }, []);

  useEffect(() => {
    setTbody(mountTable({ atualFilter, atualSort: atualSort, data: res }));
  }, [atualFilter, atualSort]);

  const handleEdit = (id) => {
    history.push(`/contatos/${id}`);
  };

  return (
    <>
      <Modal
        className="newContactModal modal-sm"
        active={newContactModal}
        closeModal={() => {
          setNewContactModal(false);
        }}
        statusModal={newContactModal}
        content={
          <NewContact
            inputValue={customerCompanyID}
            setInputValue={setCustomerCompanyID}
            customerCompanyID={customerCompanyID}
            setCustomerCompanyID={setCustomerCompanyID}
            customerCompanyName={customerCompanyName}
            setCustomerCompanyName={setCustomerCompanyName}
            statusModal={newContactModal}
            closeModal={() => {
              setTbody(null);
              setInitialized(false);
              listContact(() => setNewContactModal(false));
            }}
            companiesOptions={companiesOptions}
          />
        }
      />

      <div className="fullWidth flex between titleContent">
        <Title text="Contatos" />
      </div>
      <div className="flex between fullWidth">
        <div className="flex flexGrow">
          {/* <Select placeholder="Status" style={{ maxWidth: "150px" }} property="filter" value={atualFilter} onChange={(value) => setAtualFilter({ value })} options={filterValues} /> */}

          <Select
            placeholder="Ordenação"
            style={{ maxWidth: "150px" }}
            property="filter2"
            onChange={(value) => setAtualSort(value)}
            options={[
              { id: "lowerDate", label: "Mais recentes" },
              { id: "upperDate", label: "Menos recentes" },
            ]}
          />
        </div>
        <Button
          text="Novo contato"
          paddingHorizontal="25px"
          onClick={() => setNewContactModal(true)}
        />
      </div>

      <Table
        thead={[
          { name: "Nome", id: "name" },
          { name: "Empresa", id: "companyName" },
          { name: "Cargo", id: "position" },
          { name: "Último contato", id: "lastcontact" },
          { name: "Contatos", id: "contact" },
        ]}
        tbody={tbody}
        handleEdit={(value) => handleEdit(value)}
        initialized={initialized}
      />
    </>
  );
};

export default Base(ContactsPage);
