import React, { useEffect, useState } from "react";
import { login, getUserProfile } from "../../request";
import Header from "../../components/Header";
import logo from "../../png/logo.png";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useHistory, Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const HomePage = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [refToken, setRefToken] = useState(
    localStorage.getItem("refreshToken") || ""
  );
  const [logged, setLogged] = useState(token && refToken ? true : false);

  const history = useHistory();

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      signIn(email, pass);
    }
  };

  const signIn = async (email, password) => {
    if (email && password) {
      return login({ email, password })
        .then(({ data }) => {
          setToken(data.token);
          setRefToken(data.refreshToken);
          setLogged(true);
        })
        .catch((error) => {
          const errMsg = error.response
            ? error.response.data
            : "Não foi possível autenticar";
          console.error(
            error
          );
          toast.error(errMsg);
          return false;
        });
      // console.log('Token:', token);
    } else {
      toast.warning("Os campos E-mail e Senha são obrigatórios");
      return false;
    }
  };

  useEffect(() => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refToken);
    localStorage.setItem("isAuthenticatedWithEmail", "true");

    getUserProfile().then((user) => {
      localStorage.setItem("userImageUrl", user.data.photoURL || "");
      localStorage.setItem("userName", user.data.name || "");
    });

    // setTimeout(() => {
    //   window.location.reload(true);
    // }, 700);
    if (logged) {
      window.location.href = "/dashboard";
    }
  }, [token, refToken, logged]);

  return (
    <div className="backgroundGradient loginScreen">
      <Header></Header>
      <main className="flex center">
        <div className="flex center column card">
          <figure className="smallMarginBottom ">
            <img className="logo" src={logo} alt="" />
          </figure>
          <hr className="defaultMarginTop" />
          <form onKeyDown={handleOnKeyDown}>
            <Input
              type="text"
              placeholder="E-mail"
              property="email"
              onChange={setEmail}
            />
            <Input
              type="password"
              placeholder="Senha"
              property="pass"
              onChange={setPass}
            />
          </form>
          <Button
            text="Entrar"
            className="smallMarginTop fullWidth"
            onClick={() => signIn(email, pass)}
          />
          <div className="flex around vCenter fullWidth smallMarginTop cardActions">
            <Link to="/criar-conta">
              <span className="pointer">Criar uma conta</span>
            </Link>
            <div className="dot"></div>
            <Link to="/recuperar-senha">
              <span className="pointer">Esqueci minha senha</span>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
