import React from 'react';
import Router from '../../router/Router';

function Content() {

    return (
        <>
            <Router />
        </>
    )
}

export default Content;

