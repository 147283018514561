export const functionalities = [
  {
    label: `Release de 28 de Novembro de 2022`,
    funcionalidades: [
      " * Detalhes da Lead agora são mostrados em um Modal carregando mais rápido ",
      " * Em Detalhes agora leads podem ser transicionadas pelos botoes superiores mesmo quando perdidas",
      "BUGFIXES ",
      `-Correção do BUG de não carregar a pagina negocios apos alterar uma lead`,
      `-Correção do BUG de alerta de token invalido`,
      `-Correção do BUG de bloqueio de permissão ao deletar leads`,
    ],
  },
  {
    label: `Release de 07 de Novembro de 2022`,
    funcionalidades: [
      " * Dashboard com informações de negócio com gráficos interativos e dados disponibilizados para visualização mês a mês ",
      " * Nova página de Releases refatorada",
      " * Atualização da Página de negócios com cards mostrando valores Únicos e Recorrentes",
      "BUGFIXES ",
      `-Correção do BUG do erro de salvamento no comentário de "Não Qualificou"`,
    ],
  },
  {
    label: `Release de 31 de Outubro de 2022`,
    funcionalidades: [
      " * Migração do Banco dados",
      " * Geração automática senha com envio de email para novos usuários",
      " * Nova API com melhoria no tempo de resposta",
      "BUGFIXES",
      "-Correção de bugs de utilização na pagina de negócios",
    ],
  },
  {
    label: `Release de 6 de Abril de 2022`,
    funcionalidades: [
      " * Aviso ao salvar informações",
      " * Leads perdidas podem ser recuperadas de devolvidas a uma etapa anterior do fluxo",
      " * Botões de formas de contato do na página de contatos dos clientes agora são interativos",
      " * Nova página com informações de correções e novas funcionalidades",
      " * Cards agora podem migrar entre os funís",
      " * Funis com atividades pendentes agora tem bloqueio de exclusão",
      " * Questões de qualificação agora permitem edição das respostas",
      " * Apenas o gestores tem permissão para exclusão de funís",
      " * Questionário de qualificação agora permite adicionar perguntas em qualquer posição",
      " * Nova funcionalidade de seleção de Funil principal",
      " * Filtros novos na página de negócios, permitindo selecionar leads por responsávél e por cidade",
      " * Cards perdidos a mais de 90 dias saem do funil de 'Perdidos' autimaticamente.",
      "BUGFIXES:",
      "-Correção na associação de contatos",
      "-Spin de carregamento corrigido",
      "-Correção do bug de motivo de exclusão",
      "-Correção do bug de transição de responsabilidade de lead",
      "-Correção do bug ao usar concluir atividade, onde informação não salvas eram perdidas",
      "-Correção do crash por 'drag n' drop' ao arrastar cards.",
      "-Correção na quebra de linha que deixava texto do lado de fora do card",
    ],
  },
];
