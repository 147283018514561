import React from 'react';

function ArrowLeft({ ...props }) {

    return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17.385 30.385"  {...props}>
                    <g transform="translate(-437.393 -434.808)" className="arrowColor">
                        <path className="a" d="M-1.414,0H2V21.172H-1.414Z" transform="translate(438.807 451.193) rotate(-135)"/>
                        <path className="a" d="M0,0H3.414V21.172H0Z" transform="translate(437.393 450.222) rotate(-45)"/>
                    </g>
                </svg>
    );

}


export default ArrowLeft;