import React from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';

const Base = (Component) => {
    return ({ hasBase, ...rest }) => {
        return (hasBase ?
            <>
                <Header></Header>
                <main className="flex">
                    <Sidebar></Sidebar>
                    <section className="flexGrow flex column">
                        <Component {...rest} />
                    </section>
                </main>
            </> :
            <Component {...rest} />
        )
    }
}


export default Base;