import React from 'react';
import Base from '../../components/Base';
import Title from '../../components/Title'
import Entities from '../SearchResultPage/Entities'
import { useLocation } from 'react-router-dom';

 const SearchResultPage = () => {
  const location = useLocation();
  return (
    <>
      <div className="fullWidth flex between titleContent">
        <Title text="Resultados" />
      </div>

      <div className="fullWidth flex column">
        <Entities entities={location.state.entities} />
      </div>
    </>
  )
}

export default Base(SearchResultPage);