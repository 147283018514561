import React, { useState } from 'react';
import Chip from '../../../../Chip';
import Input from '../../../../InputHook';

function SuggestedActivitie({ index, length, handleStage, onDragStart, draggable, ...chip }) {

    const [duration, setDuration] = useState(chip.duration);
    const [cadence, setCadence] = useState(chip.cadence);

    const handleActivity = (value, property) => {
        let auxPrev = { ...chip, [property]: value };
        handleStage(auxPrev, 'suggestedActivities', index);
    }

    return (
        <>
            <Chip draggable={draggable} onDragStart={onDragStart} type={chip.id} duration={
                <Input value={duration} onChange={setDuration} withoutMargin={true} onBlur={() => { handleActivity(duration, 'duration') }}
                    inputStyle={{ width: "100%", maxWidth: 60, height: 22, background: "transparent", borderColor: "#fff", color: "#fff", textAlign: "center" }} />
            } />

            {length !== index + 1 && <Chip className="smallMarginVertical" variant="simple" cadence={
                <Input value={cadence || "1h"} onChange={setCadence} classNameLabel="flex center" withoutMargin={true} onBlur={() => { handleActivity(cadence, 'cadence') }}
                    inputStyle={{ width: "100%", maxWidth: 60, height: 22, background: "transparent", borderColor: "#00a19a", color: "#00a19a", textAlign: "center" }} />
            } />}
        </>
    )
}

export default SuggestedActivitie;

