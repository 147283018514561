import React, { Fragment } from "react";
import Entity from "../Entity";
import Hr from "../../../components/Hr";

const Entities = (location) => {
  return (
    <>
      <div className="flexGrow card team defaultMarginTop">
        <h3>Negócios</h3>
        <div className="contentCard flex column defaultMarginTop">
          {location.entities.leads && location.entities.leads.length > 0 ? (
            location.entities.leads.map((lead, idx, { length }) => (
              <Fragment key={lead.id || lead._id}>
                <Entity id={lead.id || lead._id} name={lead.name} type="lead" />
                {idx < length - 1 && (
                  <Hr className="noLimit defaultMarginVertical" />
                )}
              </Fragment>
            ))
          ) : (
            <span>Negócios não encontrados </span>
          )}
        </div>
      </div>

      <div className="flexGrow card team defaultMarginTop">
        <h3>Contatos</h3>
        <div className="contentCard flex column defaultMarginTop">
          {location.entities.customerContacts &&
          location.entities.customerContacts.length > 0 ? (
            location.entities.customerContacts.map(
              (customerContact, idx, { length }) => (
                <Fragment key={customerContact.id || customerContact._id}>
                  <Entity
                    id={customerContact.id || customerContact._id}
                    name={customerContact.name}
                    type="contact"
                  />
                  {idx < length - 1 && (
                    <Hr className="noLimit defaultMarginVertical" />
                  )}
                </Fragment>
              )
            )
          ) : (
            <span>Contatos não encontrados</span>
          )}
        </div>
      </div>

      <div className="flexGrow card team defaultMarginTop">
        <h3>Empresas</h3>
        <div className="contentCard flex column defaultMarginTop">
          {location.entities.customerCompanies &&
          location.entities.customerCompanies.length > 0 ? (
            location.entities.customerCompanies.map(
              (customerCompany, idx, { length }) => (
                <Fragment key={customerCompany.id || customerCompany._id}>
                  <Entity
                    id={customerCompany.id || customerCompany._id}
                    name={customerCompany.companyName}
                    type="company"
                  />
                  {idx < length - 1 && (
                    <Hr className="noLimit defaultMarginVertical" />
                  )}
                </Fragment>
              )
            )
          ) : (
            <span>Empresas não encontradas</span>
          )}
        </div>
      </div>
    </>
  );
};

export default Entities;
