import React from 'react';

function Whatsapp({ width = "19.21", height = "19.2", ...props }) {
    return (
        <svg data-tip="Whatsapp" xmlns="http://www.w3.org/2000/svg" {...props} width={width} height={height} viewBox="0 0 19.21 19.2">
            <path d="M203.439-130.316a9.227,9.227,0,0,1-2.143,6.171,9.214,9.214,0,0,1-5.9,3.357,9.436,9.436,0,0,1-5.508-.826.559.559,0,0,0-.411-.033q-2.3.607-4.61,1.2a2.88,2.88,0,0,1-.294.06.267.267,0,0,1-.324-.351q.249-.827.519-1.648.48-1.459.972-2.914a.267.267,0,0,0-.018-.227,10.873,10.873,0,0,1-.944-2.471,9.807,9.807,0,0,1-.228-2.479,9.432,9.432,0,0,1,4.618-7.776,9.174,9.174,0,0,1,4.151-1.309,9.336,9.336,0,0,1,5.843,1.524,9.362,9.362,0,0,1,4.087,5.961A7.089,7.089,0,0,1,203.439-130.316Zm-6.456,6.116c.187-.018.376-.026.56-.059a2.351,2.351,0,0,0,.437-.127,4.979,4.979,0,0,0,2.08-1.687.96.96,0,0,0,.16-.407,1.267,1.267,0,0,0-.356-1.1,6.334,6.334,0,0,0-1.389-1.192,4.241,4.241,0,0,0-.765-.418.849.849,0,0,0-1.023.246,4.612,4.612,0,0,1-.311.35,4.179,4.179,0,0,1-.517.459.689.689,0,0,1-.863-.074c-.446-.43-.908-.846-1.346-1.285-.542-.544-1.071-1.1-1.6-1.662a.629.629,0,0,1-.114-.715,1.315,1.315,0,0,1,.347-.5c.172-.15.342-.3.514-.452a.83.83,0,0,0,.306-.769,1.4,1.4,0,0,0-.145-.477,6.7,6.7,0,0,0-1.466-1.886,1.936,1.936,0,0,0-.467-.311,1.085,1.085,0,0,0-1.214.154c-.214.18-.414.377-.612.575a3.933,3.933,0,0,0-.959,1.408,3.1,3.1,0,0,0,.078,2.3,6.744,6.744,0,0,0,1.52,2.227c1.127,1.143,2.273,2.266,3.407,3.4A8.452,8.452,0,0,0,195-124.794,4.017,4.017,0,0,0,196.983-124.2Z" transform="translate(-184.231 139.581)" />
        </svg>
    );
}

export default Whatsapp;