import React from 'react';
import clsx from 'clsx';

function Subtitle({ title, quantity, className, marginBottom }) {

    const style = Object.assign({}, marginBottom && { marginBottom });

    return (
        <h4 className={clsx(className)} style={style}>{`${title} ${quantity ? `(${quantity})` : ""}`}</h4>
    );

}

export default Subtitle;