import React from "react";
import clsx from "clsx";
import Close from "../../svg/close";

const Modal = ({
  active,
  className,
  closeModal,
  content,
  children,
  disableBackgroundClosing,
}) => {
  return (
    <div
      className={clsx(
        "contentModal flex center absolute fullWidth fullScreenHeight",
        active && "active"
      )}
      onClick={(e) => {
        if (
          !disableBackgroundClosing &&
          closeModal &&
          active &&
          e?.target?.className.length &&
          e.target.className.includes("contentModal")
        ) {
          closeModal();
        }
      }}
    >
      <div className={clsx("modal card auto", className && className)}>
        {closeModal && (
          <div className="flex end">
            <Close
              onClick={() => {
                closeModal();
              }}
              className="pointer"
            />
          </div>
        )}
        {content || children}
      </div>
    </div>
  );
};

export default Modal;
