import React from "react";
import Modal from "..";
import BusinessDetailPage from "../../../pages/BusinessDetailPage";

const BusinessDetailModal = ({
  businessPageModal,
  setBusinessPageModal,
  leadID,
}) => {
  return (
    <>
      <Modal
        active={businessPageModal}
        closeModal={() => {
          setBusinessPageModal(false);
          //window.location.reload();
        }}
        content={<BusinessDetailPage leadID={leadID} />}
      />
    </>
  );
};

export default BusinessDetailModal;
