import React, { useState, useEffect } from 'react';
import Hr from '../../../components/Hr'
import Input from '../../../components/InputHook';
import Edit from '../../../svg/edit';
import Save from '../../../png/save-icon-small.png';

const ResponseOptions = ({ options = [], key, title, handleFields }) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [fields, setFields] = useState()
    const [displaySave, setDisplaySave] = useState(true);

    const setIsDisabledAndSetDisplaySave = () => {
        setIsDisabled(!isDisabled);
        setDisplaySave(!displaySave);
    }

    const handleCustomField = (value) => {
        let aux = value.split(',').map(label => ({ label: label.trim() })).filter(item => item.label !== "");
        handleFields(aux);
    }

    useEffect(() => {
        setFields(
            options.map(item => item.label).join(", ")
        );
    }, []);

    useEffect(() => {
        // setIsDisabled(!isDisabled)
    }, [!isDisabled]);

    return (
        <div className="contentCard flex defaultMarginTop reverseEnd">
            <div className="flexGrow flex vCenter halfWidth">
                <img className="defaultMarginRight" src={require('../../../png/graph.png')} />
                <span className="defaultMarginLeft defaultMarginBottom stronger">{title}</span>
            </div>
            <Hr className="noLimit verticalHr defaultMarginHorizontal" style={{ height: "40px" }} />
            <div className="flexGrow flex fullHeight halfWidth" >
                {
                    isDisabled ? <label className="flexGrow flex vCenter secondaryLabel" style={{ height: "40px", fontWeight: "400", fontSize: "1rem" }} > {fields}</label> :
                        <Input withoutMargin={true} value={fields} onChange={setFields} onBlur={() => handleCustomField(fields)} isDefault={true} className="flexGrow" />
                }
                {/* <Edit className="centerSelf pointer flexShrinkNone" onClick={() => { setIsDisabled(!isDisabled) }} /> */}
                <button onClick={() => { setIsDisabledAndSetDisplaySave() }}>
                    {displaySave && <Edit className="centerSelf pointer" />}
                    {!displaySave && (
                        <img src={Save} alt="save" style={{ height: "20px" }} />
                    )}
                </button>
            </div>
        </div>
    )
};

export default ResponseOptions;

