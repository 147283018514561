import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../config";
import { recover } from "../../request/index";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Input from "../../components/Input";
import logo from "../../png/logo.png";
import { toast } from "react-toastify";

const CreateNewPasswordPage = () => {
  const history = useHistory();
  const [pass, setPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [recToken, setRecToken] = useState("");

  useEffect(() => {
    setRecToken(
      history.location.search.split("&").reduce((total, item) => {
        if (item.indexOf("token=") !== -1) {
          return item.split("=")[1];
        } else {
          return total;
        }
      })
    );
  }, [history.location.search]);

  function isTokenExpired(token) {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = Buffer.from(payloadBase64, "base64").toString();
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    return Date.now() >= exp * 1000;
  }

  const createNewPassword = (recToken, pass, repeatPass) => {
    if (isTokenExpired(recToken)) {
      toast.error("Link de recuperação de senha expirado");
    } else {
      if (pass && repeatPass) {
        if (pass === repeatPass) {
          recover(pass, recToken)
            .then(() => history.push("/"))
            .catch((error) => {
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível atualizar a senha");
            });
        } else {
          toast.warning("Os campos devem ser iguais");
        }
      } else {
        toast.warning(
          "Os campos Nova senha e Repetir nova senha são obrigatórios"
        );
      }
    }
  };

  return (
    <div className="backgroundGradient createNewPasswordScreen">
      <Header></Header>
      <main className="flex center">
        <div className="flex center column card">
          <div className="flex center fullWidth">
            <figure className="smallMarginBottom ">
              <img className="logo" alt="" src={logo} />
              {/*TODO: Adicionar alt na imagem */}
            </figure>
          </div>
          <hr className="smallMarginTop defaultMarginBottom" />
          <div className="defaultMarginTop textCenter successMessage">
            <p style={{ fontWeight: 500 }}>Crie sua nova senha.</p>
          </div>
          <Input
            type="password"
            placeholder="Nova senha"
            value={pass}
            onChange={setPass}
          />
          <Input
            type="password"
            placeholder="Repetir nova senha"
            value={repeatPass}
            onChange={setRepeatPass}
          />
          <Button
            text="Criar nova senha"
            className="fullWidth defaultMarginTop"
            onClick={() => createNewPassword(recToken, pass, repeatPass)}
          ></Button>
        </div>
      </main>
    </div>
  );
};

export default CreateNewPasswordPage;
