import { combineReducers } from 'redux';
import changeMenu from './changemenu';
import role from './role';
import status from './status';
import activities from './activities';
import qualifications from './qualifications';
import stages from './stages';

const rootReducer = combineReducers({
    changemenu: changeMenu,
    role,
    status,
    activities,
    qualifications,
    stages,
});

export default rootReducer;
