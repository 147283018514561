import React, { useState } from 'react';

function CheckRounded({ width = 23, height = 23, active = false, ...props }) {

  const [status, setStatus] = useState(active);

  return (
    <div onMouseEnter={() => setStatus((prev) => !prev)} onMouseLeave={() => setStatus((prev) => !prev)}>
      <svg className="flex center defaultMarginLeft pointer" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28"  {...props}>
        <g transform="translate(-1598 -241)">
          {
            status ?
              (<g transform="translate(1600 243)">
                <circle cx="12" cy="12" r="12" fill="#fff" stroke="#00a19a" strokeWidth="4px" />
              </g>) :
              (
                <g fill="#d1d1d1" stroke="#d1d1d1" strokeWidth="2px" transform="translate(1600 243)">
                  <circle stroke="none" cx="12" cy="12" r="12" />
                  <circle fill="none" cx="12" cy="12" r="13" />
                </g>
              )
          }
          <path fill={status ? "#00a19a" : "#fff"} d="M14,2A12,12,0,1,0,26,14,12,12,0,0,0,14,2ZM11.6,20l-6-6,1.692-1.692L11.6,16.6,19.908,8.3,21.6,10Z" transform="translate(1598 241)" />
        </g>
      </svg>
    </div>
  )
}


export default CheckRounded;