import React from 'react';

function trash({ width, height, fill, ...rest }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width || "18.667"} height={height || "24"} viewBox="0 0 18.667 24" {...rest}>
            <path fill={fill || "#545557"} d="M6.333,24.333A2.675,2.675,0,0,0,9,27H19.667a2.675,2.675,0,0,0,2.667-2.667v-16h-16Zm17.333-20H19L17.667,3H11L9.667,4.333H5V7H23.667Z" transform="translate(-5 -3)" />
        </svg>

    )
}

export default trash;

