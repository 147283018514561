import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dateToMili, currencyToNumber, removeEmptyKeys } from "../../../script";
import Input from "../../InputHook";
import TextArea from "../../TextArea";
import Button from "../../Button";
import Select from "../../Select";
import StageHeader from "../../Stage/StageHeader";
import {
  getAllStagesByPipelineID,
  getInformationsByType,
  postLead,
  listMembersByTeamPosition,
  postEvent,
} from "../../../request";
import InputMasked from "../../InputMaskedHook";
import InputCurrency from "../../InputCurrency";
import Spinner from "../../../svg/spinner";
import {
  ROLE_MANAGER,
  payloadBusinessValidation,
  STAGE_PRE_SELLER,
  STAGE_SELLER,
} from "../../../utils";
import { toast } from "react-toastify";
import AutoComplete from "../../AutoComplete";

function NewBusiness({
  pipelineID,
  closeModal,
  status,
  isBusinessPage,
  companiesOptions,
  customerCompanyID,
  setCustomerCompanyID,
  customerCompanyName,
  setCustomerCompanyName,
  segment,
  setSegment,
  site,
  setSite,
  email,
  setEmail,
  phoneMain,
  setPhoneMain,
  socialNetwork,
  setSocialNetwork,
  contactsOptions,
  customerContactID,
  setCustomerContactID,
  customerContactName,
  setCustomerContactName,
  contactEmail,
  setContactEmail,
  contactPhoneMain,
  setContactPhoneMain,
  contactPhoneSecondary,
  setContactPhoneSecondary,
  contactWhatsApp,
  setContactWhatsApp,
  contactPosition,
  setContactPosition,
}) {
  const dispatch = useDispatch();
  const currentStageID = useSelector(
    (state) =>
      state.stages.current.id || state.stages.current._id?.valueOf().toString()
  );
  const [fields, setFields] = useState({
    userUID: "",
    pipelineID,
    memberProfileID: "",
    name: "",
    observation: "",
    leadOrigin: "",
    pain: "",
    uniqueValue: "",
    recurringValue: "",
    expectedDate: "",
    customField: "",
    customerCompany: {},
    customerContact: {},
  });

  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [sourcesOptions, setSourcesOptions] = useState([]);
  const [painOptions, setPainOptions] = useState([]);
  const [stages, setStages] = useState([]);
  const [stageID, setStageID] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isCompanyDisabled, setIsCompanyDisabled] = useState(false); //NOTE: Será importante quando existir o auto complete
  const [isContactDisabled, setIsContactDisabled] = useState(false); //NOTE: Será importante quando existir o auto complete
  const [isDisabled, setIsDisabled] = useState(false); //NOTE: Será importante quando existir o auto complete
  const [customFieldLabel, setCustomFieldLabel] = useState("");
  const [loadingPostLead, setLoadingPostLead] = useState(false);
  const [inputValueCompanies, setInputValueCompanies] = useState("");
  const [inputValueContacts, setInputValueContacts] = useState("");
  const handleCommonFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  const handleCompany = (value, property) => {
    let customerCompany = { ...fields.customerCompany };
    customerCompany[property] = value;
    setFields({ ...fields, customerCompany });
  };

  const handleContact = (value, property) => {
    let customerContact = { ...fields.customerContact };
    customerContact[property] = value;
    setFields({ ...fields, customerContact });
  };

  const resetFields = () => {
    setStageID("");
    setFields({
      stageID: "",
      pipelineID,
      currentStageID: "",
      memberProfileID: "",
      name: "",
      observation: "",
      leadOrigin: "",
      pain: "",
      uniqueValue: "",
      recurringValue: "",
      expectedDate: "",
      customField: "",
    });
    setCustomerCompanyName("");
    setCustomerCompanyID("");
    setCustomerContactName("");
    setCustomerContactID("");
    setSegment("");
    setSite("");
    setEmail("");
    setPhoneMain("");
    setSocialNetwork("");
    setContactEmail("");
    setContactPhoneMain("");
    setContactPhoneSecondary("");
    setContactWhatsApp("");
    setContactPosition("");
  };

  const handlePostLead = ({
    expectedDate,
    uniqueValue,
    recurringValue,
    ...rest
  }) => {
    setLoadingPostLead(true);
    rest.stageID = stageID || currentStageID;
    rest.customerCompanyID = customerCompanyID;
    rest.customerContactID = customerContactID;

    if (customerCompanyName != "") {
      rest.customerCompany = {
        companyName: customerCompanyName,
        segment: segment,
        site: site,
        email: email,
        phoneMain: phoneMain,
        socialNetwork: socialNetwork,
      };
    }

    const customerContactNameisValid = (customerName) => {
      return (
        customerName != "" &&
        !customerName.split("").every((char) => char === " ")
      );
    };

    if (customerContactNameisValid(customerContactName)) {
      rest.customerContact = {
        name: customerContactName,
        email: contactEmail,
        phoneMain: contactPhoneMain,
        phoneSecondary: contactPhoneSecondary,
        whatsApp: contactWhatsApp,
        position: contactPosition,
      };
    }

    const isValid = payloadBusinessValidation(rest);
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setLoadingPostLead(false);
      return;
    }
    let payload = {
      expectedDate: dateToMili(expectedDate),
      uniqueValue: currencyToNumber(uniqueValue),
      recurringValue: currencyToNumber(recurringValue),
      stageID: stageID || currentStageID,
      ...rest,
    };
    payload = removeEmptyKeys(payload);

    postLead(payload)
      .then(() => {
        toast.success("Negócio cadastrado com sucesso");

        resetFields();
        setLoadingPostLead(false);
        closeModal();
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível salvar o negócio");
        setLoadingPostLead(false);
        return;
      });
  };

  useEffect(() => {
    resetFields();
    if (!status) {
      dispatch({ type: "RESET_CURRENT_STAGES" });
      if (currentStageID) {
        selectStage(currentStageID);
      }
    } else {
      getAllStagesByPipelineID(pipelineID)
        .then(({ data }) => {
          setStages(data);
          /*NOTE: seta o primeiro stage como default */
          if (!currentStageID) {
            let auxCurrentStage = data
              .sort((a, b) => a.order - b.order)
              .reduce((total, item, index) => {
                if (!index) return item;
                return total;
              }, "");
            selectStage(auxCurrentStage);
          }
          let auxCurrentStage = data
            .sort((a, b) => a.order - b.order)
            .reduce((total, item, index) => {
              if (
                (item.id || item._id?.valueOf().toString()) === currentStageID
              )
                return item;
              return total;
            }, "");
          selectStage(auxCurrentStage);
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível buscar as etapas do funil selecionado");
        });

      getInformationsByType("SEGMENTS", "LEAD_SOURCES", "PAIN_POINTS")
        .then((res) => {
          const informations = res.data[0];
          setSegmentsOptions(informations.SEGMENTS.data);
          setSourcesOptions(informations.LEAD_SOURCES.data);
          setPainOptions(informations.PAIN_POINTS.data);
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error(
            "Não foi possível carregar algumas informações referentes ao negócio"
          );
        });
    }
  }, [status]);

  useEffect(() => {
    let label = JSON.parse(localStorage.getItem("customFields"))?.reduce(
      (field, { key, label }) => {
        if (key === "LEADS") return label;
        return field;
      },
      ""
    );
    if (label) setCustomFieldLabel(label);
  }, []);

  function selectStage(selectedStage) {
    if (selectedStage) {
      if (selectedStage.step !== "won" && selectedStage.step !== "lost") {
        listMembersByTeamPosition(selectedStage.step, ROLE_MANAGER)
          .then(({ data }) => {
            setTeamMembers(data);
          })
          .catch((error) => {
            if (error.response?.data === "auth/id-token-expired") {
              toast.error(
                "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
              );
              return;
            }
            console.error("Erro: ", error.message, " Status: ", error.code);
            toast.error("Não foi possível obter a lista de responsáveis");
          });
      } else {
        listMembersByTeamPosition(STAGE_PRE_SELLER, STAGE_SELLER, ROLE_MANAGER)
          .then(({ data }) => {
            setTeamMembers(data);
          })
          .catch((error) => {
            if (error.response?.data === "auth/id-token-expired") {
              toast.error(
                "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
              );
              return;
            }
            console.error("Erro: ", error.message, " Status: ", error.code);
            toast.error("Não foi possível obter a lista de responsáveis");
          });
      }
    }
    setStageID(
      selectedStage
        ? selectedStage.id || selectStage._id?.valueOf().toString()
        : ""
    );
  }

  return (
    <>
      <div className="flex vCenter">
        <img
          alt=""
          src={require("../../../png/suitcase.png")}
          className="defaultMarginRight iconTitle"
        />
        <h3>Novo negócio</h3>
      </div>
      <label className="flex defaultMarginTop secondaryLabel">
        Selecione a etapa do funil
      </label>
      <div className="flex defaultMarginTop">
        {stages
          .filter((item) => item.step !== "lost" && item.step !== "won")
          .sort((a, b) => a.order - b.order)
          .map((item) => {
            return (
              <StageHeader
                key={item.id || item._id?.valueOf().toString()}
                text={item.description}
                className="min pointer smallMarginBottom"
                active={
                  stageID
                    ? stageID === (item.id || item._id?.valueOf().toString())
                    : currentStageID ===
                      (item.id || item._id?.valueOf().toString())
                }
                onClick={() => {
                  selectStage(item);
                }}
              />
            );
          })}
      </div>
      {stages.length ? (
        <>
          <div className="fullWidth flex center wrap defaultMarginBottom smallMarginTop contentInputs">
            <Input
              label="Nome do negócio"
              className="fullWidth inputSpaced"
              disabled={false}
              value={fields.name}
              isDefault={true}
              onChange={(value) => handleCommonFields(value, "name")}
            />
            <div style={{ marginTop: "8px" }} className="flex column halfWidth">
              <label className="secondaryLabel">
                <span>Empresa</span>
              </label>
              <AutoComplete
                disabled={false}
                value={customerCompanyName}
                isBusinessPage={isBusinessPage}
                onChange={(event, option) => {
                  if (option && option.inputValue && isBusinessPage) {
                    setCustomerCompanyID("");
                    setCustomerCompanyName(option.inputValue);
                  } else {
                    if (option) {
                      setCustomerCompanyName(option.companyName);
                      setCustomerCompanyID(option.id);
                      setSegment(option.segment);
                      setSite(option.site);
                      setEmail(option.email);
                      setPhoneMain(option.phoneMain);
                      setSocialNetwork(option.socialNetwork);
                    }
                  }
                }}
                options={companiesOptions}
              />
            </div>
            <Select
              placeholder="Qual o segmento?"
              value={segment}
              onChange={(value) => setSegment(value)}
              options={segmentsOptions}
              isDefault={true}
              label="Segmento de mercado"
              disabled={isCompanyDisabled}
            />

            <Input
              label="Site"
              type="url"
              disabled={isCompanyDisabled}
              value={site}
              isDefault={true}
              onChange={(value) => setSite(value)}
            />
            <Input
              label="E-mail"
              disabled={isCompanyDisabled}
              value={email}
              isDefault={true}
              onChange={(value) => setEmail(value)}
            />

            <InputMasked
              label="Telefone principal"
              type="tel"
              disabled={isCompanyDisabled}
              isDefault={true}
              maskChar={null}
              mask="(99) 99999-9999"
              value={phoneMain ? phoneMain : ""}
              onChange={(value) => setPhoneMain(value)}
              required={true}
            />
            <Input
              label="Rede social"
              disabled={isCompanyDisabled}
              value={socialNetwork}
              isDefault={true}
              onChange={(value) => setSocialNetwork(value)}
            />

            <div className="fullWidth flex wrap contentContact">
              <div
                style={{ marginTop: "8px" }}
                className="flex column halfWidth"
              >
                <label className="secondaryLabel">
                  <span>Contato</span>
                </label>
                <AutoComplete
                  isBusinessPage={isBusinessPage}
                  value={customerContactName}
                  onChange={(event, option) => {
                    if (option && option.inputValue && isBusinessPage) {
                      setCustomerContactID("");
                      setCustomerContactName(option.inputValue);
                    } else {
                      if (option) {
                        setCustomerContactName(option.name);
                        setCustomerContactID(option.id);
                        setContactEmail(option.email);
                        setContactPhoneMain(option.phoneMain);
                        setContactPhoneSecondary(option.phoneSecondary);
                        setContactPosition(option.position);
                        setContactWhatsApp(option.whatsApp);
                      }
                    }
                  }}
                  contactsOptions={contactsOptions}
                />
              </div>
              <Input
                label="Cargo"
                disabled={false}
                value={contactPosition ? contactPosition : ""}
                isDefault={true}
                onChange={(value) => setContactPosition(value)}
                //className="fullWidth"
              />

              <Input
                label="E-mail"
                type="email"
                disabled={isContactDisabled}
                isDefault={true}
                value={contactEmail ? contactEmail : ""}
                onChange={(value) => setContactEmail(value)}
              />
              <InputMasked
                className="variantInput smallMarginTop"
                label="Telefone principal"
                type="tel"
                maskChar={null}
                disabled={isContactDisabled}
                isDefault={true}
                mask="(99) 99999-9999"
                value={contactPhoneMain ? contactPhoneMain : ""}
                onChange={(value) => setContactPhoneMain(value)}
                required={true}
              />

              <InputMasked
                label="Telefone secundário"
                type="tel"
                maskChar={null}
                disabled={isContactDisabled}
                isDefault={true}
                mask="(99) 99999-9999"
                value={contactPhoneSecondary ? contactPhoneSecondary : ""}
                onChange={(value) => setContactPhoneSecondary(value)}
                required={true}
              />
              <InputMasked
                label="Whatsapp"
                type="tel"
                maskChar={null}
                disabled={isContactDisabled}
                isDefault={true}
                mask="(99) 99999-9999"
                value={contactWhatsApp ? contactWhatsApp : ""}
                onChange={(value) => setContactWhatsApp(value)}
                required={true}
              />
            </div>

            <Select
              placeholder="  "
              label="Responsável"
              onChange={(value) => handleCommonFields(value, "memberProfileID")}
              options={teamMembers}
              valueOption="name"
              value={fields.memberProfileID}
              isDefault={true}
            />
            <InputMasked
              label="Data prevista do negócio"
              disabled={isDisabled}
              isDefault={true}
              mask="99/99/9999"
              value={fields.expectedDate}
              onChange={(value) => handleCommonFields(value, "expectedDate")}
              required={true}
            />

            <Select
              placeholder="Qual é a origem?"
              value={fields.leadOrigin}
              onChange={(value) => handleCommonFields(value, "leadOrigin")}
              options={sourcesOptions}
              isDefault={true}
              label="Origem do lead"
            />
            <Select
              placeholder="Quais são as dores?"
              value={fields.pain}
              onChange={(value) => handleCommonFields(value, "pain")}
              options={painOptions}
              isDefault={true}
              label="Dores"
            />

            <InputCurrency
              label="Valor único"
              disabled={isDisabled}
              value={fields.uniqueValue}
              isDefault={true}
              onChange={(value) => handleCommonFields(value, "uniqueValue")}
            />
            <InputCurrency
              label="Valor recorrente"
              disabled={isDisabled}
              value={fields.recurringValue}
              isDefault={true}
              onChange={(value) => handleCommonFields(value, "recurringValue")}
            />
            {customFieldLabel && (
              <Input
                className="fullWidth"
                label={customFieldLabel}
                disabled={false}
                value={fields.customField}
                isDefault={true}
                onChange={(value) => handleCommonFields(value, "customField")}
              />
            )}

            <TextArea
              label="Observações"
              disabled={isDisabled}
              value={fields.observation}
              isDefault={true}
              onChange={(value) => handleCommonFields(value, "observation")}
              className="fullWidth"
            />
          </div>
          <div className="flex end">
            <Button
              disabled={loadingPostLead}
              isLoading={loadingPostLead}
              text="Salvar"
              paddingHorizontal="30px"
              className="defaultMarginTop"
              onClick={() => handlePostLead(fields)}
            />
          </div>
        </>
      ) : (
        <div className="flex center fullWidth">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default NewBusiness;

/* NOTE: Campo de autocomplete, ainda não funcional abaixo:

 <InputAutoComplete value={fields.tradeName} onChange={(value) => handleCommonFields(value, "tradeName")} type="text"
                    renderInput={(props) => <input {...props} autoComplete="no" />}
                    onSelect={e => handleCommonFields(e.target.value, "tradeName")}
                    items={[
                        { id: 'foo', label: 'foo' },
                        { id: 'bar', label: 'bar' },
                        { id: 'baz', label: 'baz' }
                    ]}
                    shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item.label}
                    renderItem={(item, isHighlighted) => (
                        item.header ?
                            <div
                                className="item item-header"
                                key={item.header}
                            >{item.header}</div>
                            : <div
                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                key={item.abbr}
                            >{item.name}</div>
                    )}

                    renderMenu={(items, value) => (
                        <div className="menu">
                            {value === '' ? (
                                <div className="item">Digite a empresa</div>
                            ) : this.state.loading ? (
                                <div className="item">Carregando...</div>
                            ) : items.length === 0 ? (
                                <div className="item">Nenhum resultado encontrado {value}</div>
                            ) : items}
                        </div>
                    )}

                    isItemSelectable={(item) => !item.header}


                    label="Empresa" disabled={false}
                />

*/
