import React, { useState } from 'react';

function Cancel({ width = 23, height = 23, active = false, ...props }) {

    const [status, setStatus] = useState(active);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="pointer" width="23" height="23" viewBox="0 0 34 34" onMouseOver={() => setStatus(true)} onMouseLeave={() => setStatus(false)} {...props}>
            <g transform="translate(2 2)">
                <g fill={status ? "#D99B9B" : "#fff"} stroke={status ? "#4BA19A" : "#d1d1d1"} strokeWidth={status ? "0px" : "2px"}>
                    <circle stroke="none" cx="15" cy="15" r="15" />
                    <circle fill="none" cx="15" cy="15" r="16" />
                </g>
                <g transform="translate(6.999 6.999)">
                    <rect fill={status ? "#fff" : "#d1d1d1"} width="3" height="19" transform="translate(0 2.121) rotate(-45)" />
                    <rect fill={status ? "#fff" : "#d1d1d1"} width="3" height="19" transform="translate(13.435) rotate(45)" />
                </g>
            </g>
        </svg>
    )

}

export default Cancel;