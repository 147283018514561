import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Base from "../../components/Base";
import ClosedDealsChart from "../../components/DashboardCharts/ClosedDealsChart";
import DealsByResponsibleChart from "../../components/DashboardCharts/DealsByResponsibleChart";
import QualificationsChart from "../../components/DashboardCharts/QualificationsChart";
import LostDealsByReason from "../../components/DashboardCharts/LostDealsByReason";
import {
  getAverageTicket,
  getDashboardInfo,
  getLostLeadsbyReason,
} from "../../request/index";
import { toast } from "react-toastify";
import useRefreshToken from "../../hooks/useRefreshToken";
import Graph from "../../svg/graph";
import Calendar from "../../svg/calendar";
import Check from "../../svg/check";
import ThumbsDown from "../../svg/thumbsDown";
import AccountMultiple from "../../svg/accountmultiple";
import SchedulesChart from "../../components/DashboardCharts/SchedulesChart";
import CalendarPicker from "react-calendar";
import "../Dashboard/CalendarStyle/Calendar.css";
import UniqueValue from "../../svg/uniqueValue";
import TicketChart from "../../components/TicketChart";
import { isTokenExpired } from "../../../src/utils/index";

const errorManager = (error) => {
  if (error.response?.data === "auth/id-token-expired") {
    toast.error(
      "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
    );
    return;
  }
  console.error("Erro: ", error.message, " Status: ", error.code);
  toast.error("Não foi possível carregar os dados!");
};

const DashboardPage = () => {
  useRefreshToken();
  const history = useHistory();
  const dispatch = useDispatch();

  //Ticket Data
  const [ticketPeriod, setTicketPeriod] = useState(0);
  const [ticketGeneral, setTicketGeneral] = useState(0);

  //Charts Data
  const [closedDeals, setClosedDeals] = useState([]);
  const [createdDeals, setCreatedDeals] = useState([]);
  const [activitiesScheduled, setActivitiesScheduled] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [dealsByResponsible, setDealsByResponsible] = useState([]);

  //lostDeas data
  const [lostDeals, setLostDeals] = useState({});

  //Date standarda if for today.
  const [dateValue, onChange] = useState(new Date());
  const [strDate, setStrDate] = useState("");

  const [picker, setpicker] = useState(false);

  const [validToken, setValidToken] = useState(false);

  if (!isTokenExpired(window.localStorage.getItem("token")) && !validToken) {
    setValidToken(true);
  }

  useRefreshToken().then((ntoken) => {
    if (!isTokenExpired(ntoken)) {
      setValidToken(true);
    } else {
      setValidToken(false);
    }
  });

  const loadData = () => {
    getAverageTicket(dateValue.getMonth(), dateValue.getFullYear())
      .then(({ data }) => {
        setTicketPeriod(data.ticketPeriodo || 0);
        setTicketGeneral(data.ticketGeral || 0);
      })
      .catch((error) => {
        errorManager(error);
      });

    getDashboardInfo(dateValue.getMonth(), dateValue.getFullYear())
      .then(({ data }) => {
        setClosedDeals(data.closedDeals || []);
        setCreatedDeals(data.createdDeals || []);
        setActivitiesScheduled(data.activitiesScheduled || []);
        setQualifications(data.qualifications || []);
        setDealsByResponsible(data.dealsByResponsible || []);
      })
      .catch((error) => {
        errorManager(error);
      });

    getLostLeadsbyReason(dateValue.getMonth(), dateValue.getFullYear())
      .then(({ data }) => {
        setLostDeals(data.data || []);
      })
      .catch((error) => {
        errorManager(error);
      });
  };

  useEffect(() => {
    if (validToken) {
      loadData();
    }

    setStrDate(
      `⮜ ${dateValue.toLocaleString("pt-BR", {
        month: "long",
      })} de ${dateValue.getFullYear()} ⮞`.toUpperCase()
    );
  }, [dateValue, validToken]);

  return (
    <>
      <div className="fullWidth flex between titleContent">
        <h1>Dashboard</h1>
      </div>

      <div
        className="flex defaultMargin dropdown defaultPadding "
        style={{ marginBottom: "0px" }}
      >
        <div className="dropbtn">
          <h2 style={{ fontSize: "18px" }}>{strDate}</h2>
        </div>
        <CalendarPicker
          className="dropdown-content border "
          value={dateValue}
          onChange={onChange}
          maxDetail="year"
          defaultView="year"
          locale="pt-BR"
        />
      </div>

      <div className="fullWidth flex column">
        <div className="fullWidth flex row center wrap">
          <div className="chartContainer flexGrom border defaultPadding ">
            <div className="flex vCenter defaultMargin">
              <UniqueValue></UniqueValue>
              <h3 className="defaultMarginLeft">Ticket Médio</h3>
            </div>
            <hr class="noLimit defaultMargin"></hr>
            <div className=" flex chart row around">
              <div className="primaryColor">
                <TicketChart
                  date={strDate.replace(/[⮜⮞]/g, "")}
                  ticket={`${ticketPeriod}`}
                />
              </div>
              <div className="vertical " />
              <div className="secondaryColor">
                <TicketChart date={"Geral"} ticket={`${ticketGeneral}`} />
              </div>
            </div>
          </div>

          <div className="chartContainer flexGrow border defaultPadding ">
            <div className="flex vCenter defaultMargin">
              <Calendar class="defaultMargin"></Calendar>
              <h3 className="defaultMarginLeft">Agendamentos</h3>
            </div>
            <hr class="noLimit defaultMargin"></hr>
            <SchedulesChart schedules={activitiesScheduled} />
          </div>

          <div className="chartContainer flexGrow border defaultPadding ">
            <div className="flex vCenter defaultMargin">
              <AccountMultiple class="defaultMarginLeft"></AccountMultiple>
              <h3 className="defaultMarginLeft">Negócios por Vendedor</h3>
            </div>
            <hr class="noLimit defaultMargin"></hr>
            <DealsByResponsibleChart dealsByResponsible={dealsByResponsible} />
          </div>

          <div className="chartContainer flexGrow  border defaultPadding ">
            <div className="flex vCenter defaultMargin">
              <Graph class="defaultMargin"></Graph>
              <h3 className="defaultMarginLeft">
                {`Negócios Ganhos e Abertos de ${dateValue.getMonth()}-${dateValue.getFullYear()}`}
              </h3>
            </div>
            <hr class="noLimit defaultMargin"></hr>
            <ClosedDealsChart
              closedDeals={closedDeals}
              createdDeals={createdDeals}
              date={{
                month: dateValue.getMonth(),
                year: dateValue.getFullYear(),
              }}
            />
          </div>

          <div className="largeChartContainer flexGrow border defaultPadding ">
            <div className="flex vCenter defaultMargin">
              <Check class="defaultMarginLeft"></Check>
              <h3 className="defaultMarginLeft">Qualificações</h3>
            </div>
            <hr class="noLimit defaultMargin"></hr>
            <QualificationsChart qualifications={qualifications} />
          </div>

          <div className="largeChartContainer flexGrow  border defaultPadding ">
            <div className="flex vCenter defaultMargin">
              <ThumbsDown class="defaultMargin"></ThumbsDown>
              <h3 className="defaultMarginLeft">Negócios Perdidos</h3>
            </div>
            <hr class="noLimit defaultMargin"></hr>
            <LostDealsByReason
              lostDeals={lostDeals}
              date={strDate.replace(/[⮜⮞]/g, "")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Base(DashboardPage);
