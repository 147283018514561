import React, { useState, setState, Fragment } from 'react';
import Option from './Option';
import Input from '../InputHook';
import OptionEdit from './OptionEdit';
import clsx from 'clsx';
import PlusButton from '../../svg/plus_button';

function Question(props) {

    const [title, setTitle] = useState(props.title);

    const handleQuestions = (value, property, index) => {
        props.handleFocusChoice(true)
        let auxQuestion = { ...props.question };
        if (index || index === 0) {
            auxQuestion[property][index] = value;
        } else {
            auxQuestion[property] = value;
        }
        props.handleFields(auxQuestion, "questions", props.index);
    }

    return (
        <div className={clsx("card flex vCenter defaultMarginTop question", props.edit ? "dashed" : "border")}>
            <div className="fullWidth">
                {
                    props.edit ?
                        (
                            <Input autoFocus={props.focus} placeholder="Digite uma pergunta"className="fullWidth title relative hCenter" isDefault={true} value={title} withoutMargin={true} onChange={setTitle} onBlur={() => handleQuestions(title, "title")} inputStyle={{ fontWeight: 700, fontSize: 14, marginLeft: 25 }} style={{}} />
                        ) : (
                            <p className="strong">{(props.index + 1) + ") " + props.title}</p>
                        )
                }


                {props.question && props.question.choices.map(
                    (item, idx) => {
                        return (
                            <Fragment key={item.title + item.hint + item.score + props.title + idx}>
                                {
                                    props.edit ?
                                        (<OptionEdit focusChoice={props.focusChoice} name={props.name} title={item.title} hint={item.hint || null} score={item.score} handleQuestions={handleQuestions} choice={item} index={idx} />) :
                                        (<Option name={props.name} title={item.title} value={item.selected} hint={item.hint || null} onChange={() => { props.onChange && props.onChange(idx) }} />)

                                }
                            </Fragment>
                        )
                    }
                )}
                {
                    props.edit &&
                    <div className="flex center fullWidth defaultMarginTop">
                        <PlusButton className="pointer" fill="#54555" width="22" onClick={() =>
                            handleQuestions([...props.question.choices, {
                                "title": "",
                                "hint": "",
                                "score": 0
                            }], "choices")} />
                    </div>
                }

            </div>
        </div>

    );
}

export default Question;