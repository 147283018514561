import React, { useState, useEffect } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

const DealsByResponsibleChart = ({ dealsByResponsible }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [options, setOptions] = useState({});

  useEffect(() => {
    let names = [];
    let won = [];
    let lost = [];
    let inProgress = [];

    dealsByResponsible.forEach((element) => {
      names.push(element.responsible);
      won.push(element.dealsWon);
      lost.push(element.dealsLost);
      inProgress.push(element.dealsInProgress);
    });

    let chartDataObj = {
      labels: names,
      datasets: [
        {
          label: "Em Progreso",
          data: inProgress,
          backgroundColor: "rgba(83,84,87,255)",
          borderRadius: 10,
        },
        {
          label: "Perdidos",
          data: lost,
          backgroundColor: "rgba(207,121,121,255)",
          borderRadius: 10,
        },
        {
          label: "Ganhos",
          data: won,
          backgroundColor: "rgba(0,157,173,255)",
          borderRadius: 10,
        },
      ],
    };

    const footer = (tooltipItems) => {
      let sum = 0;

      tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y;
      });
      return "Total: " + sum;
    };

    setOptions({
      interaction: {
        mode: "index",
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            precision: 0,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            footer: footer,
          },
        },
        legend: {
          position: "right",
          labels: {
            usePointStyle: true,
          },
        },
        title: {
          display: false,
          text: ``,
        },
        datalabels: {
          formatter: (value) => {
            return value > 0 ? value : "";
          },
          color: "#fff",
          display: true,
        },
      },
    });

    setChartData({ ...chartDataObj });
  }, [dealsByResponsible]);

  return <Bar options={options} data={chartData} className="chart" />;
};

export default DealsByResponsibleChart;
