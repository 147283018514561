import React, { useEffect } from "react";
import { listActivitiesTypes } from "./request";
import "./App.css";
import store from "./store";
import { Provider } from "react-redux";
import Content from "./components/Content";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  // Faz um cache inicial junto com o axios-cache-adapter
  useEffect(() => {
    listActivitiesTypes(); //getInformationsByType('ACTIVITY_TYPE');
  }, []);

  return (
    <Provider store={store}>
      <Content></Content>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        style={{ fontWeight: "bold", width: "auto", maxWidth: "400px" }}
        newestOnTop={false}
        draggable={false}
        bodyStyle={{ textAlign: "center" }}
      />
    </Provider>
  );
};

export default App;
