import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Close from '../../svg/close';
import { toast } from "react-toastify";

function AlertDropdown({props, onClose}) {
  const history = useHistory();

  const [alerts, setAlerts] = useState(null);

  useEffect(()=>{
    if (!!props){
      setAlerts({...props})
    }
  }, [props])  
  
  return (
    <>
      <ul className="dropdown card">
        <li className="flex smallMarginTop defaultMarginBottom">          
          <span
            style={{ alignSelf: "center", marginTop: "4px" }}
            className="stronger"
          >
            Alertas
          </span>
          <div style={{position:'absolute', right:"5px", top: "5px"}}>        
              <Close
                onClick={() => {                  
                  onClose();
                }}
                className="pointer"
              />
          </div>
        </li>
        <hr className="smallMarginVertical" />
        <li
          className="defaultMarginVertical pointer"
          onClick={() => history.push("/atividades")}
        >
          <span
            style={{ alignSelf: "start", marginTop: "4px" }}
            className="stronger"
          >
            Atividades atrasadas:
          </span>
          <span
            style={{ alignSelf: "end", marginTop: "4px" , color: 'red'}}
            className="stronger"
          >
            {alerts?.overdue?.length || '0'}
          </span>
        </li>
        <li
          className="defaultMarginVertical pointer"
          onClick={() => history.push("/atividades")}
        >
          <span
            style={{ alignSelf: "start", marginTop: "4px" }}
            className="stronger"
          >
            Atividades para hoje:
          </span>
          <span
            style={{ alignSelf: "end", marginTop: "4px" , color: 'red'}}
            className="stronger"
          >
            {alerts?.today?.length || '0'}
          </span>
        </li>        
      </ul>
    </>
  );
}

export default AlertDropdown;
