
import React, { useState } from 'react';
import { toast } from 'react-toastify';

function useLocalStorage(key, initialValue) {

  const checkItem = (value) => {
    try {
      let item = JSON.parse(value);
      return item;
    } catch (error) {
      console.error("Erro: ", error.message, " Status: ", error.code);
      //toast.error('Não foi possível carregar a informação do localstorage');
      return value;
    }
  }

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? checkItem(item) : initialValue;
    } catch (error) {
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error('Não foi possível alterar a informação do localstorage');
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      let valueToStore =
        value instanceof Function ? value(storedValue) : value;

      if (typeof valueToStore !== "string") valueToStore = JSON.stringify(valueToStore);
      window.localStorage.setItem(key, valueToStore);
      setStoredValue(valueToStore);
    } catch (error) {
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error('Não foi possível salvar a informação do localstorage');
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;