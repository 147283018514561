import React, { useState, useEffect } from 'react';
import Hr from '../../../components/Hr'
import Input from '../../../components/InputHook';
import Edit from '../../../svg/edit';
import Save from '../../../png/save-icon-small.png';

const CustomField = ({ item, title, handleFields }) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [label, setLabel] = useState(true);
    const [displaySave, setDisplaySave] = useState(true);

    const setIsDisabledAndSetDisplaySave = () => {
        setIsDisabled(!isDisabled);
        setDisplaySave(!displaySave)
    }

    const handleCustomField = (value, property) => {
        let aux = { ...item }
        aux[property] = value;
        handleFields(aux);
        // setIsDisabled(!isDisabled);
    }

    useEffect(() => {
        setLabel(item.label);
    }, [])

    return (
        <div className="flexGrow card team defaultMarginTop">
            <h3>{`Campo personalizado - ${title}`}</h3>
            <div className="contentCard flex defaultMarginTop reverseEnd">
                <Input placeholder="Resposta" readOnly={true} label={item.label ? item.label : "Nome do campo personalizado"} isDefault={true} className="halfWidth" />
                <Hr className="noLimit verticalHr defaultMarginHorizontal" style={{ height: "40px" }} />
                <div className="halfWidth flex fullHeight" >
                    {
                        isDisabled ? <label className="flexGrow flex vCenter secondaryLabel" style={{ height: "40px", fontWeight: "400", fontSize: "1rem" }} > {item.label ? item.label : "Nome do campo personalizado"}</label> :
                            <Input placeholder="Nome do campo personalizado" withoutMargin={true} value={label} onChange={setLabel} onBlur={() => handleCustomField(label, "label")} isDefault={true} className="flexGrow" />
                    }
                <button onClick={() => { setIsDisabledAndSetDisplaySave() }}>
                    {displaySave  && <Edit className="centerSelf pointer" />}
                    {!displaySave && <img src={Save} alt="save" style = {{ height: "20px" }} />}
                </button>
                    {/* <Edit className="centerSelf pointer" onClick={() => { setIsDisabled(!isDisabled) }} /> */}
                </div>
            </div>
        </div>


    )
};

export default CustomField;

