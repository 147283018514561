import React from "react";
import { useHistory, Link } from "react-router-dom";
import User from "../../svg/user";
import { toast } from "react-toastify";
import { removeCachedResources, getUserProfile } from "../../request";

function Dropdown({ active }) {
  const history = useHistory();

  const userName = window.localStorage.getItem("userName") || "";
  return (
    <>
      <ul className="dropdown card">
        <li className="flex smallMarginTop defaultMarginBottom">
          <User fill="#545557" className="smallMarginRight userSvg" />
          <span
            style={{ alignSelf: "center", marginTop: "4px" }}
            className="stronger"
          >
            {userName || ""}
          </span>
        </li>
        <hr className="smallMarginVertical" />
        <li
          className="defaultMarginVertical pointer"
          onClick={() => history.push("/perfil-usuario")}
        >
          Dados do usuário
        </li>
        <li
          className="defaultMarginVertical pointer"
          onClick={() => {
            localStorage.clear();
            //TODO
            removeCachedResources();

            history.push("/");
          }}
        >
          Sair
        </li>
      </ul>
    </>
  );
}

export default Dropdown;
