import React, { useState, useEffect } from "react";
import { postCustomerCompany, getInformationsByType } from "../../../request";
import Button from "../../Button";
import Input from "../../InputHook";
import InputMasked from "../../InputMaskedHook";
import TextArea from "../../TextAreaHook";
import Select from "../../Select";
import { toast } from "react-toastify";

function NewCompany({ closeModal }) {
  // const [companyID, setCompanyID] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [segment, setSegment] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const [phoneMain, setPhoneMain] = useState("");
  const [phoneSecondary, setPhoneSecondary] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [socialNetwork, setSocialNetwork] = useState("");
  const [observation, setObservation] = useState("");
  const [customField, setCustomField] = useState("");
  const [customFieldLabel, setCustomFieldLabel] = useState("");
  // Endereço
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  // Options for dropdowns
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  // Misc
  const [loading, setLoading] = useState(false);

  // TODO @Anderson, ao carregar a lista de empresas, já está disparando o userEffect da modal,
  // tem como disparar só no momento que clicar em Nova empresa?
  useEffect(() => {
    getInformationsByType("SEGMENTS", "STATES")
      .then((res) => {
        res.data.forEach((info) => {
          if (info.key && info.key === "STATES") {
            setStatesOptions(
              info.data.sort((a, b) => a.label.localeCompare(b.label))
            );
          }
          if (info.SEGMENTS) {
            const informations =
              Object.entries(info).map((element) => {
                return { key: element[0], ...element[1] };
              }) || "";
            if (informations) {
              const segments = informations.reduce((field, { key, data }) => {
                if (key === "SEGMENTS") return data;
                return field;
              }, "");
              setSegmentsOptions(
                segments.sort((a, b) => a.label.localeCompare(b.label))
              );
            }
          }
          if (info.SEGMENTS === undefined) {
            const informations = res.data[1].SEGMENTS.data;
            const segments = Object.entries(informations).map((element) => {
              return { key: element[0], ...element[1] };
            });
            console.log(segments);
            setSegmentsOptions(segments);
          }
        });
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível obter a lista de responsáveis");
      });
  }, []);

  const postCustomer = () => {
    if (!companyName) {
      toast.warning("O nome da empresa é obrigatório");
      return;
    }

    /* TODO: Retirar hard coded quando for implementar o auto complete */
    setLoading(true);
    let payload = {
      // customerCompanyID,
      companyName,
      segment,
      site,
      email,
      phoneMain,
      phoneSecondary,
      whatsApp,
      socialNetwork,
      observation,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      customField,
    };

    postCustomerCompany(payload)
      .then(() => {
        toast.success("Empresa cadastrada com sucesso");
        closeModal();
        setLoading(false);
        setCompanyName("");
        setSegment("");
        setSite("");
        setEmail("");
        setPhoneMain("");
        setPhoneSecondary("");
        setWhatsApp("");
        setSocialNetwork("");
        setObservation("");
        setAddress("");
        setNumber("");
        setComplement("");
        setNeighborhood("");
        setCity("");
        setState("");
        setCustomField("");
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível salvar a empresa");
        setLoading(false);
      });
  };

  useEffect(() => {
    let label = JSON.parse(localStorage.getItem("customFields"))?.reduce(
      (field, { key, label }) => {
        if (key === "LEADS") return label;
        return field;
      },
      ""
    );

    if (label) setCustomFieldLabel(label);
  }, []);

  return (
    <>
      <div className="flex vCenter">
        <img
          alt=""
          src={require("../../../png/contact.png")}
          className="defaultMarginRight iconTitle"
        />
        <h3>Nova empresa</h3>
      </div>

      <div className="fullWidth flex center wrap defaultMarginVertical contentInputs">
        <Input
          label="Empresa"
          isDefault={true}
          value={companyName}
          onChange={setCompanyName}
        />
        <Select
          label="Segmento de mercado"
          placeholder="  "
          isDefault={true}
          value={segment}
          onChange={setSegment}
          options={segmentsOptions}
        />

        <Input label="Site" value={site} isDefault={true} onChange={setSite} />
        <Input
          label="E-mail"
          value={email}
          isDefault={true}
          onChange={setEmail}
        />

        <InputMasked
          label="Telefone principal"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={phoneMain}
          onChange={setPhoneMain}
          required={true}
          maskChar={null}
        />
        <InputMasked
          label="Telefone secundário"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={phoneSecondary}
          onChange={setPhoneSecondary}
          required={true}
          maskChar={null}
        />

        <InputMasked
          label="Whatsapp"
          type="text"
          disabled={false}
          isDefault={true}
          mask="(99) 99999-9999"
          value={whatsApp}
          onChange={setWhatsApp}
          required={true}
          maskChar={null}
        />
        <Input
          label="Rede social"
          value={socialNetwork}
          isDefault={true}
          onChange={setSocialNetwork}
        />

        <Input
          label="Endereço"
          value={address}
          isDefault={true}
          onChange={setAddress}
          className="inputSpaced smallMarginTop"
        />
        <Input
          label="Número"
          value={number}
          isDefault={true}
          onChange={setNumber}
          className="inputSpaced smallMarginTop"
        />

        <Input
          label="Complemento"
          value={complement}
          isDefault={true}
          onChange={setComplement}
        />
        <Input
          label="Bairro"
          value={neighborhood}
          isDefault={true}
          onChange={setNeighborhood}
        />

        <Input
          label="Cidade"
          value={city}
          isDefault={true}
          property="city"
          onChange={setCity}
        />
        <Select
          label="Estado"
          placeholder="  "
          isDefault={true}
          value={state}
          onChange={setState}
          options={statesOptions}
        />
        {customFieldLabel && (
          <Input
            className="fullWidth"
            label={customFieldLabel}
            disabled={false}
            value={customField}
            isDefault={true}
            onChange={setCustomField}
          />
        )}
        <TextArea
          label="Observações"
          value={observation}
          onChange={setObservation}
          className="fullWidth"
        />
      </div>
      <div className="flex end">
        <Button
          text="Salvar"
          paddingHorizontal="30px"
          className="defaultMarginTop"
          isLoading={loading}
          onClick={postCustomer}
        />
      </div>
    </>
  );
}

export default NewCompany;
