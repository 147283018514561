import React, { useState, useEffect } from 'react';
import Base from '../../components/Base';
import Button from '../../components/Button';
import Edit from '../../svg/edit';
import { getQualificationTemplate, putQualificationTemplate } from '../../request';
import Question from '../../components/Question';
import Modal from '../../components/Modal';
import QualificationFlowEdit from '../../components/Modal/QualificationFlowEdit';
import ConfigurationPageTitle from '../../components/ConfigurationPageTitle'
import { toast } from 'react-toastify';
import ProgressBar from '../../components/ProgressBar';

const QualificationFlowPage = () => {

  const [ranges, setRanges] = useState();
  const [template, setTemplate] = useState();
  const [modalQualificationFlowEdit, setModalQualificationFlowEdit] = useState(false);
  const [rangeDisabled, setRangeDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const checkElement = (ranges, value, index) => ([
    ranges[index - 1] ? +ranges[index - 1].value < +value : true,
    ranges[index + 1] ? +value < +ranges[index + 1].value : true
  ].indexOf(false) === -1)

  const changeRange = (itemAtualValue, itemAtualIndex) => {
    setRanges((prevRanges) => {
      prevRanges = [...prevRanges]
      if (checkElement(prevRanges, itemAtualValue, itemAtualIndex)) {
        prevRanges[itemAtualIndex].value = itemAtualValue;
      }
      prevRanges[itemAtualIndex + 1] && (prevRanges[itemAtualIndex + 1].disabled = false);
      return prevRanges;
    })
  }

  const initQualification = () => {
    setIsLoading(true)
    getQualificationTemplate().then(({ data }) => {
      setTemplate(data)
      setRanges(
        data.score.sort((a, b) => b.order - a.order).map(({ max, label, order }, index) => ({ value: max, disabled: !!index, label, order }))
      );
    })
    .catch((error) => {
      if (error.response?.data === "auth/id-token-expired") {
        toast.error("O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema.");
        return;
      }
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error('Não foi carregar a template de qualificação');
    }).finally(()=> setIsLoading(false));
  }
    
  const handleSendQualification = ({ created, companyID, userUID, ...fields }) => {
    setIsLoading(true)

    let isInvalid = false;
    if (fields.questions?.length > 0) {
      for (const question of fields.questions) {
        isInvalid = question.choices.some((choice) => !choice.title);
        if (isInvalid) {
          toast.error('O título das respostas não pode ser em branco');
          setIsLoading(false)
          setRangeDisabled(!rangeDisabled)
          return;
        }
      };

    }

    putQualificationTemplate(fields).then(() => {
      initQualification();
    })
    .catch((error) => {
      if (error.response?.data === "auth/id-token-expired") {
        toast.error("O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema.");
        return;
      }
      if (error.response?.data === "INVALID_TITLE") {
        toast.error('O título das respostas não pode ser em branco');
        return;
      }
      console.error("Erro: ", error.message, " Status: ", error.code);
      toast.error('Não foi possível atualizar o fluxo de qualificação');
    })
    .finally(() => {
      setIsLoading(false);
      setRangeDisabled(!rangeDisabled)
    });
  }

  // TODO @Anderson, está chamando o GET duas vezes ao carregar esta página
  useEffect(() => {
    initQualification()
  }, []);

  useEffect(() => {
    if (!modalQualificationFlowEdit)
      initQualification()
  }, [modalQualificationFlowEdit]);

  return (
    <>
      <ProgressBar className="loading" loading={isLoading} color="#545557" height="4px" style={{ position: "fixed", top: 0, left: 0, }} />
      {
        template && <Modal className="modal-lg modalQualificationFlowEdit"
          active={modalQualificationFlowEdit} closeModal={() => setModalQualificationFlowEdit(false)}
          content={<QualificationFlowEdit template={JSON.parse(JSON.stringify(template))} status={modalQualificationFlowEdit} closeModal={() => setModalQualificationFlowEdit(false)} />}
        />
      }
      <ConfigurationPageTitle title="Fluxo de Qualificação" />
      <div className="fullWidth flex wrapReverse hCenter nowrap">

        <div className="flexGrow3 flex column hCenter startSelf" >
          <div className="card border flex hCenter fullWidth startSelf">
              <h3 style={{flexGrow: 1}}>{template ? template.description : ""}</h3>
              <Edit className="pointer flexShrinkNone" onClick={() => setModalQualificationFlowEdit(true)} />
            
   
          </div>

          {
            template && template.questions.map((item, index) => <Question key={item.title + item.order + "view" + index} name="mock1" index={index} question={item} title={item.title} />)
          }
        </div>
        <div className="card border  defaultMarginLeft  profileContent flex column vCenter startSelf defaultMarginBottom">
          <div className="flex between fullWidth defaultMarginBottom">
            <h3>Score final</h3>
            {
              rangeDisabled ?
                (<Edit className="pointer flexShrinkNone" onClick={() => {
                  setRangeDisabled(!rangeDisabled)
                }} />) :
                (<Button text="Salvar" isLoading={isLoading} paddingHorizontal="20px" onClick={() => {
                  let config = ranges.map(({ label, value, order }, index, array) => (
                    { label, order, max: +value, min: !index ? 0 : +array[index - 1].value + 1 }
                  ));
                  handleSendQualification({ ...template, score: config })
                }} />)
            }
          </div>
          <div className="flex columnReverse fullWidth defaultMarginTop">
            {
              ranges && ranges.map((item, index) => {
                let lastIndex = index ? index - 1 : 0;
                let lastValue = index !== 0 ? +ranges[lastIndex].value + 1 : 0;
                lastValue = lastValue > 100 ? 100 : lastValue;
                let isLastItem = index === ranges.length - 1;
                let disabled = true;
                if (!rangeDisabled) {
                  disabled = item.disabled;
                }
                return (
                  <div key={item.label} className="flex fullwidth between contentRange">
                    <span>{item.label}</span>
                    <label className="flex column">
                      <div className="flex end rangeValue">
                        <span>{lastValue} - {item.value}</span>
                      </div>
                      <input type="range" disabled={disabled} minLength={0} value={item.value} onChange={(inpt) => { changeRange(!isLastItem ? inpt.target.value : 100, index) }} />
                    </label>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Base(QualificationFlowPage);
