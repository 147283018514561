import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Base from "../../components/Base";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Subtitle from "../../components/Subtitle";
import Edit from "../../svg/edit";
import Trash from "../../svg/trash";
import StageHeader from "../../components/Stage/StageHeader";
import Chip from "../../components/Chip";
import Modal from "../../components/Modal";
import ProgressBar from "../../components/ProgressBar";
import PipelineAndCadenceEdit from "../../components/Modal/PipelineAndCadenceEdit";
import Confirm from "../../components/Modal/Confirm";
import {
  getAllPipelineByCompanyID,
  deletePipeline,
  getAllLeadsByPipelineID,
} from "../../request";
import useLocalStorage from "../../hooks/useLocalStorage";
import ConfigurationPageTitle from "../../components/ConfigurationPageTitle";
import { toast } from "react-toastify";

const PipelineAndCadencePage = () => {
  const [pipelines, setPipelines] = useState([]);
  const [atualPipeline, setAtualPipeline] = useState(null);
  const [modalPipelineAndCadence, setModalPipelineAndCadence] = useState(false);
  const [modalConfirm, setModalConfirm] = useState({
    status: false,
    message: "",
    hasCancel: true,
    hasConfirm: true,
    onConfirm: () => {},
  });
  const [loading, setLoading] = useState(null);
  const [uid] = useLocalStorage("uid");
  const [role] = useLocalStorage("role");

  const handleDeletePipeline = (pipelineId) =>
    new Promise((resolve, reject) => {
      let pipeLineLeads = getAllLeadsByPipelineID(pipelineId);
      pipeLineLeads.then((leads) => {
        let checkLeads = leads.data;
        if (checkLeads.length == 0) {
          deletePipeline(pipelineId)
            .then(() => {
              toast.success("Funil deletado com sucesso");
              initPipeline();
              resolve();
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível deletar o funil.");
              reject();
            });
        } else {
          toast.error(
            "Há etapas a serem completadas neste funil. Por favor certifique-se de terminá-las antes de excluí-lo."
          );
          resolve();
        }
      });
    });

  const handleModalConfirm = (value, property) => {
    if (value instanceof Object) {
      setModalConfirm({
        ...modalConfirm,
        ...value,
      });
    } else {
      setModalConfirm({
        ...modalConfirm,
        [property]: value,
      });
    }
  };

  const initPipeline = () => {
    setLoading(true);
    getAllPipelineByCompanyID()
      .then(({ data }) => {
        setPipelines(data);
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }

        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar os funis da empresa");
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setLoading(null);
        }, 4000);
      });
  };

  const removeRefer = (item) => JSON.parse(JSON.stringify(item));

  useEffect(() => {
    initPipeline();
  }, []);

  useEffect(() => {
    // TODO @Anderson, está chamando 2x o getAllPipelineByCompanyID ao carregar essa pagina
    if (!modalPipelineAndCadence && modalPipelineAndCadence !== null) {
      initPipeline();
    }
  }, [modalPipelineAndCadence]);

  return (
    <>
      <ProgressBar
        className="loading"
        loading={loading}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />
      <Modal
        className="modal-lg modalPipelineAndCadence"
        active={modalPipelineAndCadence}
        closeModal={() => setModalPipelineAndCadence(false)}
        content={
          <PipelineAndCadenceEdit
            status={modalPipelineAndCadence}
            pipeline={atualPipeline}
            closeModal={() => setModalPipelineAndCadence(false)}
          />
        }
      />
      <Modal
        className="modal-xs modalConfirm"
        active={modalConfirm.status}
        closeModal={() => handleModalConfirm(false, "status")}
        content={
          <Confirm
            onCancel={() => handleModalConfirm(false, "status")}
            closeModal={() => handleModalConfirm(false, "status")}
            {...modalConfirm}
          />
        }
      />
      {/* <div className="fullWidth flex between titleContent"> */}
      {/* alterado  */}
      <div>
        <ConfigurationPageTitle title="Funis e cadências">
          <Button
            text="Novo funil"
            paddingHorizontal="25px"
            onClick={() => {
              setAtualPipeline(null);
              setModalPipelineAndCadence(true);
            }}
          />
        </ConfigurationPageTitle>
      </div>
      {pipelines.map((item) => {
        let preSellerStages = item.stages.filter(
          (item) => item.step === "pre-seller"
        );
        let sellerStages = item.stages.filter((item) => item.step === "seller");

        return (
          <div
            key={item.id || item._id.valueOf().toString()}
            className="fullWidth flex column card border table "
          >
            <div className="flex between fullWidth">
              <Subtitle
                title={item.description}
                className="defaultMarginBottom"
              />
              <div className="flex">
                {
                  <Trash
                    fill={
                      role !== "manager" &&
                      role !== "admin" /*||
                        item.userUID !== uid*/ &&
                      "#54555799"
                    }
                    className="pointer defaultMarginRight"
                    height="15px"
                    onClick={() => {
                      if (
                        role !== "manager" &&
                        role !== "admin" /* ||
                        item.userUID !== uid*/
                      ) {
                        handleModalConfirm({
                          status: true,
                          hasCancel: false,
                          hasConfirm: false,
                          message:
                            "Você não tem permissão para excluir este funil",
                        });
                      } else {
                        handleModalConfirm({
                          status: true,
                          hasCancel: true,
                          hasConfirm: true,
                          onConfirm: () =>
                            handleDeletePipeline(
                              item.id || item._id.valueOf().toString()
                            ),
                          message:
                            "Excluindo o funil, todas as informações associadas ao funil serão excluídas. Informações referentes as atividades, etapas e negócios serão apagadas. Deseja continuar?",
                        });
                      }
                    }}
                  />
                }
                <Edit
                  className="pointer"
                  height="15px"
                  onClick={() => {
                    setAtualPipeline(() => removeRefer(item));
                    setModalPipelineAndCadence(true);
                  }}
                />
              </div>
            </div>
            <div className="flex fullWidth funnelAndCadenceContent auto">
              {preSellerStages
                .sort((a, b) => a.order - b.order)
                .map((item, index, array) => (
                  <div
                    key={item.id || item._id.valueOf().toString()}
                    className={clsx(
                      "stageColumn flexShrinkNone",
                      index + 1 === array.length && "border"
                    )}
                  >
                    <StageHeader
                      active={true}
                      text={item.description}
                      className="light smallMarginBottom"
                    />
                    <div className="dashed rounded contentStage">
                      {item?.suggestedActivities?.map(
                        ({ id, duration, cadence }, index, { length }) => (
                          <div
                            key={
                              (item.id || item._id.valueOf().toString()) + id
                            }
                          >
                            <Chip type={id} duration={duration} />

                            {length !== index + 1 && (
                              <Chip
                                className="smallMarginVertical"
                                variant="simple"
                                cadence={cadence}
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}

              {sellerStages
                .sort((a, b) => a.order - b.order)
                .map((item) => (
                  <div
                    key={item.id || item._id.valueOf().toString()}
                    className="stageColumn flexShrinkNone"
                  >
                    <StageHeader
                      active={true}
                      text={item.description}
                      className="smallMarginBottom"
                    />
                    <div className="dashed rounded contentStage">
                      {item?.suggestedActivities?.map(
                        ({ id, duration, cadence }, index, { length }) => (
                          <div>
                            <Chip
                              key={id + index + "default"}
                              type={id}
                              duration={duration}
                            />

                            {length !== index + 1 && (
                              <Chip
                                className="smallMarginVertical"
                                variant="simple"
                                cadence={cadence || "1h"}
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Base(PipelineAndCadencePage);
