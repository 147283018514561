import React from 'react';
import Input from '../../InputHook';

function Option({ name, value, onChange, title, hint }) {
    return (
        <div className="flex vCenter fullWidth">
            <label className="answer defaultMarginTop flex column fullWidth">
                <input type="radio" name={name} checked={value ? true : false} onChange={onChange} />
                <span className="strong">{title}</span>
                {hint &&
                    (
                        <small className="smallMarginTop"><strong>Dica: </strong>{hint}</small>
                    )
                }
            </label>


        </div>
    );
}

export default Option;