import React, { Fragment } from "react";
import Member from "../Member";
import Hr from "../../../components/Hr";

const Teams = (props) =>
  props.teams &&
  props.teams.map((team) => (
    <div key={team.id} className="flexGrow card team defaultMarginTop">
      <h3>{team.Team.label}</h3>
      <div className="contentCard flex column defaultMarginTop">
        {team.members && team.members.length > 0 ? (
          team.members.map((member, idx, { length }) => (
            <Fragment key={member.id}>
              <Member
                member={member.MemberProfile}
                onEdit={props.onEdit}
                urlImage={member.MemberProfile.urlImage}
                isManager={props.isManager}
              />
              {idx < length - 1 && (
                <Hr className="noLimit defaultMarginVertical" />
              )}
            </Fragment>
          ))
        ) : (
          <span>Cadastre membros para sua equipe</span>
        )}
      </div>
    </div>
  ));

export default Teams;
