import React from "react";
import Button from "../../components/Button";
import { auth } from "../../config";
import { toast } from "react-toastify";
import { removeCachedResources } from "../../request";

const SucessPage = () => {
  return (
    <div className="backgroundGradient sucessoScreen" onClick={() => {}}>
      <main className="flex center">
        <div className="flex center column card">
          <div className="flex center fullWidth">
            <div className="smallMarginBottom flex center">
              <img
                alt=""
                src={require("../../png/check.png")}
                className="check"
              />
              <h2 className="defaultMarginLeft">Sucesso!</h2>
            </div>
          </div>
          <hr className="smallMarginTop defaultMarginBottom" />
          <div className="defaultMarginVertical textCenter successMessage">
            <p>Sua conta foi criada com sucesso.</p>
            <p>Aguarde a liberação por e-mail.</p>
          </div>
          <Button
            text="OK"
            className="fullWidth defaultMarginTop"
            onClick={() => {
              localStorage.removeItem("customFields");
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("uid");
              localStorage.removeItem("isAuthenticatedWithEmail");
              localStorage.removeItem("role");
              localStorage.removeItem("userImageUrl");
              //TODO
              removeCachedResources();
            }}
          ></Button>
        </div>
      </main>
    </div>
  );
};

export default SucessPage;
