import React from 'react';
import clsx from 'clsx';

function TextArea({ withoutMargin, className, label, onChange, ...rest }) {

    return (
        <>
            <label className={clsx("flex column secondaryLabel relative", !withoutMargin && "smallMarginTop", className, (className && className.indexOf("fullWidth") !== -1) ? "fullWidth" : "halfWidth")}>
                {label && (<span>{label}</span>)}
                <textarea
                    className={clsx("variantInput smallMarginTop", !withoutMargin && "smallMarginTop")}
                    {...rest}
                    onChange={inpt => { onChange(inpt.target.value) }} />

            </label>
        </>
    );

}

export default TextArea;