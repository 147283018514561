/* eslint-disable */
import React from "react";
import History from "./History";
import Common from "./Common";

const Activity = ({ data, isHistory }) => (
  <>{isHistory ? <History data={data} /> : <Common data={data} />}</>
);

export default Activity;
