//NOTE: Função responsável por retornar os erros do sistema
export const getMessage = (code) => {
  var obj = {
    "auth/invalid-email": "Email inválido.",
    "auth/wrong-password": "Senha inválida.",
    "auth/user-not-found": "Usuário não encontrado.",
  };

  return obj[code] ? obj[code] : "Erro.";
};

//NOTE: Função responsável por tranformar data(dd/mm/aaaa) em milissegundos
export const dateToMili = (date) => {
  let array = `${date}`.split("/").reverse();
  return new Date(array).getTime();
};

//NOTE: Função responsável por remover mascara e transformar o valor em number
export const currencyToNumber = (currency) => {
  if (typeof currency === "string") {
    return +currency.replaceAll(".", "").replace(",", ".").replace("R$ ", "");
  }
  return currency;
};

//NOTE: Função responsável por tratar payload, removendo todos os campos vazios antes de enviar para o backend
export const removeEmptyKeys = (obj) => {
  for (let key in obj) {
    if (obj[key] == "" || !!obj[key] == false) {
      delete obj[key];
    }
    if (typeof obj[key] == "object") {
      if (Object.entries(obj[key]).length) {
        removeEmptyKeys(obj[key]);
        if (!Object.entries(obj[key]).length) delete obj[key];
      } else {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const getMonthString = (index) =>
  ({
    0: "Janeiro",
    1: "Fevereiro",
    2: "Março",
    3: "Abril",
    4: "Maio",
    5: "Junho",
    6: "Julho",
    7: "Agosto",
    8: "Setembro",
    9: "Outubro",
    10: "Novembro",
    11: "Dezembro",
  }[index]);

//TODO: Remover função quando todos os campos de máscara manuais forem alterados
export const setValuesMasked = (setValue, event, configs) => {
  let value = event.target.value;
  let checkNext = (mask, index) => {
    let status = false;
    let value = "";
    index += 1;

    while (!status) {
      if (mask[index] === " " && mask[index] !== "9") {
        value += mask[index];
        index++;
      } else {
        status = true;
      }
    }
    return value;
  };

  if (event.keyCode >= 48 && event.keyCode <= 57) {
    if (configs.mask) {
      if (configs.mask[value.length] && configs.mask[value.length] !== "9") {
        if (configs.mask[value.length] !== " ") {
          value =
            value +
            configs.mask[value.length] +
            checkNext(configs.mask, value.length) +
            event.key;
        } else {
          value = value + " " + event.key;
        }
      } else {
        value = value + event.key;
      }
    } else {
      value = value + event.key;
    }
    var maxLength = configs.mask ? configs.mask.length : configs.maxlength;
    if (maxLength && value.length > maxLength) {
      value = value.substring(0, value.length - 1);
    }
  } else if (event.keyCode === 8) {
    value = value.substring(0, value.length - 1);
  }
  setValue(value);
};
