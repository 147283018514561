import React, { useState, useEffect } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

const QualificationsChart = ({ qualifications }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          precision: 0,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: false,
        text: ``,
      },
      datalabels: {
        formatter: (value) => {
          return value > 0 ? value : "";
        },
        color: "#fff",
        display: true,
      },
    },
  };

  useEffect(() => {
    let congelado, frio, morno, quente, muitoQuente, unqualified;
    congelado = frio = morno = quente = muitoQuente = unqualified = 0;

    qualifications.forEach((qualif) => {
      switch (qualif.labelScore) {
        case "not qualified":
          unqualified++;
          break;
        case "Congelado":
          congelado++;
          break;
        case "Frio":
          frio++;
          break;
        case "Morno":
          morno++;
          break;
        case "Quente":
          quente++;
          break;
        case "Muito quente":
          muitoQuente++;
          break;
      }
    });

    const chartDataObj = {
      labels: [
        "Não qualificada",
        "Muito quente",
        "Quente",
        "Morno",
        "Frio",
        "Congelado",
      ],
      datasets: [
        {
          label: "Não qualificada",
          data: [unqualified, 0, 0, 0, 0, 0],
          backgroundColor: "rgba(83,84,87,255)",
          borderRadius: 10,
        },
        {
          label: "Muito quente",
          data: [0, muitoQuente, 0, 0, 0, 0],
          backgroundColor: "rgba(143,45,86,255)",
          borderRadius: 10,
        },
        {
          label: "Quente",
          data: [0, 0, quente, 0, 0, 0],
          backgroundColor: "rgba(216,17,89,255)",
          borderRadius: 10,
        },
        {
          label: "Morno",
          data: [0, 0, 0, morno, 0, 0],
          backgroundColor: "rgba(255,160,66,255)",
          borderRadius: 10,
        },
        {
          label: "Frio",
          data: [0, 0, 0, 0, frio, 0],
          backgroundColor: "rgba(0,161,154,255)",
          borderRadius: 10,
        },
        {
          label: "Congelado",
          data: [0, 0, 0, 0, 0, congelado],
          backgroundColor: "rgba(0,180,219,255)",
          borderRadius: 10,
        },
      ],
    };

    setChartData({ ...chartDataObj });
  }, [qualifications]);

  return <Bar options={options} data={chartData} className="largeChart" />;
};

export default QualificationsChart;
