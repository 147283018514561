import React, { useState, useEffect } from "react";
import { postActivity, putActivity } from "../../../request";
import TextArea from "../../TextArea";
import Button from "../../Button";
import Select from "../../Select";
import ModalTitle from "../../Modal/ModalTitle";
import Attention from "../../../svg/attention";
import InputHour from "../../InputHour/index";
import InputMasked from "../../InputMaskedHook/index";
import { removeEmptyKeys } from "../../../script";
import { ACTIVITY_COMPLETED } from "../../../utils";
import { format } from "date-fns";
import { toast } from "react-toastify";

function ActivityModal({ activity, closeModal, refetch, status, leads }) {
  const [isCreating, setIsCreating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fields, setFields] = useState({
    leadID: "",
    activityTypeID: "",
    startTime: "",
    endTime: "",
    description: "",
    date: "",
    startHour: "",
    endHour: "",
  });
  let seqSwitch = false;
  let activity_ID = activity?.id || activity?._id;

  useEffect(() => {
    if (status) {
      const isCreatingBool = !activity;
      setIsCreating(isCreatingBool);
      if (activity) {
        let date,
          startHour = "",
          endHour = "";
        try {
          const startDate = new Date(activity.startTime);
          date = format(startDate, "dd/MM/yyyy");
          startHour = format(startDate, "HH:mm");
          if (activity.endTime) {
            endHour = format(new Date(activity.endTime), "HH:mm");
          }
        } catch (error) {
          console.warn("Data inválida:", activity.startTime, activity.endTime);
          toast.warning("Data inválida");
          date = new Date();
        }
        setFields({ ...activity, date, startHour, endHour });
      } else {
        resetFields();
      }
    }
  }, [status]);

  const handleFields = (value, property) => {
    let aux = {};
    aux[property] = value;
    setFields({ ...fields, ...aux });
  };

  const handleSaveActivity = async (fields, activityID) => {
    setIsSaving(true);

    const revalidator = require("revalidator");
    let isValid = revalidator.validate(fields, {
      properties: {
        leadID: {
          message: "O Negócio é obrigatório",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        date: {
          message: "A Data é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        endHour: {
          message: "A Hora de fim é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        startHour: {
          message: "A Hora de início é obrigatória",
          type: "string",
          required: true,
          allowEmpty: false,
        },
        description: {
          message: "A Atividade é obrigatória",
          type: "string",
          required: false,
          allowEmpty: true,
        },
        activityTypeID: {
          message: "Você deve selecionar de qual forma irá entrar em contato",
          type: "string",
          required: true,
          allowEmpty: false,
        },
      },
    });
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setIsSaving(false);
      return;
    }

    const payload = handlePayload(fields, activityID);
    // Define qual endpoint chamar baseado na presença do activityID
    // Se tiver o ID, chama a edição, caso contrário a criação de atividade
    const endpointFunc = activityID ? putActivity : postActivity;

    //console.log(activityID);

    endpointFunc(payload)
      .then(() => {
        if (!seqSwitch) {
          toast.success("Atividade cadastrada/atualizada com sucesso");
        }
        refetch();
        resetFields();
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível cadastrar/atualizar a atividade");
      })
      .finally(() => {
        setIsSaving(false);
        if (!seqSwitch) {
          closeModal();
        }
      });
    if (seqSwitch) {
      return true;
    }
  };

  const handlePayload = ({ date, endHour, startHour, ...rest }, activityID) => {
    date = date.split("/").reverse();
    date.splice(1, 1, +date[1] - 1);
    const endTime = new Date(...[...date, ...endHour.split(":")]);
    const startTime = new Date(...[...date, ...startHour.split(":")]);

    let payload = removeEmptyKeys(rest);
    return {
      activityID,
      ...payload,
      endTime,
      startTime,
    };
  };

  const changeActivityStatus = (activityID, status) => {
    // handleChanging(true);
    setIsSaving(true);
    putActivity({ activityID, status })
      .then(() => {
        toast.success("Atividade atualizada com sucesso");
        refetch();
        resetFields();
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar a atividade");
      })
      .finally(() => {
        setIsSaving(false);
        seqSwitch = false;
        closeModal();
        // setTimeout(() => setIsSaving(null), 4000);
      });
  };

  const resetFields = () => {
    setFields({
      leadID: "",
      activityTypeID: "",
      startTime: "",
      endTime: "",
      description: "",
      date: "",
      startHour: "",
      endHour: "",
    });
  };

  return (
    <>
      <ModalTitle
        title={activity ? activity.title : "Nova atividade"}
        icon={require("../../../png/activity.png")}
      >
        {activity?.isOverdue && (
          <Attention
            style={{ width: "20px", height: "20px", marginLeft: "15px" }}
          />
        )}
      </ModalTitle>
      <div className="flex" style={{ width: "615px" }}>
        <Select
          placeholder="Selecione"
          label="Negócio"
          className="fullWidth"
          isDefault={true}
          onChange={(value) => handleFields(value, "leadID")}
          options={leads}
          valueOption="name"
          value={fields.leadID}
          style={{ height: "32px" }}
        />
        <InputMasked
          className="marginLeft"
          placeholder={"00/00/000"}
          label="Data"
          isDefault={true}
          value={fields.date}
          onChange={(value) => {
            handleFields(`${value}`, "date");
          }}
          style={{ maxWidth: "95px" }}
          mask="99/99/9999"
          maskChar={null}
        />
        <InputHour
          label="Hora de início"
          placeholder={"00:00"}
          isDefault={true}
          value={fields.startHour}
          onChange={(value) => {
            handleFields(value, "startHour");
          }}
          style={{ maxWidth: "95px" }}
          mask="99:99"
        />
        <InputHour
          label="Hora de fim"
          placeholder={"00:00"}
          isDefault={true}
          value={fields.endHour}
          onChange={(value) => {
            handleFields(value, "endHour");
          }}
          style={{ maxWidth: "95px" }}
          mask="99:99"
        />
      </div>

      <TextArea
        label="Atividade"
        className="fullWidth"
        isDefault={true}
        controlled={true}
        value={fields.description}
        selectedType={fields.activityTypeID}
        isBusinessPage={false}
        onChange={(value) => handleFields(value, "description")}
        setType={(value) => handleFields(`${value || ""}`, "activityTypeID")}
      />

      <div className="fullWidth flex end vCenter defaultMarginTop">
        {isCreating ? (
          <Button
            text="Salvar"
            paddingHorizontal="20px"
            marginTop="10px"
            isLoading={isSaving}
            onClick={() => handleSaveActivity(fields, activity_ID)}
          />
        ) : (
          <>
            <Button
              text="Concluir"
              paddingHorizontal="20px"
              marginRight="15px"
              marginTop="10px"
              isLoading={isSaving}
              onClick={() => {
                seqSwitch = true;
                handleSaveActivity(fields, activity_ID).then(() => {
                  changeActivityStatus(
                    activity.id || activity._id,
                    ACTIVITY_COMPLETED
                  );
                });
              }}
            />
            <Button
              text="Salvar"
              paddingHorizontal="20px"
              marginTop="10px"
              isLoading={isSaving}
              onClick={() => handleSaveActivity(fields, activity_ID)}
            />
          </>
        )}
      </div>
    </>
  );
}

export default ActivityModal;
