import React, { useState, useEffect } from "react";
import ModalTitle from "../ModalTitle";
import Input from "../../InputHook";
import { changePass } from "../../../request/index";
import Button from "../../Button";
import { toast } from "react-toastify";
import logo from "../../../png/logo.png";

function ChangePassword({ closeModal, status }) {
  const [reEnterNewPass, setReEnterNewPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!status) {
      resetFields();
    }
  }, [status]);

  const changePassword = async (newPass, reEnterNewPass) => {
    setLoading(true);

    if (newPass !== reEnterNewPass) {
      toast.warning("As senhas estão diferentes");
      setLoading(false);
      return;
    }
    if (newPass.length < 6) {
      toast.warning("São necessários no mínino 6 caracteres para a nova senha");
      setLoading(false);
      return;
    }

    changePass({ password: newPass })
      .then(() => {
        toast.success("Senha alterada com sucesso!");
        resetFields();
        closeModal();
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response?.data === "NEW PASSWORD EQUAL TO OLD PASSWORD") {
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Sua nova senha é igual a sua senha atual");
          return;
        }
        if (error.message == "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível atualizar a senha");
      })
      .finally(() => setLoading(false));
  };

  const resetFields = () => {
    setNewPass("");
    setReEnterNewPass("");
  };

  return (
    <div className="flex column center defaultMarginRight defaultMarginLeft">
      <figure className="smallMarginBottom ">
        <img className="logo" alt="" src={logo} />
        {/*TODO: Adicionar alt na imagem */}
      </figure>

      <div className="flex column center fullWidth defaultMarginTop">
        <hr className="smallMarginTop defaultMarginBottom" />
        <div className="defaultMarginTop defaultMarginBottom textCenter successMessage">
          <p style={{ fontWeight: 500 }}>Crie sua nova senha.</p>
        </div>
        <Input
          type="password"
          placeholder="Nova senha"
          value={newPass}
          onChange={setNewPass}
        />
        <Input
          type="password"
          placeholder="Repetir nova senha"
          value={reEnterNewPass}
          onChange={setReEnterNewPass}
        />
        <Button
          text="Criar nova senha"
          className="defaultMarginTop fullWidth defaultMarginRight defaultMarginLeft"
          paddingHorizontal="20px"
          onClick={() => changePassword(newPass, reEnterNewPass)}
          isLoading={loading}
        />
      </div>
    </div>
  );
}

export default ChangePassword;
