import React from "react";

const StatusPage = () => {
  return (
    <div className="backgroundGradient sucessoScreen">
      <p>Man at work. Under Construction...</p>
    </div>
  );
};

export default StatusPage;
