import React from 'react';
import clsx from 'clsx';

function Select({ className, ...props }) {
  const valueKey = props.valueKey || "id";
  const valueOption = props.valueOption || "label";
  return (
    <>
      {
        props.isDefault ?
          (
            <label className={clsx("flex column secondaryLabel", !props.withoutMargin && "smallMarginTop", (className && className.indexOf("fullWidth") !== -1) ? "fullWidth" : "halfWidth", (className && className.indexOf("noLimit") !== -1 && "noLimit"))} >
              {props.label && (<span>{props.label}</span>)}

              <div className={clsx("contentSelect smallMarginTop", className && className)} style={props.style && props.style}>
                <select className={clsx("variantInput")}
                  onChange={(inpt) => props.onChange(inpt.target.value)}
                  value={props.value ? props.value : ""}
                  disabled={props.disabled ? true : false}
                >
                  {props.placeholder && (<option value="" disabled>{props.placeholder}</option>)}
                  {(props.options && props.options.length) &&
                    props.options.map((item) => {
                      return (
                        <option selected={props.value === item[valueKey]} key={item[valueKey]} value={item[valueKey]}>{item[valueOption]}</option>
                      )
                    })
                  }
                </select>
              </div>
            </label>
          ) :
          (
            <div className={clsx("contentSelect", className && className)} style={props.style && props.style}>
              <select onChange={(inpt) => props.onChange(inpt.target.value)}>
                {props.placeholder && (<option selected="selected" disabled>{props.placeholder}</option>)}
                {(props.options && props.options.length) &&
                  props.options.map((item) => {
                    return (
                      <option selected={props.value === item[valueKey]} key={item[valueKey]} value={item[valueKey]}>{item[valueOption]}</option>
                    )
                  })
                }
              </select>
            </div>
          )
      }
    </>
  )
}

export default Select;